import AliOSSHttp from "@/api/common/base-serve/AliOSSHttp";
import { Constants } from "@utils/constants/Constants";
import EnumConstants from "../constants/EnumConstants";
import paramHttp from "../../api/system-manager/param-manager/paramHttp";
import TBUserManger from "../user/TBUserManger";
import { SYSTEM_CONFIG } from "../constants/RequestCodeConstants";

/**
 * 全局加载上传配置
 * @param {*} orgId
 */
const loadUploadOSSType = (orgId) => {
    orgId = orgId || Constants.tibiOrg.orgId;
    AliOSSHttp.getUploadPlatformConfig(orgId).then((data) => {
        window.$TBUpload.uploadPlatform = data || EnumConstants.UPLOAD_PLATFORM.HUAWEI;
        let ossType = window.$TBUpload.uploadPlatform;
        if (ossType == EnumConstants.UPLOAD_PLATFORM.TB || ossType == EnumConstants.UPLOAD_PLATFORM.TIBI) {
            AliOSSHttp.getUploadToken("");
        } else {
            AliOSSHttp.getUploadToken(ossType);
        }
    });
};

/**
 * 加载全局的参数配置
 */
export const loadGlobalSystemConfig = (cb) => {
    const param = {
        orgId: TBUserManger.getCurrentUserOrgId(),
        parameterCodeList: [SYSTEM_CONFIG.HAS_OFFLINE_TRAIN],
    };
    // 全局配置
    const result = {
        hasOfflineTrain: true,
    };
    paramHttp
        .getOrgParameterList(param)
        .then((res) => {
            if (res && res.success && res.data) {
                if (res.data[SYSTEM_CONFIG.HAS_OFFLINE_TRAIN]) {
                    const data = res.data[SYSTEM_CONFIG.HAS_OFFLINE_TRAIN];
                    result.hasOfflineTrain = data == 1;
                }
            }
            window.$TBConfig = result;
            cb && cb(result);
        })
        .catch((res) => {
            window.$TBConfig = result;
            cb && cb(result);
        });
};

export { loadUploadOSSType };
