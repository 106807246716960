/**
 * 全局变量类
 */

export default class GlobalModel {
    // 示例
    static instance = null;

    /**
     * 静态函数的单例
     */
    static shareInstance() {
        if (this.instance instanceof this == false) {
            this.instance = new this();
        }
        return this.instance;
    }

    /**
     *  定义属性
     */
    constructor() {
        /**
         * 是否已经打开控制台流程引导
         */
        this.isOpenedFlowGuide = false;
        /**
         * 是否勾选协议
         */
        this.isSelectedProtocol = false;
        /**
         * 是否选择自动登录
         */
        this.isSelectedAutoLogin = true;
        /**
         * 本地版本 lzb - V1.0.8
         */
        this.localVersion = "";
    }
}
