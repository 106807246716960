import userUtil from "../user/userUtil.js";
import { Constants } from "@utils/constants/Constants.js";

export default {
    // 监听事件
    Events: {
        BeforeUnload: "beforeunload",
        Unload: "unload",
    },
    _beforeUnload_time: 0,
    _gap_time: 0,

    /**
     * 监听流浏览器关闭
     */
    addMonitorBrowserCloseEvent() {
        const that = this;
        window.addEventListener(this.Events.BeforeUnload, (e) => this.beforeUnloadFn(e));
        window.addEventListener(this.Events.Unload, (e) => this.unloadFn(e));
    },
    /**
     * 关闭之前事件
     */
    beforeUnloadFn(event) {
        this._beforeUnload_time = new Date().getTime();
        // // 如果火狐浏览器- 只执行这个方法
        // const isFirefox = navigator.userAgent.indexOf("Firefox")>0;
        // if(isFirefox){
        //     // 用户点击浏览器右上角关闭按钮或是按alt+F4关闭
        //     if(event.clientX>document.body.clientWidth && event.clientY<0||event.altKey)
        //     {
        //         this.closeLogOut();
        //     }
        //     // 用户点击任务栏，右键关闭。s或是按alt+F4关闭
        //     else if(event.clientY > document.body.clientHeight || event.altKey)
        //     {
        //         this.closeLogOut();
        //     }
        //     // 其他情况为刷新
        //     else
        //     {
        //         // alert("刷新页面");
        //     }
        // }
    },
    /**
     * 关闭事件 - 关闭窗口不需要退出登录-lzb 20230925
     */
    unloadFn(e) {
        this._gap_time = new Date().getTime() - this._beforeUnload_time;
        if (this._gap_time <= 5) {
            // this.closeLogOut();
        }
    },
    /**
     * 退出登录
     */
    closeLogOut() {
        console.log("监听到窗口关闭------");
        const href = window.location.href;
        const result = this.isExitUrlInWhiteList(href);
        // 只有不在白名单才清楚
        if (!result) {
            console.log("清楚缓存-----");
            // lzb - 2020-07-22 清楚本地缓存
            userUtil.cleanAllLocationData();
            // 退出登录
            userUtil.logout().then((res) => {});
        }
    },

    /**
     * 当前链接是否在白名单类 lzb 2020-08-03
     */
    isExitUrlInWhiteList(url) {
        const routerWhiteList = Constants.routerWhiteList;
        if (!routerWhiteList || routerWhiteList.length == 0) {
            return false;
        }
        if (!url || url.length == 0) {
            return false;
        }
        let result = false;
        for (let i = 0; i < routerWhiteList.length; i++) {
            const str = routerWhiteList[i];
            if (url.indexOf(str) !== -1) {
                result = true;
            }
        }
        return result;
    },

    /**
     * 移除浏览器监听
     */
    removeBrowserCloseEvent() {
        window.removeEventListener(this.Events.BeforeUnload, (e) => this.beforeUnloadFn());
        window.removeEventListener(this.Events.Unload, (e) => this.unloadFn());
    },
};
