import http from "@tibi/http";
import { HudGlobal } from "./HudGlobal";
import router from "../router";
import userUtil from "./user/userUtil";
import { ConstantErrors } from "./constants/ConstantErrors";
import { encryptDataByAES, decryptDataByAES } from "./helper/CryptoHelper";
import { removeCookieToken, setCookieToken } from "./tool/TokenUtil";
import StoreUserDB from "./db/StoreUserDB";
import { autoLoginByTokenToFixPath, fetchAuthTokenByCookie } from "./user/LoginHelper";
import SessionStorageDBManager from "./db/SessionStorageDBManager";
const baseUrl = process.env.VUE_APP_API_BASE_URL;
const loginBaseUrl = process.env.VUE_APP_API_SSO_URL;
const config = {
    baseUrl,
    token: true,
    tokenRefreshUrl: `${loginBaseUrl}/token/refresh`,
    supportLocalStorageToken: true,
    supportRefreshToken: false,
    withCredentials: window.location.protocol === "https",
    tokenExpireTip(res) {},
    // 自定义头部
    customHeaders: {},
    // 刷新token 2023-09-18
    refreshTokenCallBack: (token) => {
        StoreUserDB.saveUserToken(token);
    },
};

http.init(config);

export default {
    /**
     * get 请求
     * @param url
     * @param param
     * @returns {*}
     */
    get(url, param, options) {
        const that = this;
        if (this.onForceRefresh(url)) {
            // rqm 20240219 校验同浏览器多窗口 保证一个账号登录
            const isEncrypt = this.isAPIEncrypt(options);
            const newParam = this.decorateRequestParam(param, options);
            const newOptions = this.decorateRequestOptions(options);
            return new Promise((resolve, reject) => {
                http.get(url, newParam, newOptions)
                    .then((response) => {
                        that.handleRequestSuccessResponse(resolve, response, isEncrypt);
                    })
                    .catch((error) => {
                        that.handleRequestFailResponse(resolve, error);
                    });
            });
        } else {
            // 直接返回失败的网络请求 rqm 20240219
            return this.returnFail();
        }
    },
    /**
     * 直接返回失败的网络请求 rqm 20240219
     */
    returnFail() {
        const that = this;
        return new Promise((resolve, reject) => {
            that.handleRequestFailResponse(resolve, {});
        });
    },

    /**
     * post 请求
     * @param url
     * @param param
     * @returns {*}
     */
    post(url, param, options) {
        if (this.onForceRefresh()) {
            const that = this;
            const isEncrypt = this.isAPIEncrypt(options);
            const newParam = this.decorateRequestParam(param, options);
            const newOptions = this.decorateRequestOptions(options);
            return new Promise((resolve, reject) => {
                http.post(url, newParam, newOptions)
                    .then((response) => {
                        that.handleRequestSuccessResponse(resolve, response, isEncrypt);
                    })
                    .catch((error) => {
                        that.handleRequestFailResponse(resolve, error);
                    });
            });
        } else {
            return this.returnFail();
        }
    },

    /**
     * put 请求
     * @param url
     * @param parm
     * @returns {*}
     */
    put(url, param, options) {
        if (this.onForceRefresh()) {
            const that = this;
            const isEncrypt = this.isAPIEncrypt(options);
            const newParam = this.decorateRequestParam(param, options);
            const newOptions = this.decorateRequestOptions(options);
            return new Promise((resolve, reject) => {
                http.put(url, newParam, newOptions)
                    .then((response) => {
                        that.handleRequestSuccessResponse(resolve, response, isEncrypt);
                    })
                    .catch((error) => {
                        that.handleRequestFailResponse(resolve, error);
                    });
            });
        } else {
            return this.returnFail();
        }
    },

    /**
     * 删除请求
     * @param url
     * @param param
     * @returns {*}
     */
    delete(url, param, options) {
        if (this.onForceRefresh()) {
            const that = this;
            const isEncrypt = this.isAPIEncrypt(options);
            const newParam = this.decorateRequestParam(param, options);
            const newOptions = this.decorateRequestOptions(options);
            return new Promise((resolve, reject) => {
                http.delete(url, newParam, newOptions)
                    .then((response) => {
                        that.handleRequestSuccessResponse(resolve, response, isEncrypt);
                    })
                    .catch((error) => {
                        that.handleRequestFailResponse(resolve, error);
                    });
            });
        } else {
            return this.returnFail();
        }
    },

    /**
     * 检测url是否有效  lzb 20240313
     */
    checkUrlValid(url) {
        return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.open("HEAD", url, true);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        resolve && resolve();
                    } else {
                        reject && reject();
                    }
                }
            };
            xhr.onerror = function () {
                reject(new Error("发生错误"));
            };
            xhr.send();
        });
    },

    /**
     * 装饰请求参数
     */
    decorateRequestParam(param, options) {
        const isEncrypt = this.isAPIEncrypt(options);
        const sourceParam = {};
        Object.assign(sourceParam, param);
        // 定义新增请求参数
        let newParam = sourceParam;
        if (isEncrypt) {
            const str = encryptDataByAES(sourceParam);
            newParam = {
                data: str,
            };
            return newParam;
        } else {
            return param;
        }
    },
    /**
     * 装饰请求的options
     */
    decorateRequestOptions(options) {
        const isEncrypt = this.isAPIEncrypt(options);
        const newOptions = {
            headers: {
                platform: "pc-admin",
                mapType: "GD", // GD 高德  BD 百度  TX 腾讯  目前是固定值 GD 2023-11-09 lzb
                "x-project": "tbatb-admin-web", // 项目
            },
        };
        // 加密 默认加密 使用A加密方式 传1  使用A加密方式 传-1 不加密
        if (!isEncrypt) {
            newOptions.headers["Encrypt-Mode"] = -1;
        }
        Object.assign(newOptions, options);
        return newOptions;
    },

    /**
     * 是否API加密
     */
    isAPIEncrypt(options) {
        if (process.env.NODE_ENV == "production") {
            return options && options.isEncrypt && process.env.VUE_APP_OPEN_API_ENCRYPT == "true";
        } else {
            return localStorage.getItem("TBEncryptAPI") == 1 || (options && options.isEncrypt && process.env.VUE_APP_OPEN_API_ENCRYPT == "true");
        }
    },
    /**
     *bug15380 【测试环境】搜狗浏览器同时打开多个无痕浏览器，每个浏览器登录不同的账号，结果先登录的账号查看到的是后登录账号的数据
     *rqm 20240220
     * 强制刷新页面
     * @param {} url
     * @returns
     */
    onForceRefresh(url) {
        // console.log("onForceRefresh url:", url, url.startsWith("/api/"), url.includes("/api/"), url.indexOf("/api/"));
        if (url && url.startsWith("/api/")) {
            return true;
        }
        if (url && url.includes("/api/")) {
            return true;
        }
        if (location.pathname == "/login") {
            return true;
        }

        const sessionUserId = SessionStorageDBManager.getUserId();
        const userId = localStorage.getItem("SAVE_USER_ID") || "";
        if (!!sessionUserId && !!userId && sessionUserId != userId) {
            console.log("onForceRefresh 控制台强制刷新页面:", userId, sessionUserId);
            const option = {
                message: "已登录其它账号，请点击【确定】刷新页面！",
            };
            // 弹刷新弹框
            // const dialogObj = HudGlobal.showOneButtonAlertMessageOnly(option);
            // if (dialogObj != null) {
            // 在其它窗口已登录别的账号，需要重现强制刷新该窗口
            SessionStorageDBManager.setUserId(userId);
            HudGlobal.showOneButtonAlertMessageOnly(option).then((res) => {
                console.log("onForceRefresh http强制刷新页面:");
                // 在其它窗口已登录别的账号，需要重现强制刷新该窗口
                // SessionStorageDBManager.setUserId(localStorage.getItem("SAVE_USER_ID"));
                location.reload(true); // 强制刷新页面
            });
            // }
            return false;
        }
        return true;
    },

    //= ===================================请求响应统一处理==============================//
    /**
     * 请求成功处理
     */
    handleRequestSuccessResponse(resolve, response, isEncrypt) {
        const vue = HudGlobal.getVue();
        vue.loadingClose();
        // 1.系统升级 - 当做成功处理lzb 20230620
        if (response && response.code === 900) {
            HudGlobal.showWarningWithMessage(response.description || "当前系统正在升级，请稍后重试！恢复正常后可继续当前操作！", 5000);
            return;
        }
        if (!response || !response.hasOwnProperty("code")) {
            // 500处理
            router.push("/500");
            return false;
        }
        if (response.code == 500) {
            router.push("/500");
            return;
        }

        // 404 处理
        if (response.code == 404) {
            router.push("/404");
            return;
        }
        // 服务器重启- lzb 20230525
        if (response.code == 6) {
            HudGlobal.showWarningWithMessage("当前系统正在升级，请稍后重试！恢复正常后可继续当前操作！", 5000);
            return;
        }

        // // rqm 2020-02-18 12611用户未登陆
        if (response.code == 105 || response.code == 106 || response.code == 107 || response.code == 108 || response.code == 12611 || response.code == 18009) {
            // 有验证失效弹框不处理
            // if (window.tokenExpiredTip) {
            //     return;
            // }
            if (window.__isHasAlert) {
                return;
            }
            window.__isHasAlert = true;
            const message = response.description ? response.description : "用户未登陆";
            vue.$confirm(message, {
                showClose: false,
                showCancelButton: false,
                confirmButtonText: "去登录",
            })
                .then((res) => {
                    window.__isHasAlert = false;
                    userUtil.logout().then((res) => {
                        removeCookieToken();
                    });
                })
                .catch((err) => {
                    window.__isHasAlert = false;
                    userUtil.logout().then((res) => {
                        removeCookieToken();
                    });
                });
            return;
        }

        // 多端登录,不同的处理
        if (response.code == 405 || response.code == 12611) {
            // // 有验证失效弹框不处理
            // if (window.tokenExpiredTip) {
            //     return;
            // }
            if (window.__isHasAlert) {
                return;
            }
            // 取消所有弹框
            // vue.$alert.closeAll();
            window.__isHasAlert = true;
            const message = response.description ? response.description : "您的帐号已在其它地方登录";
            const currentVue = HudGlobal.getVue();

            currentVue
                .$alert(message, {
                    showClose: false,
                    showCancelButton: false,
                    confirmButtonText: "重新登录", // 2020/05/22 需求改动 fbz
                })
                .then((res) => {
                    window.__isHasAlert = false;
                    userUtil.logout().then((logoutRes) => {
                        removeCookieToken();
                    });
                })
                .catch((err) => {
                    window.__isHasAlert = false;
                    userUtil.logout().then((res) => {
                        removeCookieToken();
                    });
                });
            return;
        }
        // 多组织单点登录新增 2023-10-23
        if (response.code === 406) {
            if (window.__isHasSwitchLoginAlert) {
                return;
            }
            window.__isHasSwitchLoginAlert = true;
            const message = response.description;
            vue.$confirm(message, {
                showClose: false,
                showCancelButton: false,
                confirmButtonText: "确定",
            })
                .then(async (res) => {
                    window.__isHasSwitchLoginAlert = false;
                    const code = await fetchAuthTokenByCookie();
                    console.log("406获取本地cookie中的token---code=", code);
                    if (code == 0) {
                        // 直接自动登录
                        await autoLoginByTokenToFixPath();
                    }
                })
                .catch((err) => {
                    window.__isHasSwitchLoginAlert = false;
                });
        }

        if (resolve) {
            const encryptMode = response.resHeaders["encrypt-mode"];
            // A. 无encryptMode 字段   默认使用A解密  B. 1  使用A解密 C -1  不解密
            if (encryptMode == 1) {
                response.data = decryptDataByAES(response.data);
            }
            resolve(response);
        }
    },
    /**
     * 请求失败处理
     */
    handleRequestFailResponse(resolve, error) {
        let message = null;

        // 网络离线 - lzb 20230525
        if (error.statusCode === 700 && error.code == 8100) {
            HudGlobal.showWarningWithMessage(error.description, 5000);
            return;
        }
        // 请求异常、或者响应异常 - lzb 20230525
        if (error.statusCode === 700 && (error.code == 8000 || error.code == 8001)) {
            message = "网络连接失败";
        }
        // 请求取消
        if (error.statusCode === 700 && error.code == 3) {
            console.warn("重复请求被取消");
            return;
        }
        // 服务器断开- lzb 20230525
        if (error.statusCode == 900) {
            message = "当前系统正在升级，请稍后重试！恢复正常后可继续当前操作！";
            HudGlobal.showWarningWithMessage(message, 5000);
            return;
        }

        const object = {
            success: false,
            fail: true,
            description: message ? message : ConstantErrors.description.NETWORK_CONNECT_FAIL,
            code: 999,
        };
        if (resolve) {
            resolve(object);
        }
    },
};
