import Vue from "vue";
import { Constants } from "../constants/Constants.js";
import FormatterUtil from "@utils/formatter-global/FormatterUtil";
import UniversalFormatter from "./UniversalFormatter.js";
import uploadHelper from "../helper/uploadHelper.js";

/*
 *
 *  培训类别-培训模式
 * */
// eslint-disable-next-line func-names
Vue.prototype.formatterTrainSubjectModelStyle = function (item) {
    if (!item) return "-";

    if (item instanceof String) {
        return item;
    }

    let str = "";
    if (item.industryName != null && item.industryName.length != 0) {
        str += item.industryName;
    }
    if (item.projectName != null && item.projectName.length != 0) {
        if (item.industryName != null && item.industryName.length != 0) {
            str += "-";
        }
        str += item.projectName;
    }
    if (item.subjectName != null && item.subjectName.length != 0) {
        if (item.projectName != null && item.projectName.length != 0) {
            str += "-";
        }
        str += item.subjectName;
    }
    if (item.dutyName != null && item.dutyName.length != 0) {
        if (item.subjectName != null && item.subjectName.length != 0) {
            str += "-";
        }
        str += item.dutyName;
    }
    if (item.trainingModel != null) {
        if (item.trainingModel == 10) {
            str += "-报名类型";
        } else {
            str += "-企业内训";
        }
    }

    return str;
};

/**
 * 岗位显示 - 行业-岗位
 */
// eslint-disable-next-line func-names
Vue.prototype.formatterDutyStyle = function (item) {
    if (!item) return "-";

    if (item instanceof String) {
        return item;
    }
};

/**
 * 行业显示lzb 2020-12-15
 */
Vue.prototype.formatterIndustryNameListStr = (item, noData = "无") => {
    if (!item.extJsonObj) {
        return noData;
    }
    if (!item.extJsonObj.industryList || item.extJsonObj.industryList.length == 0) {
        return "通用行业";
    }
    const tempList = [];
    const industryList = item.extJsonObj.industryList;
    industryList.map((object) => {
        const str = object.industryName;
        tempList.push(str);
    });
    const resultStr = tempList.join("、");
    return resultStr;
};
/**
 * 处理培训类别 - lzb 2020-12-15
 */
Vue.prototype.formatterSubjectNameListStr = (item, noData = "无") => {
    if (!item.extJsonObj) {
        return noData;
    }
    if (!item.extJsonObj || !item.extJsonObj.subjectList || item.extJsonObj.subjectList.length == 0) {
        return "通用类别";
    }
    const tempList = [];
    item.extJsonObj.subjectList.map((object) => {
        const str = object.subjectName;
        tempList.push(str);
    });
    const resultStr = tempList.join("、");
    return resultStr;
};

/**
 * 处理岗位 - lzb 2020-12-15
 */
Vue.prototype.formatterDutyNameListStr = (item, enableNoData = false, noText = "无") => {
    if (!item.extJsonObj) {
        return noText;
    }
    if (!item.extJsonObj || !item.extJsonObj.dutyList || item.extJsonObj.dutyList.length == 0) {
        return enableNoData ? noText : "通用";
    }
    const tempList = [];
    item.extJsonObj.dutyList.map((object) => {
        const str = object.dutyName;
        tempList.push(str);
    });
    const resultStr = tempList.join("、");
    return resultStr;
};

Vue.prototype.formatterDutyListOfNull = (item) => {
    if (!item.extJsonObj) {
        return "无";
    }
    if (!item.extJsonObj || !item.extJsonObj.dutyList || item.extJsonObj.dutyList.length == 0) {
        return "无";
    }
    const tempList = [];
    item.extJsonObj.dutyList.map((object) => {
        const str = object.dutyName;
        tempList.push(str);
    });
    const resultStr = tempList.join("、");
    return resultStr;
};

/*
 *
 *  培训类别显示 - 已经弃用
 * */
Vue.prototype.formatterTrainSubjectStyle = (item) => {
    if (!item) return "-";

    if (item instanceof String) {
        return item;
    }

    let str = "";
    if (item.industryName != null && item.industryName.length != 0) {
        str += item.industryName;
    }
    if (item.projectName != null && item.projectName.length != 0) {
        if (item.industryName != null && item.industryName.length != 0) {
            str += "-";
        }
        str += item.projectName;
    }
    if (item.subjectName != null && item.subjectName.length != 0) {
        if (item.projectName != null && item.projectName.length != 0) {
            str += "-";
        }
        str += item.subjectName;
    }
    if (item.dutyName != null && item.dutyName.length != 0) {
        if (item.industryName != null && item.industryName.length != 0) {
            str += "-";
        } else if (item.projectName != null && item.projectName.length != 0) {
            str = `${str}-`;
        } else if (item.subjectName != null && item.subjectName.length != 0) {
            str = `${str}-`;
        }

        str += item.dutyName;
    }

    return str;
};

/**
 * 培训类别列表显示
 */
Vue.prototype.formatterListSubject = (list) => {
    if (list == null || list.length == 0) {
        return "-";
    }
    const length = list.length;
    let newName = "";
    for (let i = 0; i < list.length; i++) {
        const dutyName = list[i];
        if (i <= 1) {
            newName += `${dutyName}<br/>`;
        } else if (i < list.length - 1) {
            newName += `${dutyName},`;
        } else if (i === list.length - 1) {
            newName += dutyName;
        }
    }
    return newName;
};
/*
 *
 *  通过组织类型获取组织类型文字描述
 * */
Vue.prototype.fetchTypeNameByTypeCode = (typeCode) => {
    // 1. 检测
    if (typeCode == null || typeCode.length == 0) {
        return "";
    }
    if (typeCode == Constants.organizationType.ORGTYPE_SUPPERVISE) {
        return "行业管理";
    } else if (typeCode == Constants.organizationType.ORGTYPE_HEAD_OFFICE) {
        return "总部";
    } else if (typeCode == Constants.organizationType.ORGTYPE_AGENT) {
        return "代理商";
    } else if (typeCode == Constants.organizationType.ORGTYPE_COMPANY) {
        return "企业";
    } else if (typeCode == Constants.organizationType.ORGTYPE_ORGAN) {
        return "机构";
    } else if (typeCode == Constants.organizationType.ORGTYPE_AGENCY) {
        return "经销商";
    } else {
        return "";
    }
};

/**
 * 判断图片是否为null并展示默认图片
 */
Vue.prototype.formatterLoadingImage = (imgurl, defaultImagePath) => {
    if (imgurl == null || imgurl == undefined || imgurl.length == 0) {
        return defaultImagePath;
    } else {
        return imgurl;
    }
};

// 来源
Vue.prototype.filterSourceTypeStr = (val) => {
    let valInt = val;

    if (val instanceof Object) {
        valInt = val.sourceType;
    }

    let typeStr = "";
    switch (valInt) {
        case 10: {
            typeStr = "电脑(PC)";
            break;
        }
        case 14: {
            typeStr = "现场考试";
            break;
        }
        case 15: {
            typeStr = "现场学习";
            break;
        }
        case 20: {
            typeStr = "安卓(APP)";
            break;
        }
        case 21: {
            typeStr = "苹果(APP)";
            break;
        }
        case 22: {
            typeStr = "公众号";
            break;
        }
        case 23: {
            typeStr = "小程序";
            break;
        }
        case 30: {
            typeStr = "电脑(h5)";
            break;
        }
        case 31: {
            typeStr = "安卓（h5）";
            break;
        }
        case 32: {
            typeStr = "苹果（h5）";
            break;
        }
        default: {
            typeStr = "-";
            break;
        }
    }
    return typeStr;
};

/**
 * 处理地区拼接 2021-01-15 lzb
 * @param item
 * @returns {String|string}
 */
Vue.prototype.formatterAddressAppendStyle = (item) => {
    return FormatterUtil.formatterAddressAppendStyle(item);
};

Vue.prototype.fenToMoneyFixedFormatter = (val) => {
    return FormatterUtil.formatterMoneyOptionsMinusPointStr(val);
};

/**
 * 处理地区 - lzb 2020-12-15
 */
Vue.prototype.formatterAreaNameListStr = (item, noData = "无") => {
    if (!item.extJsonObj) {
        return noData;
    }
    if (!item.extJsonObj || !item.extJsonObj.areaList || item.extJsonObj.areaList.length == 0) {
        return "通用地区";
    }
    const tempList = [];
    item.extJsonObj.areaList.map((object) => {
        const str = FormatterUtil.formatterAddressAppendStyle(object);
        tempList.push(str);
    });
    const resultStr = tempList.join("、");
    return resultStr;
};

/**
 *  上传图片之前检测 lzb 2023-03-27 新增
 * */
Vue.prototype.beforePhotoUploadHook = (file) => {
    return uploadHelper.checkUploadImageFormat(file);
};
/**
 *  上传图片限制 lzb 2023-03-27 新增
 * */
Vue.prototype.uploadImageAccept = "image/png, image/jpeg, image/jpg, image/gif";
/**
 * lzb 2022-11-04 新增
 */
Vue.prototype.formatterCellValue = (col, row, value, index) => {
    return UniversalFormatter.formatterCellValueStr(col, row, value);
};
