var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.options.isVisible
      ? _c(
          "div",
          {
            staticClass: "window-custom-mask-wrap",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.onClickMaskEvent.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "window-container-wrap" }, [
              _c("div", { staticClass: "container-header" }, [
                _vm.options.title != null && _vm.options.title.length != 0
                  ? _c("div", { staticClass: "title-header-wrap" }, [
                      _vm._v(_vm._s(_vm.options.title)),
                    ])
                  : _vm._e(),
                _vm.options.showClose
                  ? _c(
                      "div",
                      {
                        staticClass: "close-icon-wrap",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.onClickCloseEvent.apply(null, arguments)
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-close" })]
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "container-content" }, [
                _vm.options.content != null && _vm.options.content.length != 0
                  ? _c("div", { staticClass: "title-content-wrap" }, [
                      _vm._v(_vm._s(_vm.options.content)),
                    ])
                  : _vm._e(),
              ]),
              _vm.options.isContainerCancel
                ? _c("div", { staticClass: "container-footer" }, [
                    _c(
                      "div",
                      {
                        staticClass: "cancel-button",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.onClickCancleEvent.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.options.cancelButtonTitle))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "ensure-button",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.onClickEnsureEvent.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.options.ensureButtonTitle))]
                    ),
                  ])
                : _c("div", { staticClass: "container-footer" }, [
                    _c(
                      "div",
                      {
                        staticClass: "ensure-button",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.onClickEnsureEvent.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.options.ensureButtonTitle))]
                    ),
                  ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }