import defaultSettings from "@/settings";
import variables from "@/assets/scss/variables-element.scss";

const { showSettings, tagsView, tagsViewStyle, fixedHeader, sidebarLogo, showBreadcrumb } = defaultSettings;

const state = {
    theme: variables.theme,
    showSettings,
    tagsView,
    tagsViewStyle,
    fixedHeader,
    sidebarLogo,
    showBreadcrumb,
};

const mutations = {
    // eslint-disable-next-line no-shadow
    CHANGE_SETTING: (state, { key, value }) => {
        if (state.hasOwnProperty(key)) {
            state[key] = value;
        }
    },
};

const actions = {
    changeSetting({ commit }, data) {
        commit("CHANGE_SETTING", data);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
