import router from "../../router.js";
import orgSiteHttp from "../../api/organize-manager/site-manager/orgSiteHttp";
import { addRoutesList } from "@utils/router/RouteUtil";
import TBUserManger from "@utils/user/TBUserManger";
import StoreMainManger from "@utils/db/StoreMainManger";

export default {
    /**
     * 跳转到登录
     */
    pushToLogin(templateUrl) {
        if (!templateUrl) {
            console.log("没有加载的到的登录组件");
            return;
        }
        const that = this;
        try {
            const newLogin = [this.getLoginSourceObj(templateUrl)];

            addRoutesList(newLogin);
            that.gotoLoginRouter();
        } catch (e) {
            that.gotoLoginRouter();
        }
    },
    /**
     *  跳转登录路由
     */
    gotoLoginRouter() {
        console.log("跳转到登录------");
        router.push({
            path: "/login",
        });
    },

    /**
     * 检测登录的组件路由跳转
     */
    checkJumpToLogin() {
        console.log("开始检测是否跳转到登录-----");
        // 清除本地数据 lzb 2021-08-11 兼容已经key = vuex 以前取值
        StoreMainManger.removeVuexStorage();

        const temp = this.getUrlParams(window.location.href);
        window.sessionStorage.setItem("tempLogin", JSON.stringify(temp));

        const item = window.localStorage.getItem("templateData");
        const that = this;
        if (item) {
            const templateData = JSON.parse(item);
            if (templateData && templateData.sysSiteTemplateDto && templateData.sysSiteTemplateDto.templateUrl) {
                const templateUrl = templateData.sysSiteTemplateDto.templateUrl;
                this.pushToLogin(templateUrl);
                return false;
            }
        } else {
            // 加载站点信息
            this.loadSiteInfoData(function (templateRL) {
                that.pushToLogin(templateRL);
            });
        }
    },

    /**
     * 检测并加入登录路由
     */
    checkAddLoginRouter() {
        const item = window.localStorage.getItem("templateData");
        const that = this;
        if (item) {
            const templateData = JSON.parse(item);
            if (templateData && templateData.sysSiteTemplateDto && templateData.sysSiteTemplateDto.templateUrl) {
                const templateUrl = templateData.sysSiteTemplateDto.templateUrl;
                this.addLoginRouter(templateUrl);
                return false;
            }
        } else {
            // 加载站点信息
            this.loadSiteInfoData(function (templateRL) {
                that.addLoginRouter(templateRL);
            });
        }
    },

    /**
     * 加入登录路由
     */
    addLoginRouter(templateUrl) {
        if (!templateUrl) {
            console.log("没有加载的到的登录组件");
            return;
        }
        const newLogin = [this.getLoginSourceObj(templateUrl)];
        addRoutesList(newLogin);
    },

    /**
     * 获取登录的资源 lzb 2021-06-22 框架替换
     */
    getLoginSourceObj(templateUrl) {
        return {
            path: "/login",
            name: "login",
            component: templateUrl,
            hidden: true,
        };
    },

    /**
     * 加载站点请求
     */
    loadSiteInfoData(callBack) {
        const params = {};
        params.url = window.location.host;
        const that = this;
        window.localStorage.removeItem("templateData");
        orgSiteHttp.getSiteByUrl(params).then((res) => {
            let templateUrl = "login-template/login-template-one/LoginTemplateOne"; // "login/Login";
            if (res.success) {
                const data = res.data;
                // 如果没有则默认的登陆界面
                templateUrl = data.sysSiteTemplateDto.templateUrl;
                window.localStorage.setItem("templateData", JSON.stringify(data));
            }
            if (callBack) {
                callBack(templateUrl);
            }
        });
    },
    getUrlParams(url) {
        if (!url.match(/\?([^#]+)/)) {
            return;
        }
        url = url.match(/\?([^#]+)/)[1];
        const obj = {};
        const arr = url.split("&");
        for (let i = 0; i < arr.length; i++) {
            const subArr = arr[i].split("=");
            obj[subArr[0]] = subArr[1];
        }
        return obj;
    },
    /**
     * 跳转到学员端 lzb V1.0.9 2021-06-03
     */
    gotoStudentWeb(tempLoginId) {
        const location = window.location.href;
        const siteOrgId = TBUserManger.getSiteLoginOrgId();
        // 学员需要跳转地址, 其它直接登陆
        const toLink = `${process.env.VUE_APP_STUDENT_HOME_URL}?tempid=${tempLoginId}&siteOrgId=${siteOrgId}&f=${location}`;
        window.location.href = toLink;
    },
    /**
     * 跳转到控制台V1.0.9 202-06-03
     */
    gotoHomeDashboard() {
        console.log("跳转到主页------");
        router.push("/dashboard");
    },
};
