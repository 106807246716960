export default {
    // 水印名称
    watterImageClassName: "tb-custom-img-water-title",

    /**
     *  生成文字水印
     * @param elId  父组件的id
     * @param text   文字
     * @param font  字体
     * @param textColor   字体颜色
     * @param supportMutilate  是否支持多个
     * @returns
     */
    addTextWaterMarked(elId, text, font = 16, textColor = "gray", supportMutilate = false) {
        if (!elId) {
            console.log("id 不能为null");
            return false;
        }
        const parentNode = document.getElementById("print-water-mark-el-list-detail");
        if (!parentNode) {
            console.log("未获取到父节点");
            return false;
        }
        return this.addTextWaterMarkToDom(parentNode, text, font, textColor, supportMutilate);
    },

    /**
     * 新增水印到dom
     */
    addTextWaterMarkToDom(doc, text, font = 16, textColor = "#333333", supportMutilate = true) {
        if (!text || text.length == 0) {
            return false;
        }

        const parentNode = doc;

        // parentNode.setAttribute("style", `position:relative;`);
        // 1. 创建画布
        const tempCanvas = document.createElement("canvas");
        const tempCtx = tempCanvas.getContext("2d");
        tempCtx.mozImageSmoothingEnabled = false;
        tempCtx.oImageSmoothingEnabled = false;
        tempCtx.webkitImageSmoothingEnabled = false;
        tempCtx.msImageSmoothingEnabled = false;
        tempCtx.imageSmoothingEnabled = false;

        const textWidth = tempCtx.measureText(text).width;
        const padding = text.length > 10 ? 60 : 20;
        tempCanvas.width = textWidth + 2 * padding;
        tempCanvas.height = tempCanvas.width;

        // 2.设置属性

        tempCtx.font = `${font}px verdana`;
        tempCtx.globalAlpha = 0.5;
        tempCtx.fillStyle = textColor;

        const offsetTop = parentNode.offsetTop;
        const offsetLeft = parentNode.offsetLeft;
        const parentWidth = parentNode.clientWidth;
        const parentHeight = Math.max(parentNode.scrollHeight, parentNode.offsetHeight, parentNode.clientHeight);

        const textX = padding;
        const textY = tempCanvas.height - padding;

        tempCtx.translate(textX, textY);
        tempCtx.rotate((-45 / 180) * Math.PI);
        tempCtx.fillText(text, 0, 0);

        // const A4Width = 595.28;
        // const A4Height = 841.89;

        const A4Width = 649;
        const A4Height = 978;

        // parentHeight = A4Width/parentWidth * parentHeight;
        //
        // if(parentHeight < A4Height){
        //     parentHeight += A4Height *0.75;
        // }else {
        //     parentHeight = parentHeight + A4Height;
        // }

        const A4Rate = A4Width / parentWidth;

        let XPadding = 10;
        let YPadding = 10;
        // 如果水印文字小于200
        if (tempCanvas.width < 200) {
            XPadding = 100;
        } else {
            XPadding = 50;
        }

        // 如果水印高度小于200
        if (tempCanvas.height < 200) {
            YPadding = 100;
        } else {
            YPadding = 50;
        }

        // 3. 生成图片
        const resultImage = tempCanvas.toDataURL("image/png", 1);

        const start = new Date().getTime();
        // 4.加载图片
        if (supportMutilate) {
            // 多个水印  生成PDF之后 每页都是自动从开始加水印

            const waterNodeWidth = tempCanvas.width;
            const waterNodeHeight = tempCanvas.height;
            let waterNodeX = offsetLeft;
            let waterNodeY = offsetTop;

            // console.log(
            //     '打印的宽度 =', parentWidth,
            //     '打印的高度 =',parentHeight,
            //     'offsetTop=',offsetTop ,
            //     "A4比例=",A4Rate,
            //     'waterNodeWidth = ',waterNodeWidth,
            //     'waterNodeHeight = ',waterNodeHeight);
            // 如果最后一行不想要水印 j<A4Height -waterNodeHeight-YPadding
            for (let i = offsetLeft; i < parentWidth; i = i + waterNodeWidth + XPadding) {
                waterNodeX = i;
                for (let j = offsetTop; j < A4Height; j = j + waterNodeHeight + YPadding) {
                    waterNodeY = j;
                    const imgNode = document.createElement("img");
                    imgNode.setAttribute("class", this.watterImageClassName);
                    imgNode.setAttribute(
                        "style",
                        `
                        pointerEvents: none;
                        position:fixed;
                        top:${waterNodeY}px;
                        left:${waterNodeX}px;
                        width:${waterNodeWidth}px;
                        height:${waterNodeHeight}px;`
                    );
                    imgNode.setAttribute("src", resultImage);
                    parentNode.appendChild(imgNode);
                }
            }
            // for (let i = 0; i < parentWidth - XPadding; i += XPadding) {
            //     const imgNodeX  = i + (i == 0 ? offsetLeft : 0);
            //     let count = 0; // 限制每页每列10个水印
            //     for (let j = 0; j < parentHeight + 2*YPadding; j += 2*YPadding) {
            //         // position:absolute;
            //         count++;
            //         if(count > 10){
            //             continue;
            //         }
            //         const imgNodeY = (j + offsetTop); // (j == 0 ? offsetTop : 0);
            //         // console.log('x =', imgNodeX, 'y =', imgNodeY, 'count =', count, "j =", j, "YPadding = ",YPadding);
            //         const imgNode = document.createElement('img');
            //         imgNode.setAttribute('class', this.watterImageClassName);
            //         imgNode.setAttribute("style", `
            //         pointerEvents: none;
            //         position:fixed;
            //         top:${imgNodeY}px;
            //         left:${imgNodeX}px;
            //         width:${tempCanvas.width}px;
            //         height:${tempCanvas.height}px;`);
            //         imgNode.setAttribute('src', resultImage);
            //         parentNode.appendChild(imgNode);
            //     }
            // }
        } else {
            // 单个水印
            const signleImgNode = document.createElement("img");
            signleImgNode.setAttribute("class", this.watterImageClassName);
            signleImgNode.style.pointerEvents = "none";
            signleImgNode.style.top = `${offsetTop + parentHeight * 0.5}px`;
            signleImgNode.style.left = `${offsetLeft + parentWidth * 0.5}px`;
            signleImgNode.style.position = "absolute";
            signleImgNode.style.width = `${tempCanvas.width}px`;
            signleImgNode.style.height = `${tempCanvas.height}px`;
            signleImgNode.setAttribute("src", resultImage);
            parentNode.appendChild(signleImgNode);
        }
        const end = new Date().getTime();
        console.log("加水印执行时间", end - start, "ms");
        return parentNode;
    },
};
