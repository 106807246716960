import { get, set, remove } from "js-cookie";
import jsonWebToken from "@tibi/json-web-token";
import { secondsToDays } from "../DateUtil.js";
import TBUserManger from "../user/TBUserManger.js";

const TokenKey = process.env.NODE_ENV + "TB_TOKEN_KEY";
const TokenKeyID = process.env.NODE_ENV + "TB_TOKEN_UNIQUE_ID";
const TB_COOKIE_ORG_ID = process.env.NODE_ENV + "TB_COOKIE_ORG_ID";

const domain = process.env.VUE_APP_COOKIE_DOMAIN;
// 保存的默认时间 - 默认天
let expires = 5;

let options = {
    expires,
    domain,
    path: "/",
};

/**
 * 获取token
 */
export const getCookieToken = () => {
    const token = isOpenLogin() ? get(TokenKey, options) : "";
    return token || "";
};
/**
 * 获取token的id
 */
export const getCookieTokenUniqueID = () => {
    const tokenId = isOpenLogin() ? get(TokenKeyID, options) : "";
    return tokenId || "";
};
/**
 * 获取token的id
 */
export const getCookieOrgId = () => {
    const orgId = isOpenLogin() ? get(TB_COOKIE_ORG_ID, options) : "";
    return orgId || "";
};
/**
 * 获取jwt的唯一id
 * @param {*} token
 * @returns
 */
export const getJWTUniqueId = (token) => {
    const parseToken = jsonWebToken.parse(token);
    const jti = parseToken.jti;
    return jti;
};
/**
 * 获取token中的userId
 * @param {*} token
 * @returns
 */
export const getJWTUserId = (token) => {
    const parseToken = jsonWebToken.parse(token);
    const sub = parseToken.sub;
    return sub;
};
/**
 * 设置token
 */
export const setCookieToken = (token) => {
    if (token) {
        const parseToken = jsonWebToken.parse(token);
        // 当前时间
        let now = new Date().getTime();
        now = now.toString().length === 13 ? parseInt(now / 1000) : now;
        // 过期的时间(单位秒)
        const exp = parseToken.exp.length === 13 ? parseToken.exp / 1000 : parseToken.exp;

        // 相差的时间, 目前定30分钟1800秒, 10分钟600秒
        expires = secondsToDays(exp - now);

        // console.log("解析token====", parseToken);

        const subToken = parseToken.sub;
        set(TokenKey, subToken, options);

        // 保存唯一标识
        const jti = parseToken.jti;
        set(TokenKeyID, jti, options);

        // 保存组织id
    }
};
/**
 * 保存组织
 */
export const setCookieOrgId = (orgId) => {
    const saveOrgId = orgId || TBUserManger.getCurrentUserOrgId();
    set(TB_COOKIE_ORG_ID, saveOrgId, options);
};
/**
 *  移除token
 */
export const removeCookieToken = () => {
    remove(TokenKey, options);
    remove(TokenKeyID, options);
    remove(TB_COOKIE_ORG_ID, options);
};

/**
 * 是否开启单点登录
 */
export const isOpenLogin = () => {
    // 如果是公司系统才能有
    const host = window.location.host || window.location.hostname;
    const filterSites = [
        "admin.tbatb.net",
        "safety.tbatb.net",
        "marketing.tbatb.net",
        "ad.tbatb.net",
        "admin.sim.tbatb.net",
        "safety.sim.tbatb.net",
        "marketing.sim.tbatb.net",
        "ad.sim.tbatb.net",
        "admin.pre.tbatb.net",
        "safety.pre.tbatb.net",
        "marketing.pre.tbatb.net",
        "ad.pre.tbatb.net",
        "admin.d1.tb.com",
        "safety.d1.tb.com",
        "marketing.d1.tb.com",
        "ad.d1.tb.com",
        "tbatb-admin.t1.tb.com",
        "tbatb-admin.t2.tb.com",
        "tbatb-admin.t3.tb.com",
        "marketing.t1.tb.com",
        "marketing.t2.tb.com",
        "marketing.t3.tb.com",
        "marketing.t3.tb.com",
        "safety.t1.tb.com",
        "safety.t2.tb.com",
        "safety.t3.tb.com",
        "safety.t3.tb.com",
        "ad.t1.tb.com",
        "ad.t2.tb.com",
        "ad.t3.tb.com",
    ];
    if (filterSites.indexOf(host) !== -1) {
        return true;
    }
    return false;
};
