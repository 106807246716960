import { RouterConstants } from "@utils/constants/RouterConstants";

export const StaticRoutes = [
    {
        path: RouterConstants.EXTERNAL_LINE.WEB_PROTOCOL,
        name: "protocolWeb",
        hidden: true,
        component: () => import("@/views/web/protocol-web/protocolWeb.vue"),
    },
    {
        path: "/loginTemplateOne",
        name: "loginTemplateOne",
        hidden: true,
        component: () => import("@/views/login-template/login-template-one/LoginTemplateOne.vue"),
    },
    {
        path: "/loginTemplateTwo",
        name: "loginTemplateTwo",
        hidden: true,
        component: () => import("@/views/login-template/login-template-two/loginTemplateTwo.vue"),
    },
    {
        path: RouterConstants.LOGIN.LOGIN_TEMPLATE_THREE,
        name: "LoginTemplateThree",
        hidden: true,
        component: () => import("@/views/login-template/login-template-three/LoginTemplateThree.vue"),
    },
    {
        path: "/help/center",
        name: "helpCenter",
        hidden: true,
        component: () => import("@/views/help-center/HelpCenter.vue"),
    },

    {
        path: "/plan/detail/print/previews",
        name: "PlanDetailPreview",
        hidden: true,
        // component: () => import("@/views/train-manager/plan-manager/plan-detail-preview/PlanDetailPreview.vue"),
        component: () => import("@/views/company-report/print-plan-detail/PrintPlanDetail.vue"),
    },
    {
        path: "/h5/app/download",
        name: "h5AppDownload",
        component: () => import("@/views/h5-app-download/H5AppDownload.vue"),
    },
    {
        path: "/student/app",
        name: "studentApp",
        hidden: true,
        component: () => import("@/views/h5-app-download/student-app/StudentApp.vue"),
    },
    {
        path: "/company/app",
        name: "companyApp",
        hidden: true,
        component: () => import("@/views/h5-app-download/company-app/CompanyApp.vue"),
    },
    {
        path: RouterConstants.UNIVERSAL.DATA_VISUAL_PLATFORM_PATH,
        name: "DataVisualPlatform",
        hidden: true,
        component: () => import("@/views/statistics/data-visual-platform/DataVisualPlatform.vue"),
    },
    {
        path: RouterConstants.COMPANY_REGISTER.COMPANY_REGISTER_PATH,
        name: "companyRegister",
        hidden: true,
        component: () => import("@/views/company-register/CompanyRegister.vue"),
    },
    {
        path: RouterConstants.COMPANY_REGISTER.FIRST_REGISTER_PATH,
        name: "firstRegister",
        hidden: true,
        component: () => import("@/views/company-register/first-register/FirstRegister.vue"),
    },
    {
        path: RouterConstants.COMPANY_REGISTER.SECOND_REGISTER_PATH,
        name: "secondRegister",
        hidden: true,
        component: () => import("@/views/company-register/second-register/SecondRegister.vue"),
    },
    {
        path: RouterConstants.COMPANY_REGISTER.THREE_REGISTER_PATH,
        name: "threeRegister",
        hidden: true,
        component: () => import("@/views/company-register/three-register/ThreeRegister.vue"),
    },
    {
        path: "/mustDocumentDetail",
        name: "mustDocumentDetail",
        hidden: false,
        component: () => import("@/views/message-manager/must-read-detail/must-document-detail/mustDocumentDetail.vue"),
    },
    {
        path: RouterConstants.MIDDLEWARE.CHANGE_ORG_PAGE, // 切换组织跳转页面
        hidden: true,
        name: "SwitchOrgMiddleWare",
        component: () => import("@/views/middleware/SwitchOrgMiddleware.vue"),
    },
    {
        path: RouterConstants.NEWS.COMPANY_NEWS_LIST, // 企业新闻
        name: "CompanyNewsList",
        hidden: true,
        component: () => import("@views/news-manager/company-news/list/CompanyNewsList.vue"),
    },
    {
        path: RouterConstants.NEWS.COMPANY_NEWS_DETAIL, // 企业新闻详情
        name: "CompanyNewsDetail",
        hidden: true,
        component: () => import("@views/news-manager/company-news/detail/CompanyNewsDetail.vue"),
    },
    {
        path: RouterConstants.LOGIN_LESSON_NEWS_LIST, // 登录课件列表
        name: "LoginLessonList",
        hidden: true,
        component: () => import("@views/lesson-manager/login-lesson/LoginLessonList.vue"),
    },
    {
        path: RouterConstants.MIDDLE_WARE, // 跳转到后台管理系统中间页
        name: "MiddleWare",
        hidden: true,
        component: () => import("@views/middleware/MiddleWare.vue"),
    },
    {
        path: RouterConstants.COMMON_PAGE.PDF_PREVIEW, // 跳转到PDF
        name: "PdfPreview",
        hidden: true,
        component: () => import("@views/common-pages/PdfPreview.vue"),
    },
    {
        path: "/safe/regular/personal/record/export/detail",
        name: "personalMeetingRecordExport",
        hidden: true,
        component: () => import("@/views/safe-regular/create-train/detail/personal-detail/look-record/printing-export/PersonalMeetingRecordExport.vue"),
    },
    {
        path: "/safe/regular/personal/detail/export/detail",
        name: "personalExport",
        hidden: true,
        component: () => import("@/views/safe-regular/create-train/detail/personal-detail/printing-export/PersonalMeetingPrintingExport.vue"),
    },
    {
        path: "/safe/regular/archives/list/print",
        name: "meetingArchivesListPrint",
        hidden: true,
        component: () => import("@/views/safe-regular/meeting-archives/meeting-archives-list-print/MeetingArchivesListPrint.vue"),
    },
    {
        path: "/safe/regular/archives/detail/print",
        name: "regularMeetingDetailPrint",
        hidden: true,
        component: () => import("@/views/safe-regular/meeting-archives/detail/regular-meeting-detail-print/RegularMeetingDetailPrint.vue"),
    },
    {
        path: RouterConstants.COMMON_PAGE.QUALIFIED_CERT_PREVIEW, // 跳转到通用证书
        name: "QualifiedCertPreview",
        hidden: true,
        component: () => import("@views/common-pages/qualified-cert-preview/QualifiedCertPreview.vue"),
    },
];
