import Vue from "vue";

// 插件的包
import PerfectScrollbar from "perfect-scrollbar";
// 对应的css
import "perfect-scrollbar/css/perfect-scrollbar.css";

/**
 * 防止重复点击 2021-02-20 lzb 设置重复点击
 */
Vue.directive("limitRepeatClick", {
    inserted(el, binding) {
        const { value } = binding;
        const textContent = el.textContent;
        el.addEventListener("click", () => {
            if (!el.disabled) {
                el.disabled = true;
                setTimeout(() => {
                    el.disabled = false;
                }, value || 3000);
            }
        });
    },
});

/**
 * 指令判断是否只能输入数字
 */
Vue.directive("require-number", {
    inserted(el) {
        el.addEventListener("keypress", function (e) {
            e = e || window.event;
            const charcode = typeof e.charCode === "number" ? e.charCode : e.keyCode;
            const re = /\d/;
            if (!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey) {
                if (e.preventDefault) {
                    e.preventDefault();
                } else {
                    e.returnValue = false;
                }
            }
        });
    },
});

const el_scrollBar = (el) => {
    if (el._ps_ instanceof PerfectScrollbar) {
        el._ps_.update();
    } else {
        // 加属性
        el._ps_ = new PerfectScrollbar(el);
    }
};

/**
 * 滚动指令
 */
Vue.directive("scrollBar", {
    // inserted钩子函数，获取自定义指令处的dom
    inserted(el, binding, vnode) {
        // 为了配合element的table组件 在这里需要判断一下并给定类
        const { value } = binding;
        if (value === "elTable") {
            el = el.querySelector(".el-table__body-wrapper");
            if (!el) {
                return console.warn("未发现className为el-table__body-wrapper的dom");
            }
        }
        // 判断其样式是否存在position 并且position为"fixed", "absolute"或"relative"
        const rules = ["fixed", "absolute", "relative"];
        if (!rules.includes(window.getComputedStyle(el, null).position)) {
            console.error(`perfect-scrollbar所在的容器的position属性必须是以下之一：${rules.join("、")}`);
        }
        // el上挂一份属性
        el_scrollBar(el);
    },
    // 更新dom的时候
    componentUpdated(el, binding, vnode, oldVnode) {
        try {
            vnode.context.$nextTick(() => {
                el_scrollBar(el);
            });
        } catch (error) {
            console.log(error);
            el_scrollBar(el);
        }
    },
});
