import router from "@/router";
import store from "@/store";
import { RouterConstants } from "../../../utils/constants/RouterConstants";
import StoreTagViewDB from "@utils/db/StoreTagViewDB";
import StringHelper from "@utils/helper/StringHelper";
import ObjectHelper from "@utils/helper/ObjectHelper";

export default {
    /**
     * 移除单个标签
     * @param url 标签的路径
     * @param last  是否点击到上一个，默认是
     * @param cb  回调
     */
    removeTab(url, last = true, cb) {
        const tagVisitedViews = StoreTagViewDB.getTagsVisitedView();
        if (!tagVisitedViews) {
            return;
        }
        // 1. 找出url 对应的view
        let view;
        for (let i = 0; i < tagVisitedViews.length; i++) {
            if (tagVisitedViews[i].path === url) {
                view = tagVisitedViews[i];
            }
        }
        if (!view) {
            return;
        }
        // 移除tag
        store.dispatch("tagsView/delView", view).then(({ visitedViews }) => {
            if (last) {
                if (this.isActive(view)) {
                    this.toLastView(visitedViews, view);
                }
            }
            cb && cb();
        });
    },
    /**
     * 移除特定标签,排除标签
     */
    removeSomeTab(url, excludeArr) {
        const excludeView = [];
        excludeArr = excludeArr || [];
        const visitedViews = StoreTagViewDB.getTagsVisitedView();
        for (let i = 0; i < visitedViews.length; i++) {
            const path = visitedViews[i].path;
            let isExcludePath = false;
            for (let j = 0; j < excludeArr.length; j++) {
                if (excludeArr[j] === path) {
                    isExcludePath = true;
                }
            }
            if (isExcludePath) {
                continue;
            }

            const indexOf = path.indexOf(url);
            if (indexOf === 0) {
                excludeView[excludeView.length] = visitedViews[i];
            }
        }

        for (let i = 0; i < excludeView.length; i++) {
            const itemView = excludeView[i];
            // 移除tag
            store.dispatch("tagsView/delView", itemView).then(() => {});
        }
    },
    /**
     * 移除所有的tag
     */
    removeAllTab(cb) {
        const tagVisitedViews = StoreTagViewDB.getTagsVisitedView();
        if (!tagVisitedViews) {
            return;
        }
        // 移除tag
        store.dispatch("tagsView/delAllViews").then(() => {
            cb && cb();
        });
    },

    /**
     * 是否是当前活跃路由
     * @param route
     * @returns {boolean}
     */
    isActive(route) {
        return route.path === router.app.$route.path;
    },
    /**
     * 选择最后一个路由
     */
    toLastView(visitedViews, view) {
        if (!visitedViews) {
            return;
        }
        let latestView = "";
        if (view.meta && this.isExitTagViewByVisitedViews(view.meta.prePath)) {
            latestView = this.findTagViewByPath(view.meta.prePath);
        } else {
            latestView = visitedViews.slice(-1)[0];
        }
        if (latestView) {
            router.push(latestView.fullPath);
            return false;
        }

        // 如果标签没有名称，默认跳转到首页
        if (view.name === RouterConstants.FIX_PATH) {
            // 重新加载
            router.replace({ path: RouterConstants.FIX_PATH });
        } else {
            router.push(RouterConstants.ROOT_PATH);
        }
    },

    /**
     * 跳转到上一个路由
     */
    gotoLastPageView(url) {
        const tagVisitedViews = StoreTagViewDB.getTagsVisitedView();
        if (!tagVisitedViews) {
            return;
        }
        const view = this.findTagViewByPath(url);
        if (!view) {
            return;
        }
        this.toLastView(tagVisitedViews, view);
    },

    /**
     * tagView是否存在
     */
    isExitTagViewByVisitedViews(obj) {
        const tagVisitedViews = StoreTagViewDB.getTagsVisitedView();
        if (!tagVisitedViews || tagVisitedViews.length == 0) {
            return false;
        }

        let url = "";
        if (StringHelper.isString(obj)) {
            url = obj;
        } else if (!ObjectHelper.isEmptyObject(obj)) {
            url = obj.path;
        } else {
        }
        if (!url || url.length == 0) {
            return false;
        }
        for (let i = 0; i < tagVisitedViews.length; i++) {
            if (tagVisitedViews[i].path === url) {
                return true;
            }
        }
        return false;
    },
    /**
     * 找到view
     */
    findTagViewByPath(path) {
        const tagVisitedViews = StoreTagViewDB.getTagsVisitedView();
        if (!tagVisitedViews || tagVisitedViews.length == 0) {
            return null;
        }
        let view;
        for (let i = 0; i < tagVisitedViews.length; i++) {
            if (tagVisitedViews[i].path === path) {
                view = tagVisitedViews[i];
            }
        }
        return view;
    },
};
