/*
 * @Author: fbz
 * @Date: 2021-08-13 18:50:53
 * @LastEditors: fbz
 * @LastEditTime: 2021-08-23 14:48:51
 */
import "./dialogDrag.js";
import "./universal/Universal.js";
import "./permission/permission.js";
import dataVisualPlatformLoadding from "./data-visual-platform-loadding/dataVisualPlatformLoadding";

export { dataVisualPlatformLoadding };
