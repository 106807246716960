const getters = {
    sidebar: (state) => state.app.sidebar,
    device: (state) => state.app.device,
    footerNews: (state) => state.app.footerNews,

    dataVisualPattern: (state) => state.temp.dataVisualPattern,
    dataVisualRefresh: (state) => state.temp.dataVisualRefresh,

    cachedViews: (state) => state.tagsView.cachedViews,
    visitedViews: (state) => state.tagsView.visitedViews,

    roles: (state) => state.user.roleList,
    username: (state) => state.user.userInfo.username,
    orgId: (state) => state.user.userInfo.orgDto.orgId,
    orgName: (state) => state.user.userInfo.orgDto.orgName,
    userId: (state) => state.user.userInfo.userId,
    realName: (state) => state.user.userInfo.realName,
    runAsRealName: (state) => state.user.userInfo.runAsRealName,
    runAsUserId: (state) => state.user.userInfo.runAs,
    runAsUserName: (state) => state.user.userInfo.runAsUserName,
    roleInfoArr: (state) => state.user.userInfo.orgUserRole,
    roleIdArr: (state) => state.user.userInfo.orgUserRole.map((data) => data.roleId),
    userInfo: (state) => state.user.userInfo,
    remember: (state) => state.global.rememberFlag,
    menuList: (state) => state.permission.dynamicRoutes,
    unreadMessageTotal: (state) => state.global.unreadMessageTotal,
};
export default getters;
