/**
 *  用户信息状态管理
 * */
const user = {
    namespaced: true,
    state: {
        token: "",
        username: "",
        roleList: [],
        userInfo: {}, // V1.0.9 lzb 2021-05-17
        userLoginIdentity: "", // V1.0.9 lzb-2021-06-04  当前登录身份
        userIdentifyList: [], // 用户身份list
        permissionList: [], // 权限list
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_ROLE_LIST: (state, roleList) => {
            state.roleList = roleList;
        },

        SET_USER_LOGIN_IDENTITY: (state, identity) => {
            state.userLoginIdentity = identity;
        },
        SET_USER_INFO: (state, userInfo) => {
            state.userInfo = userInfo;
        },
        SET_USER_IDENTITY_LIST: (state, identityList) => {
            state.userIdentifyList = identityList;
        },
        SET_PERMISSION_LIST: (state, permissionList) => {
            state.permissionList = permissionList;
        },
    },
    actions: {
        /**
         * 保存token
         */
        saveToken({ commit }, token) {
            commit("SET_TOKEN", token);
        },

        /**
         * 存储用户信息V1.0.9 lzb 2021-05-17
         */
        refreshUserInfo({ commit }, userInfo) {
            commit("SET_USER_INFO", userInfo);
        },
        /**
         * 保存当前登录的身份
         */
        refreshUserLoginIdentify({ commit }, identity) {
            commit("SET_USER_LOGIN_IDENTITY", identity);
        },

        /**
         *  保存用户角色
         */
        saveUserRoleList({ commit }, roleList) {
            commit("SET_ROLE_LIST", roleList);
        },

        /**
         *  保存用户身份
         */
        saveUserIdentifyList({ commit }, identityList) {
            commit("SET_USER_IDENTITY_LIST", identityList);
        },
        /**
         *  保存用户权限
         */
        saveUsrPermissionList({ commit }, permissionList) {
            commit("SET_PERMISSION_LIST", permissionList);
        },
    },
};

export default user;
