import store from "@/store";
import StoreBaseDBManger from "@utils/db/StoreBaseDBManger";
import { getCookieToken, getCookieTokenUniqueID, getJWTUniqueId, getJWTUserId, removeCookieToken, setCookieOrgId, setCookieToken } from "../tool/TokenUtil";
import jsonWebToken from "@tibi/json-web-token";

/**
 * user 的store 操作 V1.0.9 lzb - 2021-05-17
 */
export default {
    // ==============================================保存用户基础信息=========================================//
    /**
     * 保存或者刷新用户基本信息
     */
    saveUserInfo(data) {
        store.dispatch("user/refreshUserInfo", data);

        // 保护权限列表
        this.saveUserPermissionList(data.permissionList);
        // 保护用户身份列表
        this.saveUserIdentifyList(data.userIdentityList);

        // 报复角色列表
        this.saveUserRoleList(data.roleList);

        // 保存组织id
        setCookieOrgId(data.orgId);
    },
    /**
     * 获取用户信息
     */
    getUserInfo() {
        if (store.state && store.state.user) {
            const userInfo = store.state.user.userInfo;
            return userInfo || {};
        } else {
            return {};
        }
    },
    /**
     * 移除用户信息
     */
    removeUserInfo() {
        store.dispatch("user/refreshUserInfo", {});
        this.removeUserToken();
    },

    // ==============================================保存用户身份=========================================//
    /**
     * 用户当前登录身份
     */
    saveUserLoginIdentify(identity) {
        store.dispatch("user/refreshUserLoginIdentify", identity);
    },
    /**
     * 获取用户登录的身份
     */
    getUserLoginIdentify() {
        if (store.state && store.state.user) {
            const userLoginIdentity = store.state.user.userLoginIdentity;
            return userLoginIdentity || "";
        } else {
            return "";
        }
    },
    /**
     * 移除用户登录的身份
     */
    removeUserLoginIdentify() {
        store.dispatch("user/refreshUserLoginIdentify", "");
    },

    // ==============================================保存用户身份列表=========================================//
    /**
     * 保存用户身份列表
     */
    saveUserIdentifyList(identityList) {
        store.dispatch("user/saveUserIdentifyList", identityList);
    },

    /**
     * 获取用户身份的列表
     */
    getUserIdentifyList() {
        if (store.state && store.state.user) {
            const userIdentifyList = store.state.user.userIdentifyList;
            return userIdentifyList || [];
        } else {
            return [];
        }
    },

    /**
     * 移除用户身份列表
     */
    removeUserIdentifyList() {
        store.dispatch("user/saveUserIdentifyList", []);
    },

    // ==============================================保存用户权限列表=========================================//
    /**
     * 保存用户权限列表
     */
    saveUserPermissionList(permissionList) {
        store.dispatch("user/saveUsrPermissionList", permissionList);
    },

    /**
     * 获取用户权限列表
     */
    getUserPermissionList() {
        if (store.state && store.state.user) {
            const permissionList = store.state.user.permissionList;
            return permissionList || [];
        } else {
            return [];
        }
    },

    /**
     * 移除用户权限列表
     */
    removeUserPermissionList() {
        store.dispatch("user/saveUsrPermissionList", []);
    },

    // ==============================================保存用户角色列表=========================================//
    /**
     * 保存用户角色列表
     */
    saveUserRoleList(roleList) {
        store.dispatch("user/saveUserRoleList", roleList);
    },

    /**
     * 获取用户角色列表
     */
    getUserRoleList() {
        if (store.state && store.state.user) {
            const roleList = store.state.user.roleList;
            return roleList || [];
        } else {
            return [];
        }
    },

    /**
     * 移除用户角色列表
     */
    removeUserRoleList() {
        store.dispatch("user/saveUserRoleList", []);
    },

    // ==============================================保存用户token=========================================//
    /**
     * 保存或者刷新用户token
     */
    saveUserToken(data) {
        store.dispatch("user/saveToken", data);
        StoreBaseDBManger.saveDataToLocalStorage("token", data);
        data && setCookieToken(data);
    },
    /**
     * 获取用户信息
     */
    getUserToken() {
        // 从本地保存中取值
        const tokenValue = StoreBaseDBManger.getDataWithLocalStorage("token");
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!tokenValue) {
            return tokenValue;
        }
        return "";
    },
    /**
     * 移除用户信息
     */
    removeUserToken() {
        store.dispatch("user/saveToken", "");
        StoreBaseDBManger.removeDataWithLocalStorage("token");
    },

    /**
     * 移除所有用户数据
     */
    removeAllUserData() {
        this.removeUserInfo();
        this.removeUserToken();
        this.removeUserPermissionList();
        this.removeUserIdentifyList();
        this.removeUserLoginIdentify();
        this.removeUserRoleList();
    },
    /**
     * 获取token解析结果 - 是否失效 0未过期, 1即将过期, 2已过期 -1 不存在/切换了token -2 切换的账户登录
     */
    async getTokenExpiredResult() {
        const authorization = this.getUserToken();
        let result = -1;
        if (!authorization || authorization.length === 0) {
            return -1;
        }

        const cookieTokenId = getCookieTokenUniqueID();
        const cookieUserId = getCookieToken();
        const jit = getJWTUniqueId(authorization);
        const userId = getJWTUserId(authorization);
        // 必须是cookie存在的时候才比较 如果2个id不同，就说明token已经过期 或者 2个userId不同
        if (cookieTokenId && cookieTokenId.length > 0) {
            // 如果用户id不同，说明切换的账户登录
            if (cookieUserId != userId) {
                // 如果是没有路径， 直接使用cookie登录 lzb 2024-03-14
                if (window.location.pathname && window.location.pathname == "/") {
                    return -1;
                }
                return -2; // 自定义一个code
            } else {
                // 如果唯一标识不同，则是刷新了token
                if (jit != cookieTokenId) {
                    // const code = await fetchAuthTokenByCookie();
                    // if (code == 0) {
                    //     return await this.getTokenExpiredResult();
                    // }
                    return -1; // 当做token不存在，需要从新刷新token并重新登陆
                }
            }
        }
        const expired = jsonWebToken.isExpired(authorization);
        // 如果cookie里面id, 且token未过期
        if (!cookieTokenId && expired === 0) {
            setCookieToken(authorization);
        }
        result = expired;
        return result;
    },
};
