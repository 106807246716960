import router, { resetRouter } from "@/router";
import StorePermissionDB from "@utils/db/StorePermissionDB";
import RouteLoadUtil from "@utils/router/RouteLoadUtil";
import LoginManager from "@utils/manager/LoginManager";
import StoreMainManger from "@utils/db/StoreMainManger";

/**
 * 是否加载目标路由
 */
export const isLoadedRoutePath = (path) => {
    // 检测有路由是否加入, 没有则加入;
    let existsPath = path;
    if (existsPath && existsPath.length > 0) {
        const match = router.match({
            path: existsPath,
        });

        if (match && match.matched.length > 0) {
            return true;
        }
    }
    return false;
};

/**
 * 加载路由
 */
export const reloadRoutes = (path) => {
    if (!path || path.length === 0) {
        path = location.pathname;
    }
    // 检测有路由是否加入, 没有则加入;
    const loaded = isLoadedRoutePath(path);

    if (!loaded) {
        refreshRoutes();
    }
};

/**
 * 刷新所有的路由
 */
export const refreshRoutes = () => {
    // 1.检测当前是否是登录
    if (isLoginCurrentHref()) {
        LoginManager.checkAddLoginRouter();
        return false;
    }
    // 2. 获取菜单
    refreshMenuRoutes();
};

/**
 *  刷新菜单路由
 */
export const refreshMenuRoutes = () => {
    const menuList = StoreMainManger.getUserMenuListDataFromDB();
    addRoutesList(menuList || []);
};

/**
 * 加载处理后路由列表
 */
export const addRoutesList = (sourceList) => {
    let resultList = [];

    // 格式化处理
    if (sourceList && sourceList.length > 0) {
        resultList = RouteLoadUtil.formatterRouteComponent(sourceList);

        // 保存动态路由
        StorePermissionDB.saveDynamicRoutes(resultList);
    }
    // 静态路由已经是格式化的
    resultList = StorePermissionDB.getStaticRoutes();
    if (resultList && resultList.length > 0) {
        resultList = resultList.concat(sourceList);
    } else {
        resultList = sourceList;
    }

    // 增加路由到导航
    __addRoutes(resultList);
};

/**
 * 增加路由
 */
const __addRoutes = (routes) => {
    resetRouter();
    const targetRoutes = routes || [];
    window.__addRouteing = true;
    targetRoutes.forEach((route) => {
        if (!isExitRoute(router.matcher.getRoutes(), route)) {
            router.addRoute(route);
        }
        // 子路由
        if (route.children && route.children.length > 0) {
            route.children.forEach((childRoute) => {
                if (!isExitRoute(router.matcher.getRoutes(), childRoute)) {
                    router.addRoute(childRoute);
                }
            });
        }
    });
    window.__addRouteing = false;
    router.options.routes = routes;
    console.log("获取所有的路由====", router);
    // 保存路由到本地
    const saveRoutes = router.options.routes;
    StorePermissionDB.saveAllRoutes(saveRoutes);
};

/**
 * 路由是否存在
 */
export const isExitRoute = (routes, route) => {
    if (!routes || routes.length == 0) {
        return false;
    }
    const findRoute = routes.find((item) => {
        return item.path == route.path && item.name == route.name;
    });
    return !!findRoute;
};

/**
 * 路由是否存在
 */
export const isExitRouteByName = (name) => {
    if (!name || name.length == 0) {
        return false;
    }
    return router.hasRoute(name);
};

/**
 * 获取能用的第一个路由, 父路由不算
 */
export const getFirstRouteWithUse = (routes) => {
    if (routes && routes.length > 0) {
        const route = routes[0];
        if (route.children) {
            return getFirstRouteWithUse(route.children);
        }
        return route.path;
    } else {
        console.log("没有找到任何路由");
        return "";
    }
};
/**
 * 当前路由是否是登录
 */
export const isLoginCurrentHref = () => {
    const href = window.location.href;
    const pathname = window.location.pathname;
    const reg = /\/system/;
    // 2021-09-17 匹配系统管理的登录日志
    if (pathname.match(reg)) {
        return false;
    }
    const result = href.indexOf("/login") !== -1;
    return result;
};
/**
 * 跳计划培训档案  1.0.32 20240126 rqmei
 * @param query.orgId 计划的所属组织id
 * @param query.planId 计划id
 * @param query.subjectCode  培训类别
 * @param query.prefixPermission 权限前缀
 * @param query.notEqualTrainingState 默认11
 */
export const toPlanTrainArchives = (query) => {
router.push({
     path: "/plan/detail/print/preview",
     query,
 });
};
