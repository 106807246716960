/**
 * @description: 是否支持webp图片格式
 * @return {boolean} 布尔
 */
const isSupportWebp = () => {
    try {
        return document.createElement("canvas").toDataURL("image/webp", 0.5).indexOf("data:image/webp") === 0;
    } catch (e) {
        return false;
    }
};
export { isSupportWebp };
