/*
 * @Author: fbz
 * @Date: 2021-08-23 14:18:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-07-24 17:59:32
 */
import Vue from "vue";
import "./loading.scss";

export default {
    bind(el, binding, vnode) {
        el.style.position = el.style.position || "relative";
        // 加载动画
        const Loading = Vue.extend({
            template: `<div class="loading-content">
                            <div class="spinner">
                                <div class="rect1"></div>
                                <div class="rect2"></div>
                                <div class="rect3"></div>
                                <div class="rect4"></div>
                                <div class="rect5"></div>

                            </div>
                            <p class="loading">数据加载中...</p>
                        </div>`,
        });
        const noDataImg = require("@/assets/img/statistics/platform/noData.png");
        // 无数据
        const NoData = Vue.extend({
            template: `<div class="no-data"><img src="${noDataImg}"  class="no-data-img"/></div>`,
        });
        const component = new Loading().$mount().$el;
        const componentNoData = new NoData().$mount().$el;
        el.appendChild(component);
        el.appendChild(componentNoData);
        el.firstChild.style.visibility = "hidden";
        el.childNodes[2].style.visibility = "hidden";
    },
    update(el, binding, vnode) {
        el.style.position = el.style.position || "relative";
        const { visible, noData } = binding.value;
        if (visible) {
            el.childNodes[0].style.visibility = "hidden";
            el.childNodes[1].style.visibility = "visible";
            el.childNodes[2].style.visibility = "hidden";
        } else {
            el.childNodes[1].style.visibility = "hidden";
            if (noData) {
                el.childNodes[2].style.visibility = "visible";
                el.childNodes[0].style.visibility = "hidden";
            } else {
                el.childNodes[0].style.visibility = "visible";
                el.childNodes[2].style.visibility = "hidden";
            }
        }
    },
};
