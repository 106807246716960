import Vue from "vue";
import windowGuideDialog from "./windowGuideDialog.vue";

const windowGuideDialogExtend = Vue.extend(windowGuideDialog);
windowGuideDialog.install = function (options) {
    // 1. 示例话
    const instance = new windowGuideDialogExtend({
        data: options,
    }).$mount();

    // 2.增加组件到出口
    document.body.appendChild(instance.$el);

    return instance;
};
export default windowGuideDialog;
