export default {
    /**
     * 获取本地ip
     */
    getLocalIP(callBack) {
        const myPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
        const pc = new myPeerConnection({
            iceServers: [],
        });
        const noop = function () {};
        const localIPs = {};
        const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
        let key;

        function iterateIP(ip) {
            if (!localIPs[ip]) {
                localIPs[ip] = true;
                if (callBack) {
                    callBack(ip);
                }
            }
        }

        // create a bogus data channel
        pc.createDataChannel("");

        // create offer and set local description
        pc.createOffer().then(function (sdp) {
            sdp.sdp.split("\n").forEach(function (line) {
                if (line.indexOf("candidate") < 0) return;
                line.match(ipRegex).forEach(iterateIP);
            });
            pc.setLocalDescription(sdp, noop, noop);
        });

        // sten for candidate events
        pc.onicecandidate = function (ice) {
            if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
            // 获取ips 数组
            const ipList = ice.candidate.candidate.match(ipRegex);
            if (ipList && ipList.length > 0) {
                ipList.forEach(iterateIP);
            }
        };
    },
};
