/**
 * 上传 文件  图片处理
 */
import { HudGlobal } from "../HudGlobal";

export default {
    /**
     * 检测上传图片格式 png jpg jpeg
     * @param file
     * @returns {boolean}
     */
    checkUploadImageFormatNoGif(file) {
        if (file == null) {
            return false;
        }
        const isJPG = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
        if (!isJPG) {
            HudGlobal.showWarningWithMessage("上传图片只能是 png/jpeg/jpg格式!");
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            HudGlobal.showWarningWithMessage("上传图片大小不能超过 2MB!");
            return false;
        }
        return isJPG;
    },
    /**
     * 检测上传图片格式 png jpg jpeg
     * @param file
     * @returns {boolean}
     */
    checkUploadImageFormatNoGifAndLimitSize(file, maxSize) {
        if (file == null) {
            return false;
        }
        const isJPG = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
        if (!isJPG) {
            HudGlobal.showWarningWithMessage("上传图片只能是 png/jpeg/jpg格式!");
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < maxSize;
        if (!isLt2M) {
            HudGlobal.showWarningWithMessage(`上传图片大小不能超过 ${maxSize}MB!`);
            return false;
        }
        return isJPG;
    },
    /**
     * 检测上传图片格式 png jpg jpeg
     * @param file
     * @returns {boolean}
     */
    checkUploadImageFormat(file, maxSize = 2) {
        if (file == null) {
            return false;
        }
        const isJPG = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg" || file.type === "image/gif";

        if (!isJPG) {
            HudGlobal.showWarningWithMessage("上传图片只能是 png/jpeg/jpg/gif格式!");
            return false;
        }
        let isLt2M = true;
        if (maxSize > 0) {
            isLt2M = file.size / 1024 / 1024 < maxSize;
            if (!isLt2M) {
                HudGlobal.showWarningWithMessage(`上传图片大小不能超过${maxSize}MB!`);
                return false;
            }
        }

        return isJPG && isLt2M;
    },
    /**
     * 课件文件格式检测
     */
    checkCourseUploadFormat(fileType, file) {
        if (file == null) {
            return false;
        }
        // 文件格式（10:视频,20:音频,30:文件,31:PPT，32:EXCEL，33:PDF）
        switch (fileType) {
            case 10: {
                // 视频
                const isVideo = file.type == "video/mp4";
                if (!isVideo) {
                    HudGlobal.showWarningWithMessage("只能上传MP4格式");
                    return false;
                }
                break;
            }
            case 20: {
                const fileName = file.name;
                const isMp3 = fileName.endsWith(".mp3");
                // 音频
                // const isAudio = file.type === "audio/mp3";
                if (!isMp3) {
                    HudGlobal.showWarningWithMessage("只能上传MP3格式");
                    return false;
                }
                break;
            }
            case 30:
            case 31:
            case 32:
            case 33: {
                console.log("文件格式----", file);
                // 文件检测
                const isFileFormatter = this.checkBaseFileFormatter(file, ["xls", "xlsx", "doc", "docx", "ppt", "pptx", "pdf"], false);
                if (!isFileFormatter) {
                    HudGlobal.showWarningWithMessage("只能上传.docx/.pptx/.pdf/.xlsx格式");
                    return false;
                }
                break;
            }
        }
        return true;
    },
    /**
     * 检测文件类型
     */
    checkUpFileType(fileTypes, file) {
        // （10:未知文件，11：图片，20：vedio）21：视频，31:word，32:excel
        if (!file || !fileTypes) {
            return false;
        }
        return this.checkBaseFileFormatter(file, fileTypes, true);
    },

    /**
     * 检测上传文件格式
     */
    checkUploadFileFormat(file) {
        return this.checkBaseFileFormatter(file, ["xls", "xlsx", "doc", "docx", "ppt", "pptx", "pdf", "png", "jpg", "jpeg"], true);
    },
    /**
     * 检测导入文件的格式
     */
    checkImportFileFormat(file) {
        return this.checkBaseFileFormatter(file, ["xls", "xlsx"], true);
    },
    /**
     *  检测size
     */
    checkUploadSize(file, size = 50) {
        if (file == null) {
            return false;
        }

        const isLt2M = file.size / 1024 / 1024 < size;
        if (!isLt2M) {
            HudGlobal.showWarningWithMessage(`上传文件大小不能超过 ${size}MB!`);
        }
        return isLt2M;
    },
    /**
     * 导入文件
     */
    checkBaseFileFormatter(file, checkList, isAlert) {
        if (file == null) {
            return false;
        }
        const fileArray = file.name.split(".");
        if (fileArray == null || fileArray.length < 2) {
            return false;
        }
        const fileName = fileArray.pop();
        const sourseNames = checkList;
        const result = sourseNames.indexOf(fileName) != -1;
        if (!result) {
            if (isAlert) {
                HudGlobal.showWarningWithMessage("文件格式错误,请检测文件格式");
            }
        } else {
        }
        return result;
    },
    /**
     * 文件是否存在
     */
    isExitFileInArrayByName(name, fileList) {
        if (name == null || name.length == 0) {
            return false;
        }
        if (fileList == null || fileList.length == 0) {
            return false;
        }
        for (let i = 0; i < fileList.length; i++) {
            const item = fileList[i];
            const imageName = item.name || item.fileName;
            if (imageName == name) {
                return true;
            }
        }
        return false;
    },
    /**
     * 格式化文件大小 - 输入单位本来就是MB
     */
    formatFileSize(sizeInBytes) {
        if (!sizeInBytes) return "0GB";
        const GB = 1024;
        const MB = 1;
        sizeInBytes = Number(sizeInBytes);
        if (sizeInBytes >= GB) {
            const sizeInGB = (sizeInBytes / GB).toFixed(2);
            return `${sizeInGB}GB`;
        } else if (sizeInBytes >= MB) {
            const sizeInMB = (sizeInBytes / MB).toFixed(2);
            return `${sizeInMB}MB`;
        } else {
            return `${sizeInBytes}B`;
        }
    },
    /**
     * 视频文件格式检测
     */
    checkVideoUploadFormat(file) {
        // 视频
        // const isVideo = file.type == "video/avi" || file.type == "video/mp4";
        const isVideo = file.type == "video/mp4";
        if (!isVideo) {
            HudGlobal.showErrorWithMessage("只能上传MP4格式");
        }
        return isVideo;
    },
    /**
     * 删除文件
     * @param file 将被删除的文件
     * @param fileList 总的文件集合
     * */
    handleFileRemove(file, fileList) {
        console.log("handleFileRemove", file, fileList);
        if (!fileList) {
            return [];
        }
        const index = fileList.findIndex((item) => {
            return item.name == file.name;
        });
        console.log("handleFileRemove index", index);
        if (index != -1) {
            fileList.splice(index, 1);
        }
        return fileList;
    },
};
