export default {
    setUserId(userId) {
        sessionStorage.setItem("userId", userId);
    },
    getUserId() {
        return sessionStorage.getItem("userId") || "";
    },
    removeUserId() {
        sessionStorage.removeItem("userId");
    },
    removeAllSessionStorageData() {
        sessionStorage.clear();
    },
};
