/* eslint-disable */
const state = {
    // vue 错误日志 最多保存5条
    vueLogs: [],
    // 最多存放日志条数
    vueLogsMaxCount: 5,
    // js 错误日志
    jsLogs: [],
    // js 错误日志的最大的条数
    jsLogsMaxCount: 5,
};

const mutations = {
    ADD_VUE_ERROR_LOG: (state, log) => {
        if (state.vueLogs.length >= state.vueLogsMaxCount) {
            const len = state.vueLogs.length - state.vueLogsMaxCount;
            state.vueLogs.splice(state.vueLogsMaxCount, len);
        }
        // 插入第一条
        state.vueLogs.unshift(log);
    },
    CLEAR_VUE_ERROR_LOG: (state) => {
        state.vueLogs.splice(0);
    },

    /**
     * 新增js的错误日志收集
     */
    ADD_JS_ERROR_LOG: (state, log) => {
        if (state.jsLogs.length >= state.jsLogsMaxCount) {
            const len = state.jsLogs.length - state.jsLogsMaxCount;
            state.jsLogs.splice(state.jsLogsMaxCount, len);
        }
        // 插入第一条
        state.jsLogs.unshift(log);
    },
    /**
     * 清楚 js 的错误日志
     */
    CLEAR_JS_ERROR_LOG: (state) => {
        state.jsLogs.splice(0);
    },
};

const actions = {
    addVueErrorLog({ commit }, log) {
        commit("ADD_VUE_ERROR_LOG", log);
    },
    clearVueErrorLog({ commit }) {
        commit("CLEAR_VUE_ERROR_LOG");
    },
    addJSErrorLog({ commit }, log) {
        commit("ADD_JS_ERROR_LOG", log);
    },
    clearJSErrorLog({ commit }) {
        commit("CLEAR_JS_ERROR_LOG");
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
