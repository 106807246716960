/**
 *   功能: 全局常量
 *
 *   使用方式:
 *   1. 导入文件
 *   import {RequestCodeConstants} from "@/utils/constants/RequestCodeConstants.js";
 *
 *   2. 调用方法
 *   RequestCodeConstants.TB_SYSTEM_PRODUCT_TYPE
 *
 *
 * */

const RequestCodeConstants = {
    /**
     * 组织页面的必填数据
     */
    TB_ORG_UPDATE_MUST_FIELD: "org_update_must_field",
    /**
     * 顶部和左侧logo
     */
    TB_LOGIN_INDEX_LEFT_TOP_LOGO: "admin_login_index_left_top_logo",

    /*
     * 客户端名称
     * */
    TB_SYSTEM_PARAMETER_TYPE: "system_parameter_type",
    /*
     * 客户端名称
     * */
    TB_SYSTEM_PRODUCT_TYPE: "system_product_type",

    /**
     * APP升级客户端名称 lzb 2021-07-28
     * */
    TB_SYSTEM_APP_PRODUCT_TYPE: "app_platform_product",

    /*
     *资源类型
     */
    TB_SYSTEM_RESOURCE_TYPE: "system_resource_type",

    /*
     * 行业类型
     * */
    TB_SYSTEM_INDUSTRY_TYPE: "system_industry_type",

    /*
     * 行业code
     * */
    TB_SYSTEM_INDUSTRY_TYPE_INDUSTRY: "system_industry_type_industry",

    /*
     * 岗位code
     * */
    TB_SYSTEM_INDUSTRY_DUTY_STATION: "system_industry_type_station",
    /*
     *  权限类型
     * */
    TB_SYSTEM_PERMISSION_TYPE: "system_permission_type",

    /*
     * 教室培训类型
     * */
    TB_SYSTEM_CLASS_TRAIN_TYPE: "system_train_type",
    /*
     * 教室用途
     * */
    TB_SYSTEM_CLASSROOM_USE: "classroom_use",
    /*
     * 组织类型参数
     * */
    TB_SYSTEM_ORGANIZE_TYPE: "system_organization_type",

    /*
     * 组织性质参数
     * */
    TB_SYSTEM_ORGANIZE_NATURE: "system_organization_nature_v2",

    /*
     * 组织身份类型
     * */
    TB_SYSTEM_ORGANIZE_IDENTITY_TYPE: "system_organization_identity",
    /*
     * 组织身份类型-总部
     * */
    TB_SYSTEM_ORGANIZE_IDENTITY_TYPE_ADMIN: "system_organization_identity_head_all",
    /*
     * 组织身份类型-非总部
     * */
    TB_SYSTEM_ORGANIZE_IDENTITY_TYPE_NO_ADMIN: "system_organization_identity_all",
    /*
     * 系统操作日志类型编码
     * */
    TB_SYSTEM_OPERATION_LOG_TYPE: "system_operation_log_type",

    /*
     * 系统操作日志功能模块
     * */
    TB_SYSTEM_OPERATION_LOG_MODULE: "system_operation_log_module",

    /*
     * 系统审核流程类型
     * */
    TB_SYSTEM_AUDIT_PROCESS_TYPE: "system_audit_process_type",

    /*
     * 培训计划、计划类型
     * */
    TB_TRAIN_PLAN_TYPE: "tran_plan_type",

    /*
     * 培训计划、计划模式
     * */
    TB_TRAIN_PLAN_MODE: "tran_planning_mode",

    /**
     *  个人支付是否开启 - 2020-10-30 lzb
     * */
    TB_TRAIN_PLAN_ONLINE_PAY: "online_pay_open",

    /*
     * 授权管理使用用途
     * */
    TB_AUTH_PURPOSE_TYPE: "auth_purpose_type",

    /*
     * 使用位置 参数
     * */
    TB_SYSTEM_BANNER_POSITION: "system_banner_position",
    /*
     * 使用方式 （授权管理）
     * */
    TB_SYSTEM_AUTH_PURPOSE_TYPE: "system_auth_purpose_type",
    /*
     * 站点模板URL
     * */
    TB_SITE_TEMPLATE_URL: "site_template_url",
    /*
     *  课件分类
     * */
    TB_COURSE_CATEGRORY: "course_classification_type",
    /**
     *  课件使用场景 v1.0.15 lzb 2021-10-11
     * */
    TB_COURSE_EMPLOY_SCENE_TYPE: "COURSE_EMPLOY_SCENE_TYPE",

    /*
     * 课件标签
     * */
    TB_COURSE_LABEL_TYPE: "course_label_type",
    /*
     * 试卷管理-考试时长
     * */
    TB_PAPER_MANAGER_EXAM_DURATION: "paper_manager_exam_duration",
    /**
     * 学时时长（秒）
     */
    TB_STUDY_TIME_DURATION: "study_time_duration",
    /**
     * 课件时间大于计划学时的浮动时间(秒)
     */
    TB_COURSE_STUDY_TIME_FLOAT_TIME: "course_study_time_float_time",
    /**
     * 培训结束后是否可以补学
     */
    TB_STUDY_RESTUDY_ENABLE: "study_restudy_enable",
    /**
     * 培训结束补学最长月数(月)
     */
    TB_STUDY_RESTUDY_MAX_MONTH: "study_restudy_max_month",
    /**
     * 计划模式
     */
    TB_PLAN_TRAINING_MODEL: "plan_training_model",
    /**
     * 补学用于快速选择的周期
     */
    TB_STUDY_RESTUDY_DAY: "study_restudy_day",
    /**
     * 培训计划新增的其他设置是否展示
     */
    TB_PLAN_TRAINING_OTHER_SET: "plan_training_other_set",
    /**
     * 0：不限，1：当月，2：当季，3：当年
     */
    TB_PLAN_PERIOD: "plan_period",
    /**
     * 计划强制考试 默认 0 不强制，1 强制网络考试 2 强制现场考试 3.强制网络+现场考试；
     */
    TB_PLAN_FORCE_EXAM: "plan_force_exam",
    /**
     * 计划监督人
     */
    TB_PLAN_TRAIN_ADMIN_SELECT: "plan_train_admin_select",
    /**
     * 计划模式申请凭证内容
     */
    TB_PLAN_MODEL_EVIDENCE_CONTENT: "plan_model_evidence_content",
    /**
     * 计划是否需要勾选已知悉
     */
    TB_MODEL_ONLINE_APPLY_EVIDENCE: "plan_model_online_apply_evidence",
    /**
     * 计划标签
     */
    TB_PLAN_LABEL_TYPE: "plan_label_type",

    /**
     * 会议默认 lzb P1.0.28
     */
    TB_PLAN_MEETING_MODEL: "plan_meeting_model",
    /**
     * 安全例会上传会议视频数量限制 lzb P1.0.28
     */
    TB_MEETING_VIDEO_COUNT_LIMIT: "safe_meeting_update_video_num_limit",
    /**
     * 安全例会上传会议视频大小限制 lzb P1.0.28
     */
    TB_MEETING_VIDEO_SIZE_LIMIT: "safe_meeting_update_video_size_limit",
    /**
     * 安全例会上传会议文档数量限制 lzb P1.0.28
     */
    TB_MEETING_DOC_COUNT_LIMIT: "safe_meeting_update_doc_num_limit",

    /**
     * 安全例会上传会图片数量限制 lzb P1.0.28
     */
    TB_MEETING_PIC_COUNT_LIMIT: "safe_meeting_update_pic_num_limit",
    /**
     * 计划文件内容上传图片数量限制 rqmei P1.0.32
     */
    TB_PLAN_UPLOAD_PIC_NUM_LIMIT: "plan_upload_pic_num_limit",
    /**
     * 计划文件内容上传文件数量限制 rqmei P1.0.32
     */
    TB_PLAN_UPLOAD_DOC_NUM_LIMIT: "plan_upload_doc_num_limit",
    /**
     * 计划文件内容上传文件大小限制 rqmei P1.0.32
     */
    TB_PLAN_UPLOAD_DOC_SIZE_LIMIT: "plan_upload_doc_size_limit",
    /**
     * 计划文件内容上传图片大小限制 rqmei P1.0.32
     */
    TB_PLAN_UPLOAD_PIC_SIZE_LIMIT: "plan_upload_pic_size_limit",

    /*
     *  公文类型
     * */
    TB_DOCUMENT_TYPE: "document_type",
    /*
     *  公文保密等级
     * */
    TB_DOCUMENT_SECURITY: "document_security",
    /*
     *  公文紧急程度
     * */
    TB_DOCUMENT_PRIORITY: "document_priority",
    /*
     *  公文审核信息
     * */
    TB_DOCUMENT_OFFICICAL_AUDIT: "official_audit",

    /*
     *  改价规则审核信息
     * */
    TB_DOCUMENT_REPRICE_AUDIT: "reprice_audit",

    /**
     * 改价课件模式
     */
    TB_REPRICE_LESSON_USERMODEL: "pricingrules",

    /*
     * 学员人像审核操作类型 参数
     * */
    TB_SYSTEM_FACE_OPERATION_TYPE: "face_operation_type",

    /*
     * 设备类型 参数
     * */
    TB_FACILISTY_TYPE_CODE: "facility_type_code",
    TB_FACILISTY_TYPE_CODE_KEY: "TRAILE_VEHICLE_TYPE_CODE",
    // rqmei 20240119 1.0.32 企业级计划是否支持文件上传
    TB_COMPANY_PLAN_ADD_FILE: "company_plan_add_file",

    //= ============================行业-设施===================================
    /*
     * 行业- 设施
     * */
    TB_SYSTEM_INDUSTRY_TYPE_DEVICE: "system_industry_type_device",

    /*
     * 分类类型
     * */
    TB_SYSTEM_DEVICE_CLASS: "SYSTEM_DEVICE_CLASS",

    // =============================培训类别===================================
    /**
     * 培训属性
     */
    TB_SYSTEM_SUBJECT_TRAIN_CONTENT: "subject_training_content",
    /**
     * 培训课件
     */
    TB_SYSTEM_SUBJECT_TRAIN_COURSE: "subject_training_course",
    /**
     * 考试题库
     */
    TB_SYSTEM_SUBJECT_TRAIN_EXAM_LIB: "subject_training_exam_lib",

    // =============================监管检察===================================
    /**
     *  检察分类
     */
    TB_SYSTEM_SUPERVISION_CLAZZ_CODE: "CHECK_TARGET",
    /**
     *  检察条件编码
     */
    TB_SYSTEM_SUPERVISION_CONDITION_CODE: "CHECK_TRAINING",
    /**
     *  检查周期
     */
    TB_SYSTEM_SUPERVISION_PERIOD_CODE: "SUPERVISION_PERIOD_CODE",
    /**
     *  强化培训合集
     */
    TB_SYSTEM_SUBJECT_CODES: "intensive_subject_codes",

    //= ============================考试===================================
    /*
     * 考试标签
     * */
    TB_EXAM_LABEL_TYPE: "exam_exercises_lable",

    TB_EXAM_ONLINE_EXAM_DURATION: "online_exam_duration",

    TB_EXAM_ONLINE_PASSING_SCORE: "online_exam_passing_score",

    TB_EXAM_OFFLINE_EXAM_DURATION: "offline_exam_duration",

    TB_EXAM_OFFLINE_PASSING_SCORE: "offline_exam_passing_score",

    TB_EXAM_IMPORT: "import_template",

    /**
     * 水印标题
     */
    TB_COMMON_WATERMARK_CONTENT: "common_watermark_content",

    /**
     *  企业认证 类型 企业
     */
    TB_ORG_AUTH_ATTACHMENT_TYPE: "org_auth_attachment_type",

    /**
     *  企业认证 类型  个体户
     */
    TB_ORG_SELF_AUTH_ATTACHMENT_TYPE: "org_self_auth_attachment_type",

    /**
     * 学员审核快捷选择
     */
    TB_STUDENT_SHORTCUTOPTIONS_CONTENT: "system_face_audit_cause",

    /**
     * 菜单顶部图片 - banner 配置
     */
    TB_MENU_TOP_LOG: "banner_pc_admin_left_top",

    // ==============================企业付费 业务管理=================================
    /**
     * 业务分类
     */
    TB_BUSINESS_MANAGER_TYPE: "business_type",

    // ==============================项目配置参数lzb 2021-07-21蜀交安培新增=================================

    /**
     * 底部footer
     */
    FOOTER: {
        TB_FOOTER_QR_LIST: "org_site_config_pc_bottom_qrcode", // --app下载二维码
        TB_FOOTER_SERVICES_SUPPORT_LIST: "org_site_config_services_support", // 服务与支持
        TB_FOOTER_ABOUT_MINE_LIST: "org_site_config_about_product", // 关于我们
        TB_FOOTER_FRIENDLY_LINK_LIST: "org_site_config_friendly_link", // 友情链接
        TB_FOOTER_HOT_LINT_LIST: "org_site_config_pc_services", // 咨询热线
        TB_FOOTER_VERSION_INFO: "org_site_config_version_info", // 站点设置-底部版本信息
    },
    /**
     * 登录表单
     */
    LOGIN_FORM: {
        TB_LOGIN_FORM_PRODUCT_NAME: "org_site_config_product", // 站点设置-项目名称
        TB_LOGIN_FORM_USER_PROTOCOL: "org_site_config_agreement_user", // 站点设置-用户协议
        TB_LOGIN_FORM_PRIVATE_PROTOCOL: "org_site_config_agreement_privacy", // 站点设置-隐私协议
    },
    /**
     * 项目
     */
    PROJECT_CONFIG: {
        TB_WEB_WINDOW_ICON: "org_site_config_web_title_logo", // 网站的窗口图标
    },
    /**
     * 打印参数
     */
    PRINT_PARAMS: {
        print_mobile_is_hide: "print_mobile_is_hide", // 打印-手机号是否隐藏
        print_id_card_is_hide: "print_id_card_is_hide", // 打印-身份证号码是否隐藏
        TB_PRINT_PLAN_DETAIL_DISPLAYED_PHONE: "print_training_photo_displayed_number", // 计划详情打印是否展示学习照片
        print_is_show_style: "print_is_show_style", // 打印页面，增加边框
    },
    // ==============================登录template2=================================
    /**
     * 悬浮窗在线咨询
     */
    ORG_SITE_CONFIG_ADMIN_ONLINE_SERVICES: "org_site_config_admin_online_services",
    /**
     * 站点菜单配置
     */
    ORG_SITE_CONFIG_MENU_PRODUCT: "org_site_config_menu_product",
    /**
     * 友情链接
     */
    ORG_SITE_CONFIG_FRIENDLY_LINK: "org_site_config_friendly_link",
    /**
     * 关于我们
     */
    ORG_SITE_CONFIG_ABOUT_PRODUCT: "org_site_config_about_product",
    /**
     * 底部电话号码
     */
    ORG_SITE_CONFIG_BOTTOM_PHONE: "org_site_config_bottom_phone",
    // ===============================数据管理====================
    /**
     * 第三方平台分类
     */
    THIRD_PARTY_PLATFORM_TYPE_CODE: "thirdparty_platform_type_code",
    /**
     * 标准名称转换分类
     */
    DATA_CONVERSION_TYPE: "data_conversion_type",

    // ===============================下发计划管理====================
    /**
     * 模板使用设置-适用平台
     */
    PLAN_USE_TEMPLATE_PLATFORM: "intensive_product_clazz",

    // =================================公共部分=====================

    // 验证码时间限制20221215 lzb
    MOBILE_MESSAGE_LIMIT_TIME: "MOBILE_MESSAGE_LIMIT_TIME",
    // 是否有滑动验证 20221215 lzb
    SLIDER_VERIFICATION: "slider_verification",

    // ===============================证书打印====================

    // 证书模版管理-模版分类
    CERT_PRINT_TEMPLATE_CLASSIFY: "cert_template_type",
    // 证书模版管理-模版参数类型
    CERT_PRINT_TEMPLATE_PARAMETER_TYPE: "cert_template_parameter_type",
    // 证书管理-证书分类
    CERT_PRINT_MANAGER_CLASSIFY: "cert_manage_type",
    // 证书管理-证书编码
    CERT_PRINT_MANAGER_NUMBER_TYPE: "cert_manage_code_type",

    // 协议管理-协议分类
    AGREEMENT_MANAGER_CLASSIFY: "agreement_category",
    // 协议管理-协议分类 yxl v1.0.33 20240221
    AGREEMENT_MANAGER_NO_HEAD_CLASSIFY: "agreement_category_not_headquarter",
    // 协议管理-承诺书文件上传数量限制
    COMMITMENT_LETTER_FILE_UPLOAD_NUM_LIMIT: "commitment_letter_file_upload_num_limit",
    // 协议管理-承诺书文件上传大小限制
    COMMITMENT_LETTER_FILE_UPLOAD_SIZE_LIMIT: "commitment_letter_file_upload_size_limit",
    // 协议管理-使用路径 lzb 20240604
    AGREEMENT_USE_PATH: "agreement_use_path",
    // 协议使用场景 lzb 20240604
    AGREEMENT_USE_SCENE: "agreement_use_scene",

    // 签名分类
    AGREEMENT_MANAGER_SIGN_CLASSIFY: "signature_category",
    AGREEMENT_MANAGER_SIGN_NO_CLASSIFY: "signature_category_other", // 非总部
    // 打印审核-证书分类
    CERT_PRINT_AUDIT_CERT: "cert_manage_type",

    // 监管计划新增-新增编辑是否展示其它培训
    PLAN_OTHER_CONFIG: "plan_other_config",
    // 监管计划新增-从业类型
    PROFESSIONAL_CLAZZ: "safety_education_training_cert_professional_clazz",

    // 合格证- 考核部门的code
    SAFETY_EDUCATION_TRAINING_CET_DUTY: "safety_education_training_cert_duty",

    // ===============================系统管理====================
    SYSTEM: {
        // 导出文件功能模块
        EXPORT_FILE_MODULE: "export_file_module_code",
    },
    // 1.0.28 - 安全例会佐证材料限制个数
    SAFE_MEETING_UPDATE_SUPPORTING_MATERIALS_NUM_LIMIT: "safe_meeting_update_supporting_materials_num_limit",
    // 组织来源 - 1.0.31
    COMPANY_RESOURCE: "company_resource",
};
export { RequestCodeConstants };

/**
 * 一日三检
 */
export const DEVICE_CHECK = {
    // 设备检测-车辆例检类型
    DEVICE_CHECK_CAR_CATEGORY: "device_check_cagegory",
    // 设备检测-车辆例检数据源
    DEVICE_CHECK_CAR_DATA_SOURCE: "device_check_datasource",
};

/*
 * 上传参数 lzb 20240318
 */
export const RequestUploadParam = {
    // 上传平台
    upload_oss_type: "upload_oss_type",
    // 导出导出失效时间
    upload_file_io_expire: "upload_file_io_expire",
};

/**
 * 员工相关
 */
export const STUDENT = {
    // 人员性别、出生日期、民族、注册照
    STUDENT_ADD_REQUIRED: "user_update_must_field",
    // 民族
    STUDENT_ADD_NATION: "nation",
    // 国籍
    STUDENT_ADD_NATIONALITY: "nationality",

    // 人脸图片限制
    STUDENT_FACE_IMAGE_SIZE_LIMIT: "face_image_size_limit",
};

/**
 *  课件模块
 */
export const COURSE = {
    AUDIT_INFO: "course_audit_default_info", // 审核信息
};

/**
 * 系统配置
 */
export const SYSTEM_CONFIG = {
    HAS_OFFLINE_TRAIN: "is_offline_train", // 0否：不支持企业现场学习，不展示顶部“现场学习”操作按钮； 1是：则跟当前线上一致，正常显示现场学习按钮及操作现场学习功能；
    COMMON_UPLOAD_PIC_SIZE_LIMIT: "common_upload_pic_size_limit", // 单位MB，0表示不限制，大小0就是限制的值，默认2  上传图片参数
};
