import EnumConstants from "../constants/EnumConstants";
import { RouterConstants } from "../constants/RouterConstants";
import StoreUserDB from "../db/StoreUserDB";
import loginHttp from "../http/loginhttp";
import { getCookieOrgId, getCookieToken, removeCookieToken } from "../tool/TokenUtil";
import { isLoadedRoutePath } from "@utils/router/RouteUtil";
import userUtil from "./userUtil.js";
import LoginManager from "@utils/manager/LoginManager";

/**
 *  通过本地的cookie获取token
 */
export const fetchAuthTokenByCookie = () => {
    return new Promise((resolve, reject) => {
        const subToken = getCookieToken();

        if (!!subToken && subToken.length > 0) {
            loginHttp
                .getTokenById({ tokenId: subToken })
                .then((res) => {
                    if (res && res.success) {
                        StoreUserDB.saveUserToken(res.data);
                        resolve(0);
                    } else {
                        resolve(-1);
                    }
                })
                .catch(() => {
                    resolve(-1);
                });
        } else {
            resolve(-1);
        }
    });
};

/**
 *  刷新token  lzb 2023-11-03
 */
export const refreshTokenFromRemote = () => {
    return new Promise((resolve, reject) => {
        loginHttp
            .refreshToken()
            .then((res) => {
                if (res && res.success) {
                    StoreUserDB.saveUserToken(res.data);
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
            .catch(() => {
                resolve(false);
            });
    });
};

/**
 * 自动登录
 * @param {z} next
 */
export const autoLoginByToken = (next, to) => {
    return new Promise((resolve, reject) => {
        const param = {
            ssoToken: StoreUserDB.getUserToken(),
            userId: getCookieToken(),
            orgId: getCookieOrgId(),
            userLoginIdentity: "system_org_indentity_admin",
            loginMode: "AUTO_LOGIN",
            productCode: EnumConstants.projectClackType.SYSTEM_CLAZZ_TB_ATB,
        };
        userUtil
            .login(param)
            .then((res) => {
                if (res && res.success) {
                    // 自动登录到主页
                    let targetPath = to && to.path ? to.fullPath : RouterConstants.FIX_PATH;
                    const isExited = isLoadedRoutePath(targetPath);
                    if (!isExited) targetPath = RouterConstants.FIX_PATH;
                    next(targetPath);
                    resolve && resolve(0);
                } else {
                    resolve && resolve(1);
                }
            })
            .catch((error) => {
                console.log("自动登录失败----", error);
                resolve && resolve(1);
            });
    });
};

/**
 * 自动登录
 * @param {z} next
 */
export const autoLoginByTokenToFixPath = (fixPath) => {
    const that = this;
    return new Promise((resolve, reject) => {
        const param = {
            ssoToken: StoreUserDB.getUserToken(),
            userId: getCookieToken(),
            orgId: getCookieOrgId(),
            userLoginIdentity: "system_org_indentity_admin",
            loginMode: "AUTO_LOGIN",
            productCode: EnumConstants.projectClackType.SYSTEM_CLAZZ_TB_ATB,
        };
        userUtil
            .login(param)
            .then((res) => {
                if (res && res.success) {
                    // 自动登录成功
                    let targetPath = fixPath && fixPath.length > 0 ? fixPath : RouterConstants.FIX_PATH;
                    that.$router.replace({
                        path: targetPath,
                    });
                    resolve && resolve(true);
                } else {
                    resolve && resolve(false);
                    console.log("自动登录失败----", res);
                    LoginManager.checkJumpToLogin();
                    removeCookieToken();
                }
            })
            .catch((error) => {
                resolve && resolve(false);
                console.log("自动登录失败----", error);
                LoginManager.checkJumpToLogin();
                removeCookieToken();
            });
    });
};
