import store from "@/store";
/**
 * 操作global store lzb-2021-05-17
 */
export default {
    // ==============================================保存未读消息=========================================//
    /**
     * 刷新未读消息
     */
    refreshUnreadMessageTotal(total) {
        total = total || 0;
        store.dispatch("global/refreshUnreadMessageTotal", total);
    },
    /**
     * 获取未读消息
     */
    getUnreadMessageTotal() {
        if (store.state && store.state.global) {
            const total = store.state.global.unreadMessageTotal;
            return total || 0;
        } else {
            return 0;
        }
    },
    /**
     * 移除未读消息
     */
    removeUnreadMessageTotal() {
        store.dispatch("global/refreshUnreadMessageTotal", 0);
    },

    // ==============================================保存未读新闻=========================================//
    /**
     * 刷新未读新闻
     */
    refreshUnreadNewsTotal(total) {
        total = total || 0;
        store.dispatch("global/refreshUnreadNewsTotal", total);
    },
    /**
     * 获取未读新闻
     */
    getUnreadNewsTotal() {
        if (store.state && store.state) {
            const total = store.state.global.unreadNewsTotal;
            return total || 0;
        } else {
            return 0;
        }
    },
    /**
     * 移除未读新闻
     */
    removeUnreadNewsTotal() {
        store.dispatch("global/refreshUnreadNewsTotal", 0);
    },

    // ==============================================保存是否记住计划模板标识=========================================//
    /**
     * 刷新记住计划模板标识
     */
    refreshRememberCreatePlanFlag(flag) {
        store.dispatch("global/refreshRememberCreatePlanState", flag);
    },
    /**
     * 获取记住计划模板标识
     */
    getRememberCreatePlanFlag() {
        if (store.state && store.state) {
            const rememberFlag = store.state.global.rememberFlag;
            return rememberFlag || false;
        } else {
            return false;
        }
    },
    /**
     * 移除记住计划模板标识
     */
    removeRememberCreatePlanFlag() {
        store.dispatch("global/refreshRememberCreatePlanState", false);
    },

    // ==============================================保存我的组织是否被引导状态=========================================//
    /**
     * 刷新我的组织是否被引导状态
     */
    refreshGuidedMineOrgFlag(flag) {
        store.dispatch("global/refreshGuidedMineOrgState", flag);
    },
    /**
     * 获取我的组织是否被引导状态
     */
    getGuidedMineOrgFlag() {
        if (store.state && store.state) {
            const rememberFlag = store.state.global.isGuidedMineOrg;
            return rememberFlag || false;
        } else {
            return false;
        }
    },
    /**
     * 移除我的组织是否被引导状态
     */
    removeGuidedMineOrgFlag() {
        store.dispatch("global/refreshGuidedMineOrgState", false);
    },

    // ==============================================保存freeAPI更新时间=========================================//
    /**
     * 刷新freeAPI更新时间
     */
    refreshFreeApiObject(date) {
        store.dispatch("global/refreshFreeApiObject", date);
    },
    /**
     * 获取freeAPI更新时间
     */
    getFreeApiObject() {
        if (store.state && store.state) {
            const obj = store.state.global.updateFreeAPIObj;
            return obj;
        } else {
            return {};
        }
    },
    /**
     * 移除我的组织是否被引导状态
     */
    removeFreeApiObject() {
        store.dispatch("global/refreshFreeApiObject", {});
    },

    // ==============================================保存未审核组织数量=========================================//
    /**
     * 刷新未审核组织数量
     */
    refreshWaitAuditOrgCount(total) {
        total = total || 0;
        store.dispatch("global/refreshUnAuditOrgCount", total);
    },
    /**
     * 获取未审核组织数量
     */
    getWaitAuditOrgCount() {
        if (store.state && store.state) {
            const total = store.state.global.unAuditOrgCount;
            return total || 0;
        } else {
            return 0;
        }
    },
    /**
     * 移除未审核组织数量
     */
    removeWaitAuditOrgCount() {
        store.dispatch("global/refreshUnAuditOrgCount", 0);
    },

    // ==============================================保存项目配置lzb 2021-07-30=========================================//
    /**
     * 刷新保存项目配置
     */
    refreshProjectConfig(config) {
        store.dispatch("global/refreshProjectConfig", config);
    },
    /**
     * 获取保存项目配置
     */
    getProjectConfig() {
        if (store.state && store.state.global) {
            const config = store.state.global.projectConfig;
            return config;
        } else {
            return {};
        }
    },
    /**
     * 移除保存项目配置
     */
    removeProjectConfig() {
        store.dispatch("global/refreshProjectConfig", {});
    },

    // ==============================================移除global所有信息=========================================//
    /**
     * 移除global所有信息
     */
    removeGlobalAllData() {
        store.dispatch("global/removeGlobalStateData");
    },
};
