export default {
    /**
     * 获取object -> url
     */
    getParamPath(object) {
        if (!object) {
            return "";
        }
        const tempList = [];
        for (const key in object) {
            const str = `${key}=${object[key]}`;
            tempList.push(str);
        }
        const str = tempList.join("&");
        return str;
    },
    /**
     * 判断是否是空对象
     */
    isEmptyObject(data) {
        if (!data) {
            return true;
        }
        const arr = Object.keys(data);
        if (arr.length === 0) {
            return true;
        }
        return false;
    },
    checkType(any) {
        return Object.prototype.toString.call(any).slice(8, -1);
    },
    /**
     * 深拷贝2021-09-23
     */
    deepClone(any) {
        if (this.checkType(any) === "Object") {
            // 拷贝对象
            const o = {};
            for (const key in any) {
                o[key] = this.deepClone(any[key]);
            }
            return o;
        } else if (this.checkType(any) === "Array") {
            // 拷贝数组
            const arr = [];
            for (let i = 0, leng = any.length; i < leng; i++) {
                arr[i] = this.deepClone(any[i]);
            }
            return arr;
        } else if (this.checkType(any) === "Function") {
            // 拷贝函数
            return new Function(`return ${any.toString()}`).call(this);
        } else if (this.checkType(any) === "Date") {
            // 拷贝日期
            return new Date(any.valueOf());
        } else if (this.checkType(any) === "RegExp") {
            // 拷贝正则
            return new RegExp(any);
        } else if (this.checkType(any) === "Map") {
            // 拷贝Map 集合
            const m = new Map();
            any.forEach((v, k) => {
                m.set(k, this.deepClone(v));
            });
            return m;
        } else if (this.checkType(any) === "Set") {
            // 拷贝Set 集合
            const s = new Set();
            for (const val of any.values()) {
                s.add(this.deepClone(val));
            }
            return s;
        }
        return any;

        // let result;
        // if (typeof target === 'string' || typeof target === 'number' || typeof target === 'boolean' || typeof target === 'undefined') {
        //     return target;
        // }
        // // 如果不是对象
        // if(typeof target !== 'object' || !target) return target;
        //
        // // 如果是数组
        // if(Array.isArray(target)){
        //     result = [];
        //     for(const i in target){
        //         // 递归克隆数组中的每一项
        //         result.push(this.deepClone(target[i]));
        //     }
        // }else if(target.constructor == RegExp){
        //     // 判断如果当前的值是一个RegExp对象的话，直接赋值
        //     result = target;
        // }else {
        //     // 普通对象
        //     result = {};
        //     for (const key in target){
        //         result[key] = this.deepClone(target[key]);
        //     }
        // }
        // return  result;
    },
    /** ************************************比较json-start************************************** */
    // 判断两个对象是否相等
    isEqual(objA, objB) {
        // 相等
        if (objA === objB) return objA !== 0 || 1 / objA === 1 / objB;
        // 空判断
        if (objA == null || objB == null) return objA === objB;
        // 数据类型判断
        if (Object.prototype.toString.call(objA) !== Object.prototype.toString.call(objB)) return false;

        switch (Object.prototype.toString.call(objA)) {
            case "[object RegExp]":
            case "[object String]":
                // 字符串转换比较
                return `${objA}` === `${objB}`;
            case "number":
            case "[object Number]":
                // 进行数字转换比较,判断是否为NaN
                // eslint-disable-next-line no-self-compare
                if (+objA !== +objA) {
                    // eslint-disable-next-line no-self-compare
                    return +objB !== +objB;
                }

                return +objA === 0 ? 1 / +objA === 1 / objB : +objA === +objB;
            case "[object Date]":
            case "[object Boolean]":
                return +objA === +objB;
            case "[object Array]":
                // 判断数组
                // rqmei 20230809 bug(16082)编辑平台，更新地区保存时，提示无更改；
                if (objA.length != objB.length) {
                    console.log("数组长度不一样", objA.length, objB.length, objA, objB);
                    return false;
                }
                for (let i = 0; i < objA.length; i++) {
                    if (!this.isEqual(objA[i], objB[i])) return false;
                }
                return true;
            case "[object Object]":
                // 判断对象
                // eslint-disable-next-line no-case-declarations
                let keys = Object.keys(objA);
                for (let i = 0; i < keys.length; i++) {
                    if (!this.isEqual(objA[keys[i]], objB[keys[i]])) return false;
                }

                keys = Object.keys(objB);
                for (let i = 0; i < keys.length; i++) {
                    if (!this.isEqual(objA[keys[i]], objB[keys[i]])) return false;
                }

                return true;
            default:
                return false;
        }
    },
    arrayEquals(array1, array2) {
        console.log("arrayEquals", array1, array2);
        // 先检查是否存在
        if (!(array1 || array1)) {
            return false;
        }
        // 先比较长度
        if (array1.length != array2.length) {
            return false;
        }

        for (let i = 0, l = array1.length; i < l; i++) {
            // 检查是否为内嵌数组
            if (array1[i] instanceof Array && array2[i] instanceof Array) {
                // 递归比较数组
                if (!arrayEquals(array1[i], array2[i])) return false;
            } else if (array1[i] != array2[i]) {
                //标量比较
                return false;
            }
        }
        return true;
    },
    /**
     * 对象中的数组转字符串
     * @param obj object对象
     */
    arrayToStr(obj) {
        if (!obj) {
            return "";
        }
        const keys = Object.keys(obj);
        // 处理后的对象
        const newObj = {};
        if (keys && keys.length > 0) {
            keys.forEach((item) => {
                if (Object.prototype.toString.call(obj[item]) === "[object Array]") {
                    // 数组转字符串
                    newObj[item] = obj[item].toString();
                } else {
                    // 直接赋值
                    newObj[item] = obj[item];
                }
            });
        }
        return newObj;
    },
    /**
     * 合并对象，相同属性合并不同属性不合并
     */
    mergeSameProperty(targetObject, sourceTarget) {
        const targetObjectIn = targetObject || {};
        const sourceTargetIn = sourceTarget || {};

        // eslint-disable-next-line guard-for-in
        // eslint-disable-next-line no-restricted-syntax
        for (const key in sourceTargetIn) {
            if (targetObjectIn.hasOwnProperty(key)) {
                targetObjectIn[key] = sourceTargetIn[key];
            }
        }
        return targetObjectIn;
    },
};
