import { RouterConstants } from "@utils/constants/RouterConstants";
import EnumConstants from "./EnumConstants";

/**
 *   功能: 全局常量
 *
 *   使用方式:
 *   1. 导入文件
 *   import {Constants} from "@/utils/constants/Constants.js";
 *
 *   2. 调用方法
 *   Constants.organizationType.ORGTYPE_HEAD_OFFICE
 *
 *
 * */
const Constants = {
    pageSizes: [10, 20, 50, 100, 200, 500],
    pageMaxSizes: [10, 20, 50, 100, 200, 500, 1000],
    pageSizeDefault: 10,
    customOrgIdDefault: 999999, // 自定义一组织id
    currentPage: 1,
    provinceId: 100, // 城市省 北京-100
    clientPhone: "400-888-4020",
    vuexKey: `tibi-manager-data`,
    defaultPwd: "", // 默认密码
    exportStaticFileUrl: process.env.VUE_APP_PDF_PREVIEW_URL, //"https://admin.tbatb.net/common-static-file/static/pdf/web/viewer.html?file=", // 导出静态链接前缀
    maxExportNumber: 1000,

    fileAllowedImageTypes: ["image/jpeg", "application/x-jpg", "image/png", "image/gif", "application/x-bmp"],
    // 数据看板列表滚动时间
    dataVisualScrollTime: 10000,
    // 学员分类
    clazzList: [
        // {
        //     value:100,
        //     label:'非培训员工'
        // },
        {
            value: 101,
            label: "普通员工",
        },
        {
            value: 102,
            label: "培训学员",
        },
        // {
        //     value:103,
        //     label:'培训监督员'
        // }
    ],
    /**
     * 身份类型
     */
    identityList: [
        {
            value: "system_org_indentity_student",
            label: "普通员工",
        },
        {
            value: "system_org_indentity_admin",
            label: "管理员",
        },
        {
            value: "system_org_indentity_out_student",
            label: "培训学员",
        },
        {
            value: "system_org_indentity_admin_safe",
            label: "安全管理员",
        },
        {
            value: "system_org_indentity_lecturer",
            label: "授课人",
        },
        {
            value: "system_org_indentity_assessors",
            label: "考核人员",
        },
        {
            value: "system_org_indentity_emcee",
            label: "会议主持人",
        },
    ],
    /**
     * 刘子彬新增路由白名单常亮 lzb 2020-07-08 整合而已 之前就有
     */
    routerWhiteList: [
        "/404",
        "/500",
        "/login",
        "/companyRegister",
        "/firstRegister",
        "/secondRegister",
        "/threeRegister",
        "/h5/app/download",
        "/company/app",
        "/student/app",
        "/login/one",
        "/loginTemplateTwo",
        "/loginTemplateOne",
        RouterConstants.EXTERNAL_LINE.WEB_PROTOCOL,
        RouterConstants.EXTERNAL_LINE.ANTUBANG_WEB,
        "/help/center",
        "/student/detail/print/preview",
        "/plan/detail/print/previews",
        "/safe/regular/detail/print/export",
        "/student/print/info",
        "/login/nopass",
        RouterConstants.UNIVERSAL.DATA_VISUAL_PLATFORM_PATH, // 数据看板
        RouterConstants.NEWS.COMPANY_NEWS_LIST, // 企业新闻
        RouterConstants.LOGIN_LESSON_NEWS_LIST, // 登录课件列表
        RouterConstants.MIDDLEWARE.CHANGE_ORG_PAGE,
        RouterConstants.NEWS.COMPANY_NEWS_LIST,
        RouterConstants.NEWS.COMPANY_NEWS_DETAIL,
        RouterConstants.LOGIN.LOGIN_TEMPLATE_THREE, // lzb 第三套模板
        "/print-list/PrintList", // 打印列表
        "/company-report-print/CompanyReportPrint", // rqmei 20220609 企业报告列表打印
        "/company-report/CompanyReportPlanPrint", // rqmei 20220609 企业报告-计划列表打印
        RouterConstants.MIDDLE_WARE,
        RouterConstants.COMMON_PAGE.PDF_PREVIEW,
        RouterConstants.SAFE_REGULAR.SAFE_REGULAR_PERSONAL_DETAIL_EXPORT,
        RouterConstants.SAFE_REGULAR.SAFE_REGULAR_PERSONAL_RECORD_EXPORT,
        "/safe/regular/archives/list/print", // 安全例会-列表打印
        "/safe/regular/archives/detail/print", // 安全例会-详情打印
        RouterConstants.COMMON_PAGE.QUALIFIED_CERT_PREVIEW,
    ],
    /**
     * 常用的弱密码  yxl 2020.08.18
     * */
    weakCipherList: [
        "112233",
        "123123",
        "123321",
        "654321",
        "abcabc",
        "abc123",
        "a1b2c3",
        "aaa111",
        "123qwe",
        "qwerty",
        "qweasd",
        "admin",
        "password",
        "p@ssword",
        "passwd",
        "iloveyou",
        "5201314",
        "asdfghjkl",
    ],

    resCode: {
        FACE_CHECKED: 12612,
    },

    /*
     *  常量地址配置
     * */
    ConstantsAPIConfig: {
        // 文件预览地址
        FILE_PREIVEW_API: "://view.officeapps.live.com/op/view.aspx?src=",
    },
    /**
     * 路由外链接跳转白名单
     */
    ExternalLinkRouter: [
        // 看板
        RouterConstants.UNIVERSAL.DATA_VISUAL_PLATFORM_PATH,
    ],
    /*
     *  组织类型
     *
     * */
    organizationType: {
        /*
         * 总部
         * */
        ORGTYPE_HEAD_OFFICE: "org_type_head_office",
        /*
         *  代理商
         * */
        ORGTYPE_AGENT: "org_type_agent",
        /*
         * 行业管理
         * */
        ORGTYPE_SUPPERVISE: "org_type_supervise",
        /*
         *  企业
         * */
        ORGTYPE_COMPANY: "org_type_company",
        /*
         * 机构
         * */
        ORGTYPE_ORGAN: "org_type_organ",
        /*
         * 经销商
         * */
        ORGTYPE_AGENCY: "org_type_agency",
    },
    /*
     *
     * 组织性质
     * */
    organizationNature: {
        /*
         * 私营
         * */
        ORGNATURE_PRAVITE: "system_org_nature_private",
        /*
         * 国营
         * */
        ORGNATURE_COUNTRY: "system_org_nature_nation",
        /*
         * 股份
         * */
        ORGNATURE_SHARE: "system_org_nature_holding",
        /*
         * 合资
         * */
        ORGNATURE_JOINT: "system_org_nature_venture",
        /*
         * 外资
         * */
        ORGNATURE_FOREIGN: "system_org_nature_merchant",
    },

    /*
     *   身份类型
     *
     * */
    organizeIndentityType: {
        /*
         *  学员
         * */
        INDENTITY_STUDENT: "system_org_indentity_student",

        /*
         *  管理员
         * */
        INDENTITY_ADMIN: "system_org_indentity_admin",

        /*
         *  安全管理员
         * */
        INDENTITY_SAFE_ADMIN: "system_org_indentity_admin_safe",

        /*
         *
         *  考核人员
         * */
        INDENTITY_ASSESSORS: "system_org_indentity_assessors",

        /*
         *
         *  授课人
         * */
        INDENTITY_LECTURER: "system_org_indentity_lecturer",
        /*
         *
         *  培训学员
         * */
        INDENTITY_OUT_STUDENT: "system_org_indentity_out_student",

        /*
         *
         *  会议主持人
         * */
        INDENTITY_OUT_EMCEE: "system_org_indentity_emcee",
    },

    /*
     *  站点管理
     *
     * */
    sizeUplodeType: {
        /*
         * 上传文件夹
         * */
        SIZE_UPDATE_NAME: "/size/templName",
        /*
         *  上传地址
         * */
        SIZE_UPDATE_URL: "org_type_agent",
    },

    /**
     * 设备类型
     */
    carType: {
        /**
         * 正常类型
         */
        REGULAR_TYPE: "REGULAR_TYPE",
        /**
         * 挂车
         */
        TRAILE_VEHICLE_TYPE_CODE: "TRAILE_VEHICLE_TYPE_CODE",
        /**
         * 小新能源车
         */
        LITTLE_NEW_ENERGY_VEHICLE_TYPE_CODE: "LITTLE_NEW_ENERGY_VEHICLE_TYPE_CODE",
        /**
         * 大新能源车
         */
        BIG_NEW_ENERGY_VEHICLE_TYPE_CODE: "BIG_NEW_ENERGY_VEHICLE_TYPE_CODE",
        /**
         * 警车
         */
        POLICE_VEHICLE_TYPE_CODE: "POLICE_VEHICLE_TYPE_CODE",
        /**
         * 消防车
         */
        PROTECTION_VEHICLE_TYPE_CODE: "PROTECTION_VEHICLE_TYPE_CODE",
        /**
         * 教练车
         */
        INSTRUCTIONAL_CAR: "INSTRUCTIONAL_CAR",
    },

    /*
     *  课件分类类型
     * */
    lessonCategroryType: {
        /**
         *  通用
         * */
        LESSON_CATEGRORY_TYPE_GENERAL: "course_classification_type_general",
        /**
         *  国家法律法规
         * */
        LESSON_CATEGRORY_TYPE_REGULATIONS: "course_classification_type_regulations",
        /**
         *  单位文件
         * */
        LESSON_CATEGRORY_TYPE_FILE: "course_classification_type_unit_file",
        /**
         *  道德素质教育
         * */
        LESSON_CATEGRORY_TYPE_EDUCATION: "course_classification_type_education",
    },

    /**
     * 课件使用场景V1.0.15 lzb 2021-10-11
     */
    lessonSceneType: {
        // 教学课程
        COURSE_EMPLOY_SCENE_TYPE_TEACH: "COURSE_EMPLOY_SCENE_TYPE_TEACH",
        // 安全课程
        COURSE_EMPLOY_SCENE_TYPE_SAFETY: "COURSE_EMPLOY_SCENE_TYPE_SAFETY",
        // 系统引导
        COURSE_EMPLOY_SCENE_TYPE_GUIDE: "COURSE_EMPLOY_SCENE_TYPE_GUIDE",
        //安全例会
        COURSE_EMPLOY_SCENE_TYPE_MEETING: "COURSE_EMPLOY_SCENE_TYPE_MEETING",
    },

    tibiOrg: {
        orgId: 110110110,
        orgName: "重庆替比网络科技有限公司",
    },
    /**
     * 新闻配置特殊类型10:关于我们,20:平台使用协议,30:操作指南类型,40:学习指南类型,50:考试须知,60:隐私声明 70 常见问题
     */
    newConfigType: {
        /**
         * 关于我们
         */
        ABOUT_ME: 10,
        /**
         * 平台使用协议
         */
        PLATFORM_USE_PROTOCOL: 20,
        /**
         * 操作指南
         */
        OPERATION_BOOK: 30,
        /**
         * 学习指南
         */
        STUDY_BOOK: 40,
        /**
         * 考试须知
         */
        EXAM_BOOK: 50,
        /**
         * 隐私声明
         */
        PRIVATE_BOOK: 60,
        /**
         * 常见问题
         */
        QUESTIONS_BOOK: 70,
    },
    /*
     * 打印列表分类
     * */
    printCategroryType: {
        /**
         * 企业报告列表
         */
        COMPANY_REPORT_TYPE: "company_report_current",
        /**
         * 企业报告列表-强化培训
         */
        COMPANY_REPORT_INTENSIVE_TYPE: "company_report_intensive",
        /**
         * 企业补录报告列表
         */
        COMPANY_SUPPLEMENT_REPORT_TYPE: "company_report_make_up",
        /**
         * 企业报告 - 学员列表
         */
        COMPANY_STUDENT_TYPE: "company_report_user",
        /**
         * 企业报告 - 计划列表
         */
        COMPANY_PLAN_TYPE: "company_report_plan",
        /**
         * 企业报告 - 强化培训的计划列表
         */
        COMPANY_INTENSIVE_PLAN_TYPE: "company_report_intensive_plan",

        /**
         * 考试记录详情
         */
        EXAM_RECORD_DETAIL_TYPE: "exam_record_detail",
        /**
         * 总监管计划
         */
        REQULAR_PLAN: "regular_plan",
    },

    /*
     * 企业认证  认证证书分类
     * */
    enterAuthenticaionType: {
        /**
         * 营业执照
         */
        ENTERN_AUTH_BUSINESS_TYPE: "business_license",
        /**
         * 认证公函
         */
        ENTERN_AUTH_OFFICIAL_TYPE: "official_document",
        /**
         * 其它资质
         */
        ENTERN_AUTH_OTHER_TYPE: "other_attachment",
    },

    /*
     * 控制台 banner位置
     * */
    bannerUsePositionCode: {
        /**
         * 企业
         */
        BANNER_DASHBOARD_COMPANY: "banner_dashboard_company",
        /**
         * 总部
         */
        BANNER_DASHBOARD_HEAD: "banner_dashboard_head",
        /**
         * 行管
         */
        BANNER_DASHBOARD_SUPERVIS: "banner_dashboard_supervise",
        /**
         * 代理商
         */
        BANNER_DASHBOARD_AGENT: "banner_dashboard_agent",
        /**
         * 机构
         */
        BANNER_DASHBOARD_ORGAN: "banner_dashboard_organ",
    },

    /**
     * footer底部各个弹窗参数
     */
    indexServerType: {
        training_safe: "training_safe",
        training_continue: "training_continue",
        training_job: "training_job",
        training_preJob: "training_preJob",
        training_enterprise: "training_enterprise",
        about_platform: "about_platform",
        contact_customer: "contact_customer",
    },
    /**
     * 在线情况统计-组织用户
     */
    orgUserStatisticsType: {
        user_count: "userCount",
        add_count: "addCount",
        cut_count: "cutCount",
    },
    /**
     * 在线情况统计-在线人数
     */
    onlineUserStatisticsType: {
        sum_user: "sumUser", // 在线总数
        admin_user: "adminUser", // 管理员
        in_user: "inUser", // 普通员工
        join_user: "joinUser", // 培训学员
    },
    dataTypeList: [
        {
            name: "所有",
            code: "",
        },
        {
            name: "违法违规",
            code: EnumConstants.INTENSIVE_DRIVER_BEHAVIOR_DATA_TYPE.ILLEGAL_VIOLATION,
        },
        {
            name: "违法事故",
            code: EnumConstants.INTENSIVE_DRIVER_BEHAVIOR_DATA_TYPE.ILLEGAL_ACCIDENT,
        },
        {
            name: "违法计分",
            code: EnumConstants.INTENSIVE_DRIVER_BEHAVIOR_DATA_TYPE.DEVICE_ILLEGAL,
        },
        {
            name: "驾驶证异常",
            code: EnumConstants.INTENSIVE_DRIVER_BEHAVIOR_DATA_TYPE.DRIVER_LICENSE_ABNORMAL,
        },
    ],
    /**
     * 以XX结尾的组织名称
     */
    endWithOrgName: [
        "有限公司",
        "经营有限公司",
        "有限责任公司",
        "运输公司",
        "汽车服务有限公司",
        "工程有限公司",
        "咨询有限公司",
        "股份有限公司",
        "服务有限公司",
        "汽车有限公司",
        "运输有限公司公司",
        "建设有限公司",
        "运输有限公司",
        "广告有限公司",
        "销售服务有限公司",
        "花卉有限公司",
        "化妆品有限公司",
        "投资咨询有限公司",
        "投资管理有限公司",
        "文化传播有限公司",
        "网络科技有限公司",
        "软件技术有限公司",
        "电子科技有限公司",
        "渗透技术有限公司",
        "电梯工程有限公司",
        "机电科技有限公司",
        "电力科技有限公司",
        "通讯技术有限公司",
        "传动机械有限公司",
        "建筑设计有限公司",
        "智能科技有限公司",
        "装潢设计有限公司",
        "门业装饰有限公司",
        "展览服务有限公司",
        "快递服务有限公司",
        "婚庆服务有限公司",
        "翻译服务有限公司",
        "船舶技术有限公司",
        "真空涂装有限公司",
        "化工科技有限公司",
        "智能化科技有限公司",
        "企业管理咨询有限公司",
        "市场营销策划有限公司",
        "机电工程技术有限公司",
        "精密设备科技有限公司",
        "建筑工程技术有限公司",
        "建筑工程技术有限公司",
        "建筑装饰工程有限公司",
        "工业产品设计有限公司",
        "软件有限公司",
        "窗饰有限公司",
        "绿化有限公司",
        "商务咨询有限公司",
        "企业管理有限公司",
        "信息科技有限公司",
        "信息技术有限公司",
        "网络工程有限公司",
        "电子技术有限公司",
        "数码科技有限公司",
        "自控技术有限公司",
        "机电设备有限公司",
        "机工密封有限公司",
        "光电科技有限公司",
        "通讯科技有限公司",
        "化学科技有限公司",
        "电脑租赁有限公司",
        "建筑装潢有限公司",
        "建筑安装有限公司",
        "景观设计有限公司",
        "制冷工程有限公司",
        "会务服务有限公司",
        "数码动画有限公司",
        "礼仪服务有限公司",
        "船舶配件有限公司",
        "环保科技有限公司",
        "化工技术有限公司",
        "超声波技术有限公司",
        "计算机科技有限公司",
        "企业形象策划有限公司",
        "信息技术工程有限公司",
        "机电设备工程有限公司",
        "金属结构工程有限公司",
        "装饰设计工程有限公司",
        "贸易有限公司",
        "实业有限公司",
        "服务有限公司",
        "家具有限公司",
        "门业有限公司",
        "管业有限公司",
        "涂料有限公司",
        "玻璃有限公司",
        "鞋业有限公司",
        "箱包有限公司",
        "卫浴有限公司",
        "商贸有限公司",
        "服饰有限公司",
        "阀门有限公司",
        "服饰有限公司",
        "电器有限公司",
        "泵业有限公司",
        "五金有限公司",
        "瓷业有限公司",
        "刀具有限公司",
        "布艺有限公司",
        "化纤有限公司",
        "钢铁有限公司",
        "不锈钢有限公司",
        "润滑油有限公司",
        "再生能源科技有限公司",
        "五金配件有限公司",
        "制冷设备有限公司",
        "厨房设备有限公司",
        "电缆附件有限公司",
        "墙体材料有限公司",
        "办公设备有限公司",
        "自控仪器有限公司",
        "塑料制品有限公司",
        "实业发展有限公司",
        "日用百货有限公司",
        "电子食品有限公司",
        "电子设备有限公司",
        "机电设备有限公司",
        "机电工程有限公司",
        "家居用品有限公司",
        "包装材料有限公司",
        "工艺礼品有限公司",
        "环保设备有限公司",
        "机械材料有限公司",
        "汽车租赁有限公司",
        "汽摩配件有限公司",
        "自动化设备有限公司",
        "管理设备工程有限公司",
        "空调制冷设备有限公司",
        "轻工机械设备有限公司",
    ],
    /**
     * 签名分类
     */
    sign_classify: {
        train_signature: "TRAIN_SIGNATURE", // 培训签名
        safety_production_contract_signature: "SAFETY_PRODUCTION_CONTRACT_SIGNATURE", // 安全责任书
        train_contract_signature: "USER_TRAIN_CONTRACT_SIGNATURE", // 学习合同签名 lzb 2023-07-04
        safe_meeting_signature: "SAFE_MEETING_SIGNOUT_SIGNATURE", // 安全例会 lzb 2023-12-11 P1.0.28
        con_signature: "commitment_letter", // 承诺书 yxl v1.0.32  20240222
    },
    /**
     * 计划统计- 折线
     * */
    planStatisticsCountCode: {
        // 应参
        actualUserCount: "actualUserCount",
        // 实参
        trainUserCount: "trainUserCount",
        // 完成
        finishedUserCount: "finishedUserCount",
    },
    /**
     * 项目code
     * */
    otherProductCode: {
        // 数字交管平台
        prod_safety_risk: "prod_safety_risk",
    },
};

// 默认常量
const StaticConstants = {
    // 默认设备id
    defaultDeviceId: "999999999",
    subjectCodeTrain: "T01T01", // 培训类别-安全培训
};

/**
 * 组织类型
 */
const getOrgTypeList = [
    {
        orgTypeCode: Constants.organizationType.ORGTYPE_HEAD_OFFICE,
        orgTypeName: "总部",
    },
    {
        orgTypeCode: Constants.organizationType.ORGTYPE_AGENT,
        orgTypeName: "代理商",
    },
    {
        orgTypeCode: Constants.organizationType.ORGTYPE_SUPPERVISE,
        orgTypeName: "行业管理",
    },
    {
        orgTypeCode: Constants.organizationType.ORGTYPE_COMPANY,
        orgTypeName: "企业",
    },
    {
        orgTypeCode: Constants.organizationType.ORGTYPE_ORGAN,
        orgTypeName: "机构",
    },
    {
        orgTypeCode: Constants.organizationType.ORGTYPE_AGENCY,
        orgTypeName: "经销商",
    },
];
/**
 * 协议分类
 */
const getAgreementCategory = {
    // 安全责任书
    safety_production_contract: "safety_production_contract",
    // 承诺书
    commitment_letter: "commitment_letter",
    // 通用协议
    general_protocol: "general_protocol",
};

// 权限-前缀 20231225 lzb
export const prefixMissionList = ["safe_train-", "pre_work-", "special_train-", "target_train-", "safe_regular-", "two_personnel-"];
export { Constants, StaticConstants, getOrgTypeList, getAgreementCategory };
