import { HudGlobal } from "@/utils/HudGlobal";
import DateUtil from "../../../utils/DateUtil";
import TBUserManger from "@/utils/user/TBUserManger";
import Uploader from "@tibi/tb-upload-plugin";
import EnumConstants from "@/utils/constants/EnumConstants";
import http from "@/utils/http.js";
import StoreTempDB from "@/utils/db/StoreTempDB";
import { Constants } from "@/utils/constants/Constants";
import { RequestUploadParam } from "../../../utils/constants/RequestCodeConstants";

// 基础的URL
const BASE_SERVE_API_URL = process.env.VUE_APP_API_SERVICE_URL;
const BASE_API_URL = process.env.VUE_APP_API_BASE_URL;
// 基础路径
const BASE_TOKEN_PATH = "/api/oss/token";
// 替比云上传
const UPLOAD_API_TB_URL = "/api/oss/upload/common";

//= ====================================可配置上传文件夹路径================================//
// 系统管理站点模板上传 /site/template/yyyyMMdd/srcName
const SYSTEM_SITE_PIC = "/site/template/";

// 验证照文件路径 /user/reg/orgid/userid/yyyyMMddhhmmss
const REGISTER_PHOTO = "/user/reg/";

// 组织站点文件路径 /site/orgid/yyyyMMdd/srcName /image/orginSizePhoto
const ORGINSIZE_PHOTO = "/site/";

// 财务文件路径 /finance/orgid/yyyyMMdd/srcName
const FINANCE_MANGER = "/finance/";

// 财务付费模式 /finance/payMode/orgid/yyyyMMdd/srcName
const FINANCE_MANGER_PAY_MODE = "/finance/payMode/";

// 投诉建议文件路径 /suggest/yyyyMMdd/srcName
const SUGGESTION_PHOTO = "/suggest/";

// banner路径 /banner/yyyyMMdd/srcName
const BANNER_PHOTO = "/banner/";

// 试题管理 /exam/img/orgid/yyyyMMddhhmmss /exam/video/orgid/yyyyMMddhhmmss
const EXAMQUESTION_PHOTO = "/exam/img/";

// 课件封面路径 /course/orgId/courseid/srcName
const COURSE_COVER_PHOTO = "/course/";

// 课件文件路径 /course/orgId/courseid/srcName
const COURSE_FILE_PATH = "/course/";

// 公文文件路径 /doc/orgid/yyyyMMdd/srcName
const DOCUMENT_FILE_PATH = "/doc/";
// 协议文件路径
const AGREEMENT_FILE_PATH = "/agreement/";

// 导入通用文件
const IMPORT_COMMON_FILE = "/temp/import/common";
// 导入员工、学员
const IMPORT_STUDENT_FILE = "/temp/import/student";
// 导入课件
const IMPORT_COURSE_FILE = "/temp/import/course";
// 导入数据转换文件
const IMPORT_DATA_CONVERSION_FILE = "/temp/import/data/conversion";
// 导入车辆文件
const IMPORT_VEHICLE_FILE = "/temp/import/vehicle";
// 导入资源文件
const IMPORT_RESOURCE_FILE = "/temp/import/resource";
// 导入权限文件
const IMPORT_AUTH_FILE = "/temp/import/auth";

// 导入驾驶行为明细
const IMPORT_DRIVE_BEHAVIOR_FILE = "/temp/import/driveBehavior";

// 我的组织企业认证资料文件路径 /doc/orgid/yyyyMMdd/srcName
const ORGAUTHMENAUS_FILE_PATH = "/org/auth/means";

//  驾驶行为明细
const BASE_DATA_DRIVING_BEHAVIOR = "/driving/behavior";

// 证书打印模版 20230203 lzb
const CERT_PRINT_TEMPLATE = "/cert/print/template";
// 证书样式 20230206 lzb
const CERT_PRINT_STYLE = "/cert/print/style";
// 证书打印模版-参数图片 20230203 lzb
const CERT_PRINT_TEMPLATE_PARAM = "/cert/print/template/param";
// 富文本文件上传
const RICH_FILE_UPLOAD = "/rich/file/";

// 设备档案-一日三检-企业整改 20231010 lzb
const DEVICE_ARCHIVE_CHECK_MOD_ENTRY = "/device/archive/check/mod";
// 设备档案-车辆例检-企业整改 20231010 lzb
const DEVICE_CAR_INSPECTION_MOD_ENTRY = "/device/archive/inspection/mod";

// 安全例会-文件 P1.0.28 lzb 20231115
const SAFE_MEETING_ADD_MEETING_FILE = "/safe/meeting/add/files";
// 安全例会-图片 P1.0.28 lzb 20231115
const SAFE_MEETING_ADD_MEETING_IMAGE = "/safe/meeting/add/images";

// 企业管理-图片 P1.0.36 rqm 20240424
const ORGANIZE_MEETING_IMAGE = "/organize/images";

//= ====================================================================================//

const uploadOptions = {
    expire: 7, // 华为云
};

export default {
    /*
     *
     *  企业管理-上传图片
     * */
    uploadOrganizeImages(file, orgId) {
        orgId = orgId || TBUserManger.getCurrentUserOrgId();
        const dateFtt = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newDir = `${ORGANIZE_MEETING_IMAGE + orgId + dateFtt}/`;
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, newDir);
    },

    /**
     * 富文本文件上传
     */
    uploadRichFile(file) {
        const dateFtt = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newDir = `${RICH_FILE_UPLOAD + dateFtt}/`;
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, newDir);
    },
    /**
     * 设备档案-一日三检-企业整改凭证-20231010 P1.0.29 lzb
     * */
    uploadDeviceArchiveCheckItemMod(file, orgId = TBUserManger.getCurrentUserOrgId()) {
        orgId = orgId || TBUserManger.getCurrentUserOrgId();
        const dateFtt = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newDir = `${DEVICE_ARCHIVE_CHECK_MOD_ENTRY + orgId + dateFtt}/`;
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, newDir);
    },
    /**
     * 车辆例检-企业整改凭证-20240401 P1.0.29 lzb
     * */
    uploadDeviceArchiveCarInspectionMod(file, orgId = TBUserManger.getCurrentUserOrgId()) {
        orgId = orgId || TBUserManger.getCurrentUserOrgId();
        const dateFtt = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newDir = `${DEVICE_CAR_INSPECTION_MOD_ENTRY + orgId + dateFtt}/`;
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, newDir);
    },

    /**
     * 站点模板管理上传 ok
     */
    uploadSysSitePic(file) {
        const dateFtt = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newDir = `${SYSTEM_SITE_PIC + dateFtt}/`;
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, newDir);
    },
    /**
     * 数据管理-驾驶行为明细的照片凭证 -上传图片  ok
     * */
    uploadBaseDataDriving(file, orgId = TBUserManger.getCurrentUserOrgId()) {
        orgId = orgId || TBUserManger.getCurrentUserOrgId();
        const dateFtt = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newDir = `${BASE_DATA_DRIVING_BEHAVIOR + orgId + dateFtt}/`;
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, newDir);
    },
    /**
     * 安全例会-上传文件
     * */
    uploadSafeMeetingFile(file, orgId = TBUserManger.getCurrentUserOrgId()) {
        orgId = orgId || TBUserManger.getCurrentUserOrgId();
        const dateFtt = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newDir = `${SAFE_MEETING_ADD_MEETING_FILE + orgId + dateFtt}/`;
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, newDir);
    },
    /**
     * 安全例会-上传图片
     * */
    uploadSafeMeetingPhoto(file, orgId = TBUserManger.getCurrentUserOrgId()) {
        orgId = orgId || TBUserManger.getCurrentUserOrgId();
        const dateFtt = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newDir = `${SAFE_MEETING_ADD_MEETING_IMAGE + orgId + dateFtt}/`;
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, newDir);
    },

    /*
     *
     *  员工注册照-上传图片 - ok
     * */
    uploadRegisterPhoto(file, storeDir) {
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);

        storeDir = REGISTER_PHOTO + storeDir;
        return this.baseUploadPhoto(newFile, storeDir);
    },

    /*
     *
     *  组织站点-上传图片 /site/orgid/yyyyMMdd/srcName
     * */
    uploadOrganSizePhoto(file, orgId = TBUserManger.getCurrentUserOrgId()) {
        orgId = orgId || TBUserManger.getCurrentUserOrgId();
        const dir = `${ORGINSIZE_PHOTO + orgId}/${DateUtil.dateFtt(DateUtil.format.YYYY_MM_DD)}`;
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, dir);
    },

    /*
     *
     *  财务 支付渠道 -上传图片
     * */
    uploadFinancePayChannePhoto(file, orgId = TBUserManger.getCurrentUserOrgId()) {
        orgId = orgId || TBUserManger.getCurrentUserOrgId();
        const dir = `${FINANCE_MANGER + orgId}/${DateUtil.dateFtt(DateUtil.format.YYYY_MM_DD_HH_MM_SS)}`;
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, dir);
    },

    /*
     *
     *  投诉建议 -上传图片 /suggest/yyyyMMdd/srcName ok
     * */
    uploadSuggestionPhoto(file) {
        const dir = SUGGESTION_PHOTO + DateUtil.dateFtt(DateUtil.format.YYYY_MM_DD_HH_MM_SS);
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, dir);
    },

    /**
     *  banner管理 - 上传banner图片 ok
     */
    uploadBannerPhoto(file) {
        const dir = BANNER_PHOTO + DateUtil.dateFtt(DateUtil.format.YYYY_MM_DD_HH_MM_SS);
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, dir);
    },

    /*
     *
     *  试题管理-上传图片
     *  /exam/img/orgid/yyyyMMddhhmmss /exam/video/orgid/yyyyMMddhhmmss
     */
    uploadExamQuestionPhoto(file, orgId = TBUserManger.getCurrentUserOrgId()) {
        orgId = orgId || TBUserManger.getCurrentUserOrgId();
        const newDir = `${EXAMQUESTION_PHOTO + orgId}/${DateUtil.dateFtt(DateUtil.format.YYYY_MM_DD_HH_MM_SS)}`;
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, newDir);
    },

    /*
     *
     *  课件管理 - 新增课件封面 /course/orgId/courseid/srcName
     * */
    uploadCourseCoverPhoto(file, orgId = TBUserManger.getCurrentUserOrgId(), courseId) {
        orgId = orgId || TBUserManger.getCurrentUserOrgId();
        let newDir = `${COURSE_COVER_PHOTO + orgId}/`;
        if (courseId) {
            newDir += `${courseId}/`;
        }
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, newDir);
    },

    /*
     *  课件管理 - 新增课件文件
     * */
    uploadCourseCoverPhotoFile(file, orgId = TBUserManger.getCurrentUserOrgId(), courseId) {
        orgId = orgId || TBUserManger.getCurrentUserOrgId();
        let newDir = `${COURSE_FILE_PATH + orgId}/`;
        if (courseId) {
            newDir += `${courseId}/`;
        }
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, newDir);
    },

    /*
     *  导入通用文件组织、试题
     * */
    importUniversalFile(file, fileName, originId = TBUserManger.getCurrentUserOrgId()) {
        // 路径拼接
        let filePath = IMPORT_COMMON_FILE;
        if (originId != null && originId.length != 0) {
            filePath = `${filePath}/${originId}`;
        }

        // 日期
        filePath = `${filePath}/${DateUtil.getCurrentDatePathStr()}`;

        // 文件名称
        if (!file.name || file.name.length == 0) {
            if (fileName != null && fileName.length !== 0) {
                filePath = `${filePath}/${fileName}`;
            } else {
                const userTimeName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
                file = this.newFile(file, userTimeName);
            }
        }
        return this.baseUploadPhoto(file, filePath, uploadOptions);
    },

    /**
     * 课件导入文件
     */
    importCourseFile(file, fileName, originId = TBUserManger.getCurrentUserOrgId()) {
        // 路径拼接
        let filePath = IMPORT_COURSE_FILE;
        if (originId != null && originId.length != 0) {
            filePath = `${filePath}/${originId}`;
        }

        // 日期
        filePath = `${filePath}/${DateUtil.getCurrentDatePathStr()}`;

        // 文件名称
        if (!file.name || file.name.length == 0) {
            if (fileName != null && fileName.length !== 0) {
                filePath = `${filePath}/${fileName}`;
            } else {
                const userTimeName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
                file = this.newFile(file, userTimeName);
            }
        }
        return this.baseUploadPhoto(file, filePath, uploadOptions);
    },

    /**
     *  员工导入文件
     * */
    importStudentFile(file, fileName, originId = TBUserManger.getCurrentUserOrgId()) {
        // 路径拼接
        let filePath = IMPORT_STUDENT_FILE;
        if (originId != null && originId.length != 0) {
            filePath = `${filePath}/${originId}`;
        }

        // 日期
        filePath = `${filePath}/${DateUtil.getCurrentDatePathStr()}`;

        // 文件名称
        if (!file.name || file.name.length == 0) {
            if (fileName != null && fileName.length !== 0) {
                filePath = `${filePath}/${fileName}`;
            } else {
                const userTimeName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
                file = this.newFile(file, userTimeName);
            }
        }
        return this.baseUploadPhoto(file, filePath, uploadOptions);
    },
    /**
     *  数据转换导入文件
     * */
    importDataConversionFile(file, fileName, originId = TBUserManger.getCurrentUserOrgId()) {
        // 路径拼接
        let filePath = IMPORT_DATA_CONVERSION_FILE;
        if (originId != null && originId.length != 0) {
            filePath = `${filePath}/${originId}`;
        }

        // 日期
        filePath = `${filePath}/${DateUtil.getCurrentDatePathStr()}`;

        // 文件名称
        if (!file.name || file.name.length == 0) {
            if (fileName != null && fileName.length !== 0) {
                filePath = `${filePath}/${fileName}`;
            } else {
                const userTimeName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
                file = this.newFile(file, userTimeName);
            }
        }
        return this.baseUploadPhoto(file, filePath, uploadOptions);
    },
    /*
     *  车辆导入
     * */
    importVehicleFile(file, fileName, originId = TBUserManger.getCurrentUserOrgId()) {
        // 路径拼接
        let filePath = IMPORT_VEHICLE_FILE;
        if (originId != null && originId.length !== 0) {
            filePath = `${filePath}/${originId}`;
        }

        // 日期
        filePath = `${filePath}/${DateUtil.getCurrentDatePathStr()}`;

        // 文件名称
        if (!file.name || file.name.length == 0) {
            if (fileName != null && fileName.length !== 0) {
                filePath = `${filePath}/${fileName}`;
            } else {
                const userTimeName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
                file = this.newFile(file, userTimeName);
            }
        }
        return this.baseUploadPhoto(file, filePath, uploadOptions);
    },
    /*
     *  驾驶行为明细导入2022-11-04 lzb
     * */
    importDriveBehaviorFile(file, fileName, originId = TBUserManger.getCurrentUserOrgId()) {
        // 路径拼接
        let filePath = IMPORT_DRIVE_BEHAVIOR_FILE;
        if (originId != null && originId.length !== 0) {
            filePath = `${filePath}/${originId}`;
        }

        // 日期
        filePath = `${filePath}/${DateUtil.getCurrentDatePathStr()}`;

        // 文件名称
        if (!file.name || file.name.length == 0) {
            if (fileName != null && fileName.length !== 0) {
                filePath = `${filePath}/${fileName}`;
            } else {
                const userTimeName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
                file = this.newFile(file, userTimeName);
            }
        }
        return this.baseUploadPhoto(file, filePath, uploadOptions);
    },
    /**
     * @description:资源导入 20221223 lzb
     * @param {*} file
     * @param {*} fileName
     * @param {*} originId
     * @return {*}
     */
    importResourceFile(file, fileName, originId = TBUserManger.getCurrentUserOrgId()) {
        // 路径拼接
        let filePath = IMPORT_RESOURCE_FILE;
        if (originId != null && originId.length !== 0) {
            filePath = `${filePath}/${originId}`;
        }

        // 日期
        filePath = `${filePath}/${DateUtil.getCurrentDatePathStr()}`;

        // 文件名称
        if (!file.name || file.name.length == 0) {
            if (fileName != null && fileName.length !== 0) {
                filePath = `${filePath}/${fileName}`;
            } else {
                const userTimeName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
                file = this.newFile(file, userTimeName);
            }
        }
        return this.baseUploadPhoto(file, filePath, uploadOptions);
    },
    /**
     * @description:权限导入 20221223 lzb
     * @param {*} file
     * @param {*} fileName
     * @param {*} originId
     * @return {*}
     */
    importAuthFile(file, fileName, originId = TBUserManger.getCurrentUserOrgId()) {
        // 路径拼接
        let filePath = IMPORT_AUTH_FILE;
        if (originId != null && originId.length !== 0) {
            filePath = `${filePath}/${originId}`;
        }

        // 日期
        filePath = `${filePath}/${DateUtil.getCurrentDatePathStr()}`;

        // 文件名称
        if (!file.name || file.name.length == 0) {
            if (fileName != null && fileName.length !== 0) {
                filePath = `${filePath}/${fileName}`;
            } else {
                const userTimeName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
                file = this.newFile(file, userTimeName);
            }
        }
        return this.baseUploadPhoto(file, filePath, uploadOptions);
    },

    /**
     * 公文上传文件
     */
    uploadDocumentFile(file, orgId = TBUserManger.getCurrentUserOrgId()) {
        orgId = orgId || TBUserManger.getCurrentUserOrgId();
        const dir = `${DOCUMENT_FILE_PATH + orgId}/${DateUtil.dateFtt(DateUtil.format.YYYY_MM_DD_HH_MM_SS)}`;
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, dir);
    },
    /**
     * 协议上传文件
     */
    uploadAgreementFile(file, orgId = TBUserManger.getCurrentUserOrgId()) {
        orgId = orgId || TBUserManger.getCurrentUserOrgId();
        const dir = `${AGREEMENT_FILE_PATH + orgId}/${DateUtil.dateFtt(DateUtil.format.YYYY_MM_DD_HH_MM_SS)}`;
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, dir);
    },

    /**
     * 我的组织 - 企业认证 - 资料上传文件
     */
    uploadOrgAuthInfoFile(file, orgId) {
        orgId = orgId || TBUserManger.getCurrentUserOrgId();
        const dir = `${ORGAUTHMENAUS_FILE_PATH + orgId}/${DateUtil.dateFtt(DateUtil.format.YYYY_MM_DD_HH_MM_SS)}`;
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, dir);
    },

    /**
     * 企业付费-财务管理-付费模式设置 -上传合同
     */
    uploadContractTemplateFile(file, orgId = TBUserManger.getCurrentUserOrgId()) {
        orgId = orgId || TBUserManger.getCurrentUserOrgId();
        const newDir = `${FINANCE_MANGER_PAY_MODE + orgId}/${DateUtil.dateFtt(DateUtil.format.YYYY_MM_DD_HH_MM_SS)}`;
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, newDir);
    },

    /**
     * @description: lzb 20230202 证书模版
     * @param {*} file
     * @return {*}
     */
    uploadCertTemplate(file, orgId = TBUserManger.getCurrentUserOrgId()) {
        orgId = orgId || TBUserManger.getCurrentUserOrgId();
        const newDir = `${CERT_PRINT_TEMPLATE + orgId}/${DateUtil.dateFtt(DateUtil.format.YYYY_MM_DD_HH_MM_SS)}`;
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, newDir);
    },
    /**
     * @description: lzb 20230202 证书模版
     * @param {*} file
     * @return {*}
     */
    uploadCertStyle(file, orgId = TBUserManger.getCurrentUserOrgId()) {
        orgId = orgId || TBUserManger.getCurrentUserOrgId();
        const newDir = `${CERT_PRINT_STYLE + orgId}/${DateUtil.dateFtt(DateUtil.format.YYYY_MM_DD_HH_MM_SS)}`;
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, newDir);
    },

    /**
     * @description: lzb 20230202 证书模版-参数图片
     * @param {*} file
     * @return {*}
     */
    uploadCertTemplateParamPhoto(file, orgId = TBUserManger.getCurrentUserOrgId()) {
        orgId = orgId || TBUserManger.getCurrentUserOrgId();
        const newDir = `${CERT_PRINT_TEMPLATE_PARAM + orgId}/${DateUtil.dateFtt(DateUtil.format.YYYY_MM_DD_HH_MM_SS)}`;
        const userFaceFileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());
        const newFile = this.newFile(file, userFaceFileName);
        return this.baseUploadPhoto(newFile, newDir);
    },

    /**
     * 重组文件名
     * @param {} file
     * @param {*} fileName
     * @returns
     */
    newFile(file, fileName) {
        const name = file.name;
        // const nameArr = name.split(".");
        // if (nameArr && nameArr.length > 0) {
        //     fileName = `${fileName}.${nameArr[nameArr.length - 1]}`;
        // }
        fileName = name;

        const fileOpts = {};
        fileOpts.uid = file.uid;
        fileOpts.type = file.type;
        const newFile = new File([file], fileName, fileOpts);
        return newFile;
    },

    /**
     * 基础上传
     * @param {*} file
     * @param {*} path
     * @returns
     */
    baseUploadPhoto(file, path, uploadOpts = {}) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            const opts = {
                hwConfig: {},
                aliConfig: {},
                tbConfig: {},
                expire: 0,
            };
            opts.tokenRequestUrl = BASE_SERVE_API_URL + BASE_TOKEN_PATH;
            opts.platform = (await this.getUploadPlatformConfig(TBUserManger.getCurrentUserOrgId())) || window.$TBUpload.uploadPlatform;
            opts.videoAVCRequestUrl = `${BASE_SERVE_API_URL}/api/video/toH264`;

            // 阿里云配置
            opts.aliConfig.region = process.env.VUE_APP_ALIOSS_REGION;
            opts.aliConfig.bucket = process.env.VUE_APP_ALIOSS_BUCKET;

            // 替比云配置
            opts.tbConfig.platform = window.$TBUpload.uploadSubPlatform;
            opts.tbConfig.region = process.env.VUE_APP_ALIOSS_REGION;
            opts.tbConfig.bucket =
                opts.tbConfig.platform === EnumConstants.UPLOAD_PLATFORM.ALI ? process.env.VUE_APP_ALIOSS_BUCKET : process.env.VUE_APP_HUAWEI_BUCKET;
            opts.tbConfig.uploadURL = `${BASE_SERVE_API_URL}${UPLOAD_API_TB_URL}`;

            // 华为配置
            opts.hwConfig.bucket = process.env.VUE_APP_HUAWEI_BUCKET;

            // 上传配置
            if (uploadOpts.expire > 0 && StoreTempDB.getUploadFileIOExpire()) {
                opts.expire = Number(StoreTempDB.getUploadFileIOExpire());
                uploadOpts.expire = opts.expire;
            } else {
                uploadOpts = {};
            }

            const uploader = new Uploader(opts);
            uploader
                .uploaderFile(file, path, uploadOpts)
                .then((res) => {
                    console.log("上传结果====", res);
                    if (res.success) {
                        resolve && resolve(res);
                    } else {
                        console.log("上传失败======", res.description);
                        HudGlobal.showErrorWithMessage(res.description || "上传失败");
                        resolve && resolve(res);
                    }
                })
                .catch((error) => {
                    console.log("上传失败======", error);
                    HudGlobal.showErrorWithMessage("上传失败");
                    resolve && resolve(null);
                });
        });
    },
    /**
     * @description: 获取上传token
     * @return {*}
     */

    getUploadToken(ossType) {
        const url = BASE_SERVE_API_URL + BASE_TOKEN_PATH;
        http.get(url, { ossType }).then((res) => {
            if (res && res.success) {
                const { data } = res;
                StoreTempDB.saveUploadConfig(data);
            }
        });
    },
    /**
     *  获取平台配置
     * @returns
     */
    getUploadPlatformConfig(orgId) {
        orgId = orgId || Constants.tibiOrg.orgId;
        return new Promise((resolve, reject) => {
            const param = {
                parameterCodeList: [RequestUploadParam.upload_oss_type, RequestUploadParam.upload_file_io_expire],
                orgId,
            };
            let url = "/system/parameter/org/list";
            if (!TBUserManger.isLogin()) {
                url = "/api/system/parameter/org/list";
            }
            http.get(BASE_API_URL + url, param)
                .then((res) => {
                    if (res && res.success) {
                        const upload_oss_type = res.data[RequestUploadParam.upload_oss_type];
                        const upload_file_io_expire = res.data[RequestUploadParam.upload_file_io_expire];
                        StoreTempDB.saveUploadPlatform(upload_oss_type);
                        StoreTempDB.saveUploadFileIOExpire(upload_file_io_expire);
                        resolve && resolve(upload_oss_type);
                    } else {
                        resolve && resolve(null);
                    }
                })
                .catch((err) => {
                    resolve && resolve(null);
                });
        });
    },
};
