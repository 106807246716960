import http from "@/utils/http";

const serverUrl = process.env.VUE_APP_API_TEMPLATE_SERVER_URL;
// const serverUrl = "http://192.168.18.119:8006";
export default {
    /**
     * 分页查询打印导出日志
     * @param query
     * @returns {*}
     */
    findPrintLogList(query) {
        return http.get("/system/printExport/page", query);
    },
    /**
     *单条查询打印导出日志详情
     * @param params
     * @returns {*}
     */
    getPrintLogOne(params) {
        return http.get("/system/printExport/one", params);
    },
    /**
     *新增打印导出日志
     * @param params
     * @returns {*}
     */
    addPrintLog(params) {
        return http.post("/system/printExport/add", params);
    },

    // exportStudentInfo(params) {
    //     return http.post(`/student/export/info`, params);
    //     // return http.post(`http://localhost:8006/export/student/info`, params);
    //     // return http.post(`http://192.168.50.250:8006/export/student/info`, params);
    // },
    /**
     *  导出学员信息 20240418
     */
    exportOneStudentInfo(params) {
        return http.post(`/student/export/info`, params);
    },
    /**
     *  导出学员详情
     */
    exportStudentDetailExport(params) {
        return http.post(`${serverUrl}/api/export/student/detail/report`, params);
    },
    /**
     *  导出学员计划详情(导出两类人员进度详情) lzb 20240418
     */
    exportStudentPlanDetailExport(params) {
        return http.post(`/export/towTypePerson/progress/detail`, params);
    },

    /**
     *  导出学员计划流水详情（导出两类人员流水详情）
     */
    exportStudentFlowPlanDetailExport(params) {
        return http.post(`/export/towTypePerson/flow/detail`, params);
    },
    /**
     *  导出学员计划详情
     */
    exportStudentPlanDetailOldExport(params) {
        return http.post(`${serverUrl}/export/student/plan/detail`, params);
    },
    /**
     *  导出学员计划流水详情
     */
    exportStudentFlowPlanDetailOldExport(params) {
        return http.post(`${serverUrl}/export/student/planFlow/detail`, params);
    },

    /**
     *  导出计划详情(监管计划详情)
     */
    exportPlanDetailExport(params) {
        return http.get(`/export/twoTypePerson/plan/detail`, params);
    },

    /**
     *  导出计划详情(监管计划详情)
     */
    exportPlanDetailOldExport(params) {
        return http.post(`${serverUrl}/export/plan/detail`, params);
    },
    /**
     *  导出会议详情 lzb 20231213 P1.0.28
     */
    exportMeetingDetailExport(params) {
        return http.post(`${serverUrl}/export/meeting/detail`, params);
    },
    /**
     *  导出考试详情
     */
    exportExamDetailExport(params) {
        return http.post(`${serverUrl}/export/exam/detail`, params);
    },
    /**
     * 获取打印列表属性v2版本
     */
    getPrintFields(params) {
        return http.get("/statistics/print/field/v2", params);
    },
    /**
     * v1.0.28 - 个人会议详情的导出
     */
    exportPersonalMeetingDetail(params) {
        return http.post(`${serverUrl}/export/personal/meeting/detail`, params);
    },
    /**
     * 个人会议记录详情的导出
     */
    exportPersonalMeetingRecordDetail(params) {
        return http.post(`${serverUrl}/export/personal/meeting/record/detail`, params);
    },
};
