const toString = Object.prototype.toString;
/**
 * 是否是String
 */
export const isString = (obj) => {
    return typeof obj === "string" || baseTypeTester("String")();
};
/**
 * 是否是object
 */
export const isObject = (obj) => {
    return typeof obj === "object" && !!obj;
};
/**
 * 是否是空对象 object
 */
export const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
};

/**
 * 是否是undefined
 */
export const isUndefined = (obj) => {
    return typeof obj === "undefined";
};
/**
 * 检查是否为 null
 */
export const isNull = (value) => {
    return value === null;
};

/**
 * 检查是否为数组
 */
export const isArray = (value) => {
    return Array.isArray(value);
};
/**
 *  检查是否为函数
 */
export const isFunction = (value) => {
    return typeof value === "function";
};

/**
 * 基础类型检测
 */
export const baseTypeTester = (name) => {
    const tag = `[object ${name}]`;
    return function (obj) {
        return toString.call(obj) === tag;
    };
};
/**
 * 是否是base64 字符串
 */
export const isBase64Str = (str) => {
    if (!isString(str)) return false;
    if (str === "" || str.trim() === "") {
        return false;
    }
    try {
        return btoa(atob(str)) == str;
    } catch (err) {
        return false;
    }
};
