import http from "@/utils/http";
import store from "../../../store";

// 基础的URL
export default {
    getInit(query) {
        const { orgId } = store.getters;
        const queryData = { orgId, ...query };
        return http.get("/system/parameter/code/list", queryData);
    },
    /*
     * 获取参数列表
     * */
    getParameterList(query) {
        return http.get("/system/parameter/code/list", query);
    },
    /*
     * 获取参数列表
     * */
    getOrgParameterList(query) {
        const { orgId } = store.getters;
        const queryData = { orgId, ...query };
        return http.get("/system/parameter/org/list", queryData);
    },
    /**
     * 不需要验证获取系统参数
     * @param {*} query
     */
    getNoAuthorityParameterList(query) {
        return http.get("/api/system/parameter/code/list", query, { isEncrypt: true });
    },
    /**
     * 不需要验证获取系统参数(可以传多个参数)lzb 2021-07-21
     * @param {*} query
     */
    getNoAuthorityOrgParameterList(query) {
        return http.get("/api/system/parameter/org/list", query, { isEncrypt: true });
    },

    getIndustry(query) {
        return http.get("/system/industry/typecode/list", query);
    },
    // 获取参数列表
    getList(param) {
        return http.get("/system/parameter/page", param);
    },
    // 获取参数树数据
    getTree(query) {
        return http.get("/system/parameter/treelist", query);
    },
    /**
     * 获取选择参数的详情
     * @param query
     * @returns {*}
     */
    findSelectParamOneDetail(query) {
        return http.get("/system/parameter/intersectionone", query);
    },
    // 获取目录列表
    getDirList(query) {
        return http.get("/system/parameter/dirlist", query);
    },
    // 单条查询参数
    getOne(query) {
        return http.get("/system/parameter/one", query);
    },
    // 修改参数状态
    changeState(params) {
        return http.put("/system/parameter/state/mod", params);
    },
    //
    add(params) {
        return http.post("/system/parameter/add", params);
    },
    // 删除参数、参数目录
    del(params) {
        return http.delete("/system/parameter/del", params);
    },
    // 修改参数
    update(params) {
        return http.put("/system/parameter/mod", params);
    },

    // 参数 树状结构 (上级参数)
    paramTree(params) {
        return http.get("/paramTree");
    },
    // 修改参数目录
    updateDir(params) {
        return http.put("/system/parameter/directory/mod", params);
    },
    // 保存参数目录
    addPramaDir(params) {
        return http.post("/system/parameter/directory/add", params);
    },
    // 根据培训类别查岗位
    getDutyList(params) {
        return http.post("/system/industry/station/treeListBySubject", params);
    },
    /**
     * 系统参数同步 lzb 20240710
     */
    postSystemParametersSync(params) {
        return http.post("/system/parameter/syncOtherEnv", params);
    },
};
