import http from "@/utils/http.js";
const url = process.env.VUE_APP_API_BASE_URL;

export default {
    /*
     * 获取站点管理list
     * */
    pageByCondition(params) {
        return http.get("/org/site/sys/template/page", params);
    },
    /*
     * 模板详情
     * */
    getOne(params) {
        return http.get("/org/site/one", params);
    },
    /*
     * 添加新增模板
     * */
    add(params) {
        return http.post("/org/site/add", params);
    },
    /*
     * 删除模板
     * */
    del(id) {
        return http.delete(`/template/delete/${id}`);
    },
    /*
     * 更新模板
     * */
    update(params) {
        return http.put("/org/site/mod", params);
    },
    // 根据URL获取企业站点信息
    getSiteByUrl(params) {
        return http.get(url + "/api/org/site/one", params, { isEncrypt: true });
    },
};
