// 指令是否有对应的权限
import Vue from "vue";
import StoreUserDB from "@utils/db/StoreUserDB";
import { prefixMissionList } from "../../utils/constants/Constants";

/**
 * 是否有某一个角色
 */
Vue.directive("has-role", {
    inserted(el, binding) {
        const arg = binding.value;
        const roleList = StoreUserDB.getUserRoleList();
        handlePermission(roleList, arg, el);
    },
});

/**
 * 是否具有某个权限
 */
Vue.directive("has-permission", {
    inserted(el, binding) {
        const arg = binding.value;
        const permissionList = StoreUserDB.getUserPermissionList();
        handlePermission(permissionList, arg, el);
    },
    componentUpdated(el, binding) {
        const updatedValue = binding.value;
        // 判断值是否发生变化
        if (el._activatedValue !== updatedValue) {
            const permissionList = StoreUserDB.getUserPermissionList();
            handlePermission(permissionList, updatedValue, el);
            // 更新保存的值为最新值
            el._activatedValue = updatedValue;
        }
    },
});

/**
 * 指令是否有对应的身份
 */
Vue.directive("has-identify", {
    inserted(el, binding) {
        const arg = binding.value;
        const dataList = StoreUserDB.getUserIdentifyList();
        handlePermission(dataList, arg, el);
    },
});

// 通用权限判断
function handlePermission(dataList, auth, el) {
    let has = false;
    const isStartPrefix = prefixMissionList.some((prefix) => auth.startsWith(prefix));
    if (!dataList) {
        return has;
    }
    for (let i = 0; i < dataList.length; i++) {
        const data = dataList[i];
        if (auth instanceof Array) {
            for (let j = 0; j < auth.length; j++) {
                if (data === auth[j]) {
                    has = true;
                    break;
                }
            }
        } else if (data === auth) {
            has = true;
            break;
        }
    }

    if (!has) {
        try {
            isStartPrefix ? (el.style.display = "none") : el.remove();
        } catch (e) {
            el.parentNode.removeChild(el);
        }
    } else {
        isStartPrefix ? (el.style.display = "inline-block") : "";
    }
}
