var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: "guide" } }, [
    _vm.options.isVisible
      ? _c("div", { staticClass: "window-guide-dialog-mask" }, [
          _c(
            "div",
            { staticClass: "guide-container-wrap" },
            [
              _c("el-image", {
                staticClass: "image-el",
                attrs: {
                  src: _vm.formatterResponseUrl(_vm.currentItem.imageUrl),
                },
              }),
              _c(
                "div",
                { staticClass: "button-group-wrap" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "normal-button button-close",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.onClickCloseEvent.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("关闭")]
                  ),
                  _vm.currentIndex != 0
                    ? _c(
                        "el-button",
                        {
                          staticClass: "normal-button button-pre",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.onClickPreEvent.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("上一步")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      staticClass: "normal-button button-next",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.onClickNextEvent.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.currentIndex == _vm.imgList.length - 1
                            ? "完成"
                            : "下一步"
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }