/**
 * lzb 2021-08-18 新增加载失败
 */
export default {
    name: "LoadFail",
    props: {
        /**
         * 标题
         */
        title: {
            type: String,
            default: "加载失败，请刷新重试",
        },
        /**
         * 加载失败图片
         */
        loadFailImage: {
            type: String,
        },
        /**
         *  是否有操作按钮
         * */
        isHasOperation: {
            type: Boolean,
            default: true,
        },
        /**
         *  操作按钮文字
         * */
        operationTitle: {
            type: String,
            default: "刷新",
        },
    },
    computed: {
        /**
         * 获取没有数据图片
         */
        getLoadFailImage() {
            if (this.loadFailImage == null || this.loadFailImage.length == 0) {
                return require("../../../assets/img/error/error_load_fail.png");
            } else {
                return this.loadFailImage;
            }
        },
    },
    methods: {
        /**
         * 点击没有数据按钮事件
         * */
        onClickButtonEvent() {
            if (!this.isHasOperation) {
                return;
            }
            this.$emit("call-back");
        },
    },
};
