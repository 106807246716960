import Vue from "vue";

/**
 *  显示弹框
 * */
export const showWindowGuide = (options, callBack) => {
    if (Vue.prototype.$windownGuideDialog) {
        Vue.prototype.$windownGuideDialog.showWindownGuideAlert(options, callBack);
    }
};
/**
 *  显示弹框
 * */
export const showCustomAlert = (options, callBack) => {
    if (Vue.prototype.$windowDialog) {
        Vue.prototype.$windowDialog.showWindowAlert(options, callBack);
    }
};
