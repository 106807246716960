export default {
    /**
     *  数字保留几位小数
     *  numberRound  数据源
     *  roundDigit 保留几位
     */
    roundFunc(numberRound, roundDigit) {
        if (numberRound >= 0) {
            const tempNumber = parseInt(numberRound * Math.pow(10, roundDigit) + 0.5) / Math.pow(10, roundDigit);
            return tempNumber;
        } else {
            const numberRound1 = -numberRound;
            const tempNumber = parseInt(numberRound1 * Math.pow(10, roundDigit) + 0.5) / Math.pow(10, roundDigit);
            return -tempNumber;
        }
    },
    /**
     * 保留小数位
     * @param {数据} value
     * @param {保留的位数} number
     */
    toFixed(value, number) {
        return Math.floor(value * 100) / 100;
        // return Math.round(value * Math.pow(10, number)) / Math.pow(10, number);
        // if (Number.isInteger(value)) {
        //     return value;
        // } else {
        //     return parseFloat(value).toFixed(number);
        // }
    },
    /**
     * 数组去重
     */
    filterRepetitionArray(arrList) {
        if (arrList == null || arrList.length == 0) {
            return arrList;
        }
        const resultList = [];
        for (let i = 0; i < arrList.length; i++) {
            const item = arrList[i];
            if (resultList.indexOf(item) == -1) {
                resultList.push(item);
            }
        }
        return resultList;
    },
    /**
     * 数组 - 属性去重
     */
    filterRepetitionArrayByProperty(arrList, prop) {
        if (arrList == null || arrList.length == 0) {
            return arrList;
        }
        return arrList.filter(function (element, index, self) {
            return self.findIndex((el) => el[prop] == element[prop]) === index;
        });
    },
    /**
     * 分 转化  元
     */
    regFenToYuan(feng) {
        if (feng == null || feng == 0) {
            return 0;
        }
        let num = feng;
        num = feng * 0.01;
        num += "";
        const reg = num.indexOf(".") > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;
        num = num.replace(reg, "$1");
        num = this.toDecimal2(num);
        return num;
    },
    /**
     * 强制保留2位小数，如：2，会在2后面补上00.即2.00
     */
    toDecimal2(x) {
        const floatx = parseFloat(x);
        if (isNaN(floatx)) {
            return 0;
        }
        const f = Math.round(x * 100) / 100;
        let s = f.toString();
        let rs = s.indexOf(".");
        if (rs < 0) {
            rs = s.length;
            s += ".";
        }
        while (s.length <= rs + 2) {
            s += "0";
        }
        return s;
    },

    /**
     * 递归增加层级
     */
    dfs(list, level = 1) {
        if (!list || list.length == 0) return list;
        list.forEach((item) => {
            item.level = level;
            if (item.children && item.children.length > 0) {
                this.dfs(item.children, level + 1);
            }
        });
        return list;
    },
};
