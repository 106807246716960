import http from "@/utils/http";

export default {
    /*
     *  企业认证资料新增
     * */
    authInfoAdd(query) {
        return http.post("/org/cert/add", query);
    },
    /*
     *  企业认证资料编辑
     * */
    authInfoEdit(query) {
        return http.put("/org/cert/update", query);
    },
    /*
     *  企业认证资料详情
     * */
    authInfoOne(query) {
        return http.get("/org/cert/one", query);
    },
    /**
     * 给子公司授权
     * @param params
     */
    certAuthOrg(params) {
        return http.post("/org/cert/junior/auth", params);
    },
    /**
     * 修改状态
     * @param params
     */
    updateNewState(params) {
        return http.put("/org/cert/update/new/state", params);
    },

    /*
     *  查询当前组织的认证状态
     * */
    authState(query) {
        return http.get("/org/cert/state", query);
    },

    /*
     *  给子公司授权
     * */
    authJunior(query) {
        return http.post("/org/cert/junior/auth", query);
    },

    /*
     *  获取企业认证协议
     * */
    getprotocol(query) {
        return http.get("/org/cert/protocol", query);
    },
    /**
     * 查询当前组织下级组织
     * @param params
     */
    getOrgCommonLevel(params) {
        return http.get("/org/manage/common/level", params);
    },
};
