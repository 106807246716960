/**
 *  路由管理
 */
const RouterConstants = {
    /**
     *  固定路由- 默认路由-不删除-比如控制台
     */
    FIX_PATH: "/dashboard",

    /**
     * 企业认证页面
     */
    ENTERPRISE_AUTHENTICATION: "/myorg/enterpriseAuthentication",
    /**
     *  企业认证新增/编辑
     */
    MYORG_ENTERPRISE_AUTH_CREATE: "/myorg/authenticationMeansWrite",

    /**
     *  企业认证详情
     */
    MYORG_ENTERPRISE_AUTH_DETAIL: "/myorg/EnterpriseAuthenticationDetail",

    /**
     *  新闻详情
     */
    NEWS_DETAIL: "/news/newsdetail",
    /**
     * 认证审核列表
     */
    CERT_AUDIT_LIST: "/myorg/certificationAudit/list",
    /**
     * 认证审核详情
     */
    CERT_AUDIT_DETAIL: "/myorg/certificationAudit/detail",
    /**
     * 认证设置
     */
    CERT_AUDIT_SET: "/myorg/certificationAudit/set",
    /**
     * 参数列表
     */
    PARAM_LIST: "/system/paramList",
    /**
     * 新增参数
     */
    PARAM_ADD: "/system/paramCreate",
    /**
     * 编辑参数
     */
    PARAM_EDIT: "/system/paramEdit",
    /**
     * 参数详情
     */
    PARAM_DETIAL: "/system/paramDetail",

    // 通用
    UNIVERSAL: {
        /**
         * 数据看板
         */
        DATA_VISUAL_PLATFORM_PATH: "/statistics/DataVisualPlatform",
    },
    COMPANY_REGISTER: {
        /**
         * 企业入驻
         */
        COMPANY_REGISTER_PATH: "/companyRegister",
        FIRST_REGISTER_PATH: "/firstRegister",
        SECOND_REGISTER_PATH: "/secondRegister",
        THREE_REGISTER_PATH: "/threeRegister",
    },
    // 投诉
    SUGGEST: {
        /**
         * 投诉建议列表
         */
        SUGGEST_LIST: "/suggestion/index",
    },

    // 中间页面
    MIDDLEWARE: {
        /**
         * 切换组织
         */
        CHANGE_ORG_PAGE: "/change/org",
    },

    // 新闻
    NEWS: {
        /**
         * 企业新闻列表
         */
        COMPANY_NEWS_LIST: "/company/news/list",
        /**
         * 企业新闻列表详情
         */
        COMPANY_NEWS_DETAIL: "/company/news/detail",
    },

    // 固定路由
    ROOT_PATH: "/dashboard",

    // 组织模块
    ORG: {
        /**
         * 组织列表box
         */
        ORG_LIST_BOX: "/org/organize_box",
        /**
         * 组织列表
         */
        ORG_LIST: "/org/organize",
        /**
         * 编辑组织
         */
        ORG_EDIT: "/org/editorganize",
        /**
         * 组织详情
         */
        ORG_DETAIL: "/org/organizationDetail",
        /**
         * 角色管理
         */
        ORG_ROLE: "/org/role",
        /**
         * 站点管理
         */
        ORG_SITE: "/org/site",

        /**
         * 部门管理
         */
        ORG_DEPARTMENT: "/org/department",
        /**
         * 设备管理
         */
        ORG_DEVICE_LIST: "/system/vehicleList",

        /**
         * 教室管理
         */
        ORG_CLASS_ROOM_LIST: "/system/roomList",

        /**
         * 管理层级图
         */
        ORG_TREE: "/org/organizeTree",
        /**
         * 学员管理
         */
        ORG_STUDENT_LIST: "/student/list",
        /**
         * 计划管理
         */
        ORG_PLAN_LIST: "/trainmanger/planManager",
        /**
         * 员工管理
         */
        ORG_EMPLOYEE_LIST: "/org/employee",

        /**
         * 认证审核
         */
        ORG_AUTH_AUDIT: "/myorg/certificationAudit/list",
        /**
         * 查看订单
         */
        ORG_ORDER_LIST: "/order/payOrderList",
        /**
         * 查看考试
         */
        ORG_EXAM_LIST: "/exam/examRecordList",
        /**
         * 安全培训 - 考试记录
         */
        ORG_SAFE_TRAIN_EXAM_LIST: "/safe/train/exam/record/list",
        /**
         * 计费信息变更日志
         */
        ORG_BILLING_MODE_CHANGE_LOG: "/org/billingmodelRecord",
        /**
         * 计费信息变更日志详情
         */
        ORG_BILLING_MODE_CHANGE_LOG_DETAIL: "/org/billingmodelRecord/detail",
        /**
         * 培训管理员
         */
        ORG_TRAIN_SUPERVISE_LIST: "/student/train/admin",

        /**
         * 我的组织
         */
        ORG_MINE: "/myorg/index",
        /**
         * 我的组织box
         */
        ORG_MINE_BOX: "/myorg/index_box",
        /**
         * 新增组织
         */
        ORG_ADD: "/org/addorganize",
        /**
         * 付费模式审核
         */
        ORG_BILLING_MODE_AUDIT: "/org/paymentModeAudit",
        /**
         * 安全培训-创建培训
         */
        SAVE_TRAIN_CREATE: "/safe/train/list",
    },

    /**
     *  学员管理
     */
    STUDENT: {
        /**
         * 学员列表
         */
        STUDENT_LIST: "/student/list",
        STUDENT_INFO_PRINT: "/student/print/info",
    },

    /**
     * 计划管理
     */
    PLAN: {
        /**
         * 计划列表
         */
        PLAN_LIST: "/trainmanger/planManager",
        /**
         * 计划流水
         */
        PLAN_TRAIN_FLOW_RECORD: "/trainmanger/flowRecord",
        PLAN_SAFETY_RESPONSIBILITY_LETTER: "/plan/safety/responsibility/letter", // 安全责任书
        PLAN_CERTIFICATE: "/plan/certificate", // 合格证
    },
    /**
     * 系统管理
     */
    SYSTEM: {
        /**
         * 设备管理
         */
        DEVICE_LIST: "/system/vehicleList",
        /**
         * 导出文件管理列表
         */
        EXPORT_FILE_LIST: "/system/file/list",
    },

    // 市场负责人管理
    /**
     * 市场负责人管理  - 新增
     */
    MARKETLADER_ADD: "/business/marketLeaderCreate",
    /**
     * 市场负责人管理  - 详情
     */
    MARKETLADER_DETIAL: "/business/marketLeaderDetail",

    // 业务管理管理
    /**
     * 市场负责人管理  - 新增
     */
    BUSINESS_MANAGER_ADD: "/business/businessManagerAdd",
    /**
     * 市场负责人管理  - 编辑
     */
    BUSINESS_MANAGER_EDIT: "/business/businessManagerEdit",
    /**
     * 市场负责人管理  - 详情
     */
    BUSINESS_MANAGER_DETAIL: "/business/businessManagerDetail",
    /**
     * 账户变更日志列表
     */
    ACCOUNT_CHANGE_LOG: "/finance/AccountChangeLog",

    /**
     * 收支明细-查看凭证
     */
    PAYMENT_ADD_PREVIEW: "/finance/paymentAddPreview",

    /**
     * 收支明细-列表
     */
    PAYMENT_DETAIL_LIST: "/finance/paymentDetailList",

    /**
     * 账户变更日志-补充凭证
     */
    SUPPLEMENT_VOUCHER: "/finance/supplementVoucher",

    /**
     * 新增组织
     */
    ORGAIN_ADD: "/org/addorganize",

    /**
     * 改价规则详情
     */
    FINANCE_PRICINGRULES_DEAIL: "/finance/pricingRulesDetail",
    /**
     * 改价计划列表
     */
    FINANCE_PRICECHANGE_PLAN_LIST: "/finance/priceChangePlanList",

    /**
     * 新增改价规则
     */
    FINANCE_PRICINGRULES_ADD: "/finance/pricingRulesAdd",
    /**
     * 企业月账单列表
     */
    BUSINESS_MONTHLY_ORDER_LIST: "/order/businessMonthlyOrderList",
    /**
     * 企业月账单详情
     */
    BUSINESS_MONTHLY_ORDER_DETAIL: "/order/businessMonthlyOrderDetail",
    /**
     * 订单管理
     */
    PAY_ORDER_LIST: "/order/payOrderList",
    /**
     * 改价计划详情
     */
    PRICE_CHANGE_PLAN_DETAIL: "/finance/priceChangePlanDetail",

    /**
     * 帮助中心
     */
    HELP_CENTER: "/help/center",

    /**
     * 课件
     */
    LOGIN_LESSON_NEWS_LIST: "/lessons/news/list",

    /**
     * 无权限路由
     */
    SYSTEM_NO_PERMISSION_ROUTE: "/system/noPermissionRoute",

    /**
     * 外链路由lzb 2021-07-21
     */
    EXTERNAL_LINE: {
        /**
         * 协议
         */
        WEB_PROTOCOL: "/protocolWeb",
        ANTUBANG_WEB: "/antubang-web",
    },
    /**
     * 登录 lzb 2021-08-04
     */
    LOGIN: {
        LOGIN_TEMPLATE_THREE: "/loginTemplateThree",
    },
    /**
     * 安全例会 - 个人报告预览
     */
    SAFE_REGULAR: {
        SAFE_REGULAR_PERSONAL_DETAIL_EXPORT: "/safe/regular/personal/detail/export/detail",
        SAFE_REGULAR_PERSONAL_RECORD_EXPORT: "/safe/regular/personal/record/export/detail",
    },
    /**
     * 数据管理
     */
    // DATA_MANAGER: {
    //     DRIVING_BEHAVIOR_STATISTICS: "/datamanager/drivingBehaviorStatistics",// 驾驶员驾驶行为统计
    //     DRIVING_BEHAVIOR_DETAILED: "/datamanager/drivingBehaviorDetailed",// 驾驶行为明细管理
    //     DRIVING_BEHAVIOR_DETAILED_DETAIL: "/datamanager/drivingBehaviorDetailedDetail",// 驾驶行为明细详情
    //     THIRD_PARTY_PLATFORM_LIST: "/datamanager/thirdPartyPlatformList",// 第三方平台管理
    //     THIRD_PARTY_PLATFORM_ADD: "/datamanager/thirdPartyPlatformAdd",// 新增第三方平台管理
    //     THIRD_PARTY_PLATFORM_EDIT: "/datamanager/thirdPartyPlatformEdit",// 编辑第三方平台管理
    //     THIRD_PARTY_PLATFORM_DETAIL: "/datamanager/thirdPartyPlatformDetail",// 第三方平台管理详情
    //     NAME_CONVERSION_MANAGER_LIST: "/datamanager/nameConversionManagerList",// 标准名称转换管理
    //     NAME_CONVERSION_MANAGER_ADD: "/datamanager/nameConversionManagerAdd",// 新增标准名称转换管理
    //     NAME_CONVERSION_MANAGER_EDIT: "/datamanager/nameConversionManagerEdit",// 编辑标准名称转换管理
    //     NAME_CONVERSION_MANAGER_DETAIL: "/datamanager/nameConversionManagerDetail",// 标准名称转换管理详情
    // }
    DATA_MANAGER: {
        DRIVING_BEHAVIOR_STATISTICS: "/datamanager/drivingBehaviorStatistics", // 驾驶员驾驶行为统计
        DRIVING_BEHAVIOR_DETAILED: "/datamanager/drivingBehaviorDetailed", // 驾驶行为明细管理
        DRIVING_BEHAVIOR_DETAILED_DETAIL: "/datamanager/drivingBehaviorDetailedDetail", // 驾驶行为明细详情
        DRIVING_BEHAVIOR_DETAILED_ADD: "/datamanager/drivingBehaviorDetailed/add", // 驾驶行为明细详情
        DRIVING_BEHAVIOR_DETAILED_EDIT: "/datamanager/drivingBehaviorDetailed/edit", // 驾驶行为明细详情
        THIRD_PARTY_PLATFORM_LIST: "/datamanager/thirdPartyPlatformList", // 第三方平台管理
        THIRD_PARTY_PLATFORM_ADD: "/datamanager/thirdPartyPlatformAdd", // 新增第三方平台管理
        THIRD_PARTY_PLATFORM_EDIT: "/datamanager/thirdPartyPlatformEdit", // 编辑第三方平台管理
        THIRD_PARTY_PLATFORM_DETAIL: "/datamanager/thirdPartyPlatformDetail", // 第三方平台管理详情
        NAME_CONVERSION_MANAGER_LIST: "/datamanager/nameConversionManagerList", // 标准名称转换管理
        NAME_CONVERSION_MANAGER_ADD: "/datamanager/nameConversionManagerAdd", // 新增标准名称转换管理
        NAME_CONVERSION_MANAGER_EDIT: "/datamanager/nameConversionManagerEdit", // 编辑标准名称转换管理
        NAME_CONVERSION_MANAGER_DETAIL: "/datamanager/nameConversionManagerDetail", // 标准名称转换管理详情
    },
    // 协议管理
    AGREEMENT_MANAGER: {
        AGREEMENT_MANAGER_LIST: "/agreement/list", // 协议管理-列表
        AGREEMENT_MANAGER_ADD: "/agreement/add", // 协议管理-新增
        AGREEMENT_MANAGER_EDIT: "/agreement/edit", // 协议管理-编辑
        AGREEMENT_MANAGER_DETAIL: "/agreement/detail", // 协议管理-详情
        AGREEMENT_MANAGER_PREVIEW: "/agreement/preview", // 协议管理-预览
        AGREEMENT_MANAGER_SIGN_RECORD: "/agreement/sign/record", // 协议管理-签订明细
        AGREEMENT_MANAGER_SIGN_RECORD_DETAIL: "/agreement/sign/record/detail", // 协议管理-签订明细-详情
    },

    // 证书打印 2023-02-06 lzb
    CERT_PRINT_MANAGER: {
        CERT_TEMPLATE_ADD: "/certificate/print/template/add", // 证书模版-新增
        CERT_TEMPLATE_EDIT: "/certificate/print/template/edit", // 证书模版-编辑
        CERT_TEMPLATE_DETAIL: "/certificate/print/template/detail", // 证书模版-详情
        CERT_TEMPLATE_LIST: "/certificate/print/template/list", // 证书模版-列表
        CERT_TEMPLATE_LIST_SELECT: "/certificate/print/template/select", // 证书模版-选择证书模版

        CERT_MANAGER_ADD: "/certificate/print/add", // 证书管理-新增
        CERT_MANAGER_EDIT: "/certificate/print/edit", // 证书管理-编辑
        CERT_MANAGER_DETAIL: "/certificate/print/detail", // 证书管理-详情
        CERT_MANAGER_LIST: "/certificate/print/list", // 证书管理-列表
        CERT_MANAGER_PREVIEW: "/certificate/print/preview", // 证书管理-预览
        CERT_MANAGE_LIST_SELECT: "/certificate/print/cert/select", // 证书管理-选择证书
    },

    // 打印审核
    PRINT_MANAGER: {
        PRINT_AUDIT_LIST: "/certificate/print/audit/list", // 证书管理-打印审核-打印审核列表
        PRINT_AUDIT_DETAIL: "/certificate/print/audit/detail", // 证书管理-打印审核-打印审核详情
    },
    /**
     * 中间过渡页面
     */
    MIDDLE_WARE: "/middle/ware",
    /**
     *通用页面
     */
    COMMON_PAGE: {
        PDF_PREVIEW: "/preview/pdf",
        QUALIFIED_CERT_PREVIEW: "/qualified/cert/preview",
    },
};

// 一日三检 lzb 20231007
export const DEVICE_ARCHIVES = {
    CHECK_ITEM_LIST: "/device/check/item/list", // 检查项管理
    CHECK_ITEM_EDIT: "/device/check/item/edit", // 检查项设置
    CHECK_ITEM_DETAIL: "/device/check/item/detail", // 检查项详情
    CHECK_ITEM_RECORD: "/device/check/item/record", // 一日三检记录
    CHECK_ITEM_RECORD_AUDIT: "/device/check/item/audit", // 一日三检-审核
    CHECK_ITEM_RECORD_REFORM: "/device/check/item/reform", // 一日三检-确认整改
    CHECK_ITEM_RECORD_DETAIL: "/device/check/record/detail", // 一日三检-详情
    CHECK_ITEM_RECORD_HISTORY_DETAIL: "/device/check/history/record/detail", // 一日三检-历史详情
    CHECK_ITEM_RECORD_SUMMARY_DETAIL: "/device/check/summary/export/preview", // 一日三检-汇总数据详情
};

// 车辆例检 lzb 20240326
export const CAR_INSPECTION = {
    CAR_INSPECTION_LIST: "/car/inspection/list", //车辆例检-列表
    CAR_INSPECTION_ADD: "/car/inspection/add", //车辆例检-设置例检检查项
    CAR_INSPECTION_DETAIL: "/car/inspection/detail", //车辆例检-车辆例检详情
    CAR_INSPECTION_EXPORT_PREVIEW: "/car/inspection/export/preview", //车辆例检-车辆例检导出
    CAR_INSPECTION_RECORD_LIST: "/car/inspection/record/list", //车辆例检-车辆例检明细
    CAR_INSPECTION_RECORD_DETAIL: "/car/inspection/record/detail", //车辆例检-车辆例检明细详情
    CAR_INSPECTION_HISTORY_RECORD_DETAIL: "/car/inspection/history/record/detail", //车辆例检-车辆例检历史记录详情
};

// 我的组织跳转
export const ORG_INFO_ROUTER = {
    ORG_INFO_ROUTER_ARCHIVES: "/safe/train/archives/list", // 培训档案
    ORG_INFO_ROUTER_TRAIN_LIST: "/safe/train/list", // 创建培训
    ORG_INFO_ROUTER_STUDENT_LIST: "/student/list", // 员工管理
    ORG_INFO_ROUTER_EMPLOYEE: "/org/employee", // 管理员管理
    ORG_INFO_ROUTER_FACE_CHECK_LIST: "/student/faceCheckList", // 人像审核
    ORG_INFO_ROUTER_PARAM_LIST: "/system/paramList", // 参数管理
    ORG_INFO_ROUTER_ORG_LIST: "/org/editorganize", // 企业信息
    ORG_INFO_ROUTER_EQUIPMENT_LIST: "/system/equipmentCategoryList", // 设备管理
    ORG_INFO_ROUTER_TRAIN_ADMIN: "/student/train/admin", // 监督员管理
    ORG_INFO_ROUTERS_CHEDULE_LIST: "/safe/train/study/schedule/list", // 培训进度
    ORG_INFO_ROUTER_RECEIVE_BOX: "/docs/receiveBox", // 公文收文
    ORG_INFO_ROUTER_ORGANIZE: "/org/organize", // 企业管理
    ORG_INFO_ROUTER_SEND_BOX: "/docs/send-box", // 公文发文
};

export { RouterConstants };

/**
 * 课件模块 lzb 20240514
 */
export const LESSON = {
    LESSON_AUDIT: "/lesson/audit", // 课件审核
    LESSON_THIRD_PARTY_AUDIT: "/lesson/third/party/audit", // 课第三方审核
};
