import StoreBaseDBManger from "./StoreBaseDBManger";
import GlobalModel from "../class/GlobalModel";
import TBUserManger from "@utils/user/TBUserManger";
import { Constants } from "@utils/constants/Constants";

export default {
    keys: {
        // 保存本地信息IP
        USER_LOCAL_INFO_IP: "TB_USER_LOCAL_INFO_IP",
        // 保存是否全局信息
        SAVE_GLOBAL_INFO: "TB_SAVE_GLOBAL_INFO",
        // 保存看板定时时间
        SAVE_DATA_VISUAL_PLATFORM_TIME: "SAVE_DATA_VISUAL_PLATFORM_TIME",
        // 保存看板是手动还是自动
        SAVE_DATA_VISUAL_ISMANUAL: "SAVE_DATA_VISUAL_ISMANUAL",
        // 保存用户选择的身份对象
        SAVE_USER_LOGIN_IDENTITY: "SAVE_USER_LOGIN_IDENTITY",
        // 保存用户ID
        SAVE_USER_ID: "SAVE_USER_ID",
        // 保存用户菜单
        SAVE_USER_MENU: "SAVE_USER_MENU",
        PLAN_TEMPLATE_REMEMBER: "PLAN_TEMPLATE_REMEMBER",
    },
    /**
     * 保存本地ip信息
     */
    saveLocalIPInfoData(data) {
        if (!data) {
            console.warn("保存本地信息不能为null");
            return;
        }
        const key = this.keys.USER_LOCAL_INFO_IP;

        StoreBaseDBManger.saveDataToLocalStorage(key, data);
    },
    /**
     *  获取本地IP信息
     */
    getLocalIPInfoData() {
        const key = this.keys.USER_LOCAL_INFO_IP;
        const ipInfo = StoreBaseDBManger.getDataWithLocalStorage(key);

        return ipInfo;
    },
    // // ===================================保存菜单 start==================================//
    /**
     * 保存用户菜单
     */
    saveUserMenuData(menuList) {
        if (!menuList || menuList.length === 0) {
            console.log("保存的菜单不能为空");
            return false;
        }
        const key = this.keys.SAVE_USER_MENU;
        StoreBaseDBManger.saveDataToLocalStorage(key, menuList);
    },
    /**
     * 获取用户菜单 - removeUserMenuListData
     */
    getUserMenuListDataFromDB() {
        const key = this.keys.SAVE_USER_MENU;
        const menuList = StoreBaseDBManger.getObjectWithLocalStorage(key);
        return menuList;
    },
    /**
     * 移除数据
     */
    removeUserMenuListData() {
        const key = this.keys.SAVE_USER_MENU;
        StoreBaseDBManger.removeDataWithLocalStorage(key);
    },
    //
    // // ===================================保存菜单 end==================================//

    // ===================================保存模板信息=================================//

    // ===================================保存用户id start==================================//
    /**
     *  保存用户Id
     */
    saveUserIdToDB(userId) {
        if (!userId) {
            console.warn("保存userId不能为null");
            return;
        }
        const dbUserId = TBUserManger.getCurrentUserId();
        if (dbUserId !== userId) {
            this.removeUserIdFromDB();
        }
        // console.log('保存新的userId', userId);
        const key = this.keys.SAVE_USER_ID;
        StoreBaseDBManger.saveDataToLocalStorage(key, userId);
    },
    /**
     * 获取用户ID
     */
    getUserIdFromDB() {
        const key = this.keys.SAVE_USER_ID;
        const userId = StoreBaseDBManger.getDataWithLocalStorage(key);
        return userId;
    },
    /**
     * 移除用户id
     */
    removeUserIdFromDB() {
        const key = this.keys.SAVE_USER_ID;
        StoreBaseDBManger.removeDataWithLocalStorage(key);
    },

    //= ==================================保存全局信息==================================//
    /**
     *  保存全局信息
     */
    saveGlobalModelToDB(model) {
        if (!model) {
            console.warn("保存本地信息不能为null");
            return;
        }
        this.removeGlobalModel();
        const targetModel = GlobalModel.shareInstance();

        Object.assign(targetModel, model);
        const key = this.keys.SAVE_GLOBAL_INFO;

        StoreBaseDBManger.saveDataToLocalStorage(key, targetModel);
    },
    /**
     * 更新全局信息
     */
    updateGlobalModel(model) {
        this.saveGlobalModelToDB(model);
    },
    /**
     * 获取全局信息
     */
    getGlobalModel() {
        const key = this.keys.SAVE_GLOBAL_INFO;
        let item = StoreBaseDBManger.getObjectWithLocalStorage(key);

        if (item == null) {
            item = GlobalModel.shareInstance();
        }
        return item;
    },
    /**
     * 移除全局信息
     */
    removeGlobalModel() {
        const key = this.keys.SAVE_GLOBAL_INFO;

        StoreBaseDBManger.removeDataWithLocalStorage(key);
    },
    /**
     * 处理包含全局key的信息
     */
    removeContainerOfGlobalInfo() {
        StoreBaseDBManger.removeContainerOfKeyWord(this.keys.SAVE_GLOBAL_INFO);
    },

    // // ===================================保存用户身份信息 start==================================//
    // /**
    //  *  保存用户登录的身份
    //  */
    // saveUserLoginIdentifyToDB(item) {
    //     if (!item) {
    //         console.warn("保存本地信息不能为null");
    //         return;
    //     }
    //
    //     const userId = this.getUserIdFromDB();
    //     if(!userId || userId.length == 0){
    //         console.warn("userid 为null");
    //         return ;
    //     }
    //
    //     // console.log('保存用户身份 -userid =',userId)
    //     this.removeContainerOfUserLoginIdentify();
    //
    //     const key = userId + this.keys.SAVE_USER_LOGIN_IDENTITY;
    //
    //     StoreBaseDBManger.saveDataToLocalStorage(key, item);
    // },
    // /**
    //  * 获取用户登录的身份
    //  */
    // getUserLoginIdentifyFromDB() {
    //     const userId = TBUserManger.getCurrentUserId();
    //     if(!userId || userId.length == 0){
    //         return  null;
    //     }
    //
    //     const key = userId + this.keys.SAVE_USER_LOGIN_IDENTITY;
    //     const item = StoreBaseDBManger.getObjectWithLocalStorage(key);
    //     return item;
    // },
    // /**
    //  * 处理包含全局key的信息
    //  */
    // removeContainerOfUserLoginIdentify(){
    //     StoreBaseDBManger.removeContainerOfKeyWord(this.keys.SAVE_USER_LOGIN_IDENTITY);
    // },

    // ===================================保存用户身份信息 end==================================//

    /** *****************************看板定时时间**SAVE_DATA_VISUAL_PLATFORM_TIME**************************************** */
    /**
     * 保存看板定义的时间
     * @param {*} data
     */
    saveDataVisualPlatformTime(data) {
        if (!data) {
            console.warn("保存本地信息不能为null");
            return;
        }
        const key = TBUserManger.getCurrentUserId() + this.keys.SAVE_DATA_VISUAL_PLATFORM_TIME;

        StoreBaseDBManger.saveDataToLocalStorage(key, data);
    },
    /**
     * 获取看板定义的时间
     */
    getDataVisualPlatformTime() {
        const key = TBUserManger.getCurrentUserId() + this.keys.SAVE_DATA_VISUAL_PLATFORM_TIME;
        const time = StoreBaseDBManger.getDataWithLocalStorage(key);

        return time;
    },
    /**
     * 移除看板定义的时间
     */
    removeDataVisualPlatformTime() {
        const key = TBUserManger.getCurrentUserId() + this.keys.SAVE_DATA_VISUAL_PLATFORM_TIME;

        StoreBaseDBManger.removeDataWithLocalStorage(key);
    },
    /** *****************************看板手动还是自动**SAVE_DATA_VISUAL_ISMANUAL**************************************** */
    /**
     * 保存看板定义的时间
     * @param {*} data
     */
    saveDataVisualIsManual(data) {
        if (data === null || data === undefined) {
            console.warn("保存本地信息不能为null");
            return;
        }
        const key = TBUserManger.getCurrentUserId() + this.keys.SAVE_DATA_VISUAL_ISMANUAL;

        StoreBaseDBManger.saveDataToLocalStorage(key, data);
    },
    /**
     * 获取看板定义的时间
     */
    getDataVisualIsManual() {
        const key = TBUserManger.getCurrentUserId() + this.keys.SAVE_DATA_VISUAL_ISMANUAL;
        const isManual = StoreBaseDBManger.getDataWithLocalStorage(key);

        return isManual;
    },
    /**
     * 移除看板定义的时间
     */
    removeDataVisualIsManual() {
        const key = TBUserManger.getCurrentUserId() + this.keys.SAVE_DATA_VISUAL_ISMANUAL;

        StoreBaseDBManger.removeDataWithLocalStorage(key);
    },

    /**
     * 清除所有的本地保存数据
     */
    clearLocalStorage() {
        StoreBaseDBManger.clearAllStorage();
    },

    /**
     * 移除vuex本地保存的数据
     */
    removeVuexStorage() {
        StoreBaseDBManger.removeDataWithLocalStorage(Constants.vuexKey);
        StoreBaseDBManger.removeDataWithLocalStorage("vuex");
    },

    // ================================= 新增计划，选择模板弹框 =================================
    /**
     * 新增计划选择模板弹框
     * @param data
     */
    savePlanTemplateRemember(data) {
        if (data === null || data === undefined) {
            console.warn("保存本地信息不能为null");
            return;
        }
        StoreBaseDBManger.saveDataToLocalStorage(this.keys.PLAN_TEMPLATE_REMEMBER, data);
    },

    getPlanTemplateRemember() {
        return StoreBaseDBManger.getDataWithLocalStorage(this.keys.PLAN_TEMPLATE_REMEMBER);
    },
};
