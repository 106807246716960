import http from "@/utils/http";
import commonHttp from "@api/common/commonHttp";

export default {
    /**
     *
     通用组织查询（添加所有查询结果）
     *
     * @param query
     * @returns {*}
     */
    findCommonOrg(param) {
        // delete lzb 2020-11-19 通用接口整改
        // return http.get('/org/manage/common/list', param);
        return commonHttp.getCommonOrgList(param);
    },
    findResourceList(query) {
        // return http.get('/news?q=修改', query);
        return http.get("/resourceList", query);
        // return http.get('/news/1', query);
    },
    getInit(query) {
        return http.get("/resource", query);
    },
    getOne(params) {
        return http.get("/resourceList/", params);
    },
    add(params) {
        return http.post("/resourceList/", params);
    },
    del(id) {
        return http.delete(`/resourceList/${id}`);
    },
    update(id, params) {
        return http.put(`/resourceList/${id}`, params);
    },

    /*
     * 组织列表查询，试卷上级组织，财务代理商
     * */
    getGrouparerList(query) {
        return http.get("/org/manage/common/grouparea/list", query);
    },

    /*
     * 组织列表查询，试卷上级组织，财务代理商   新20190910
     * */
    getExamGrouparerList(query) {
        return http.get("/org/manage/common/paramarea/list", query);
    },

    /*
     * 组织列表查询，企业认证
     * */
    getOrgAuthManageList(query) {
        return http.get("/org/manage/manager/common/list", query);
    },
    /*
     * 组织列表查询，企业认证
     * */
    putOrgManageExtJsonAdd(query) {
        return http.put("/org/manage/extJson/add", query);
    },
    /**
     * 1.0.31更新组织的行业
     */
    updateOrgManageExtJsonAndState(param) {
        return http.post("/org/manage/extJsonAndState/mod", param);
    },
};
