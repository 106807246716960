import router from "@/router";
import Vue from "vue";
import { HudGlobal } from "@utils/HudGlobal";
import LoginManager from "@utils/manager/LoginManager";
import { Constants } from "@utils/constants/Constants";
import { isLoadedRoutePath, reloadRoutes } from "@utils/router/RouteUtil";
import { ConstantErrors } from "@utils/constants/ConstantErrors";
import { RouterConstants } from "./utils/constants/RouterConstants";
import StoreUserDB from "./utils/db/StoreUserDB";
import { autoLoginByToken, fetchAuthTokenByCookie, refreshTokenFromRemote } from "./utils/user/LoginHelper";
import { showCustomAlert } from "./utils/alert/TBWindowAlert";

// 访问权限路由, 不重定向白名单
const whiteList = Constants.routerWhiteList;

// 不验证token的白名单
const whiteNoTokenList = [];

// 刷新加载路由
reloadRoutes();
router.beforeEach(async (to, from, next) => {
    Vue.prototype.prevRoute = from;
    console.log("permission===toPath=%s", to.path, to);
    // 0. 跳转的白名单
    if (whiteList.indexOf(to.path) !== -1) {
        // 如果白名单里有此路由
        next();
        return;
    }

    // 1. 不用校验token的白名单
    if (whiteNoTokenList.indexOf(to.path) !== -1) {
        next();
        return;
    }
    // 1.1 lzb 2021-08-12 解决正在增加路由调用beforeEach 问题
    if (window.__addRouteing) {
        return;
    }

    // 2.检测是否有token 检测是否登录, 存储于cookie中 0未过期, 1即将过期, 2已过期 -1 不存在/切换了token -2 切换的账户登录
    const tokenExpired = await StoreUserDB.getTokenExpiredResult();
    console.log("permission=====token状态=%d", tokenExpired);
    if (tokenExpired == -2) {
        checkUserAccountSwitch(to, next);
        return false;
    }

    // 2.2 token 已过期
    if (tokenExpired === 2) {
        handleTokenExpired(to, next);
        return;
    }
    // // token 即将过期
    // if (tokenExpired === 1) {
    //     const result = await refreshTokenFromRemote();
    //     console.log("刷新token---", result);
    // }

    //  未登录-cookie检测-不存在/切换了token
    if (tokenExpired === -1) {
        //  获取token
        const code = await fetchAuthTokenByCookie();
        console.log("获取本地cookie中的token---code=", code);
        if (code == 0) {
            // 直接自动登录
            const result = await autoLoginByToken(next, to);
            result === 1 && toLogin();
            return false;
        }
        // 直接置位表示未登录
        return handleNotToken(to, next);
    }

    // 已有token 开始验证路由

    // 判断当前路由是否在路由中
    const isLoaded = isLoadedRoutePath(to.path);

    if (isLoaded) {
        //  重定向路由
        if (to.path === "/") {
            next(RouterConstants.FIX_PATH);
            return;
        }
        next();
    } else {
        try {
            // 加载路由完成
            reloadRoutes(to.path);
            const isExited = isLoadedRoutePath(to.path);
            // 未输入路径的请求20230816 lzb
            if (!isExited && to.path === "/") {
                next(RouterConstants.FIX_PATH);
                return;
            }
            if (isExited) {
                // to传入, 确保路由已被加载, replace本次跳转不留下历史记录
                next({ ...to, replace: true });
            } else {
                // 没有被加载
                toLogin();
            }
        } catch (error) {
            // 移除登录
            console.error(error);
            toLogin();
        }
    }
});
router.afterEach((to, from) => {
    removeAppLoading();
});

/**
 * 隐藏APP加载动画 lzb 2021-08-18
 */
function removeAppLoading() {
    const routeLoading = document.getElementById("routeLoading");
    if (!routeLoading || routeLoading.length == 0) return false;
    routeLoading.style.display = "none";
}

function toLogin() {
    LoginManager.checkJumpToLogin();
}

/**
 * 检测用户账号切换 2023-09-21
 */
function checkUserAccountSwitch(to, next) {
    if (window.__isHasSwitchLoginAlert) return false;
    // 账号更新
    const options = {
        content: "不允许同一个浏览器，同时登录多个账号！系统检测到：您已登录了其他平台，点击“确定”使用该账号快捷登录本平台！",
        isContainerCancel: false,
        isEnabledMaskEvent: false,
        ensureButtonTitle: "确定",
    };

    window.__isHasSwitchLoginAlert = true;
    showCustomAlert(options, async () => {
        window.__isHasSwitchLoginAlert = false;
        const code = await fetchAuthTokenByCookie();
        console.log("获取本地cookie中的token---code=", code);
        if (code == 0) {
            // 直接自动登录
            const result = await autoLoginByToken(next, to);
            result === 1 && toLogin();
            return false;
        } else {
            toLogin();
        }
        return false;
    });
}

/**
 * 处理token过期
 */
function handleTokenExpired(to, next) {
    const tokenExpiredTip = window.localStorage.getItem("tokenExpiredTip");
    // lzb 2020 -08-24 新增过期之后是否弹框, 如果本来就是登录路由
    if (to.path == "/login") {
        window.localStorage.setItem("tokenExpiredTip", "1");
        next(to.path);
        return false;
    }
    if (to.path == "/") {
        window.localStorage.setItem("tokenExpiredTip", "1");
        toLogin();
        return false;
    }
    console.log("permission tokenExpiredTip", tokenExpiredTip);
    if (!tokenExpiredTip) {
        window.localStorage.setItem("tokenExpiredTip", "1");
        // 已经有弹框啦 lzb 2021-08-12 解决多个弹框问题
        if (window.__isHasAlert) {
            return;
        }
        window.__isHasAlert = true;

        HudGlobal.showEnsureAlertMessage(ConstantErrors.description.LOGIN_EXPIRED_MESSAGE, "确定", null, false).then((flag) => {
            // rqmei 20210812 修复：点取消后不再弹该弹框的问题
            window.__isHasAlert = false;
            if (flag) {
                toLogin();
            }
        });
    } else {
        // 如果上面的操作出现问题, 则直接返回登陆, 不提示
        toLogin();
    }
}
/**
 * 处理没有token
 */
function handleNotToken(to, next) {
    if (whiteList.indexOf(to.path) !== -1) {
        // 白名单路由直接跳转
        next();
    } else {
        // 其它路由, 使用login跳转
        toLogin();
    }
}
