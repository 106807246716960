import http from "@/utils/http";
import TBUserManger from "../../../utils/user/TBUserManger";
export default {
    // 获取历史记录列表
    findMoreAppList(query) {
        return http.get("/system/appupgrade/page", query);
    },
    // 获取最新版本列表
    findAppList(query) {
        return http.get("/system/appupgrade/list", query);
    },
    // 查询下拉参数获取（客户端名称）
    initQuerParam(query) {
        const queryData = { ...query };
        return http.get("/system/parameter/code/list", queryData);
    },
    getParams(query, isApi = false) {
        if (isApi) {
            return http.get("/api/system/parameter/code/list", query, { isEncrypt: true });
        }
        return this.initQuerParam(query);
    },
    /**
     * 查询组织下参数
     * @orgId orgId
     */
    initQueryOrgParameter(param) {
        return http.get("/system/parameter/org/list", param);
    },

    add(params) {
        return http.post("/system/appupgrade/add", params);
    },
    update(params) {
        return http.put("/system/appupgrade/mod", params);
    },
    // 停止继续
    stopOrCon(params) {
        return http.put("/system/appupgrade/state/mod", params);
    },
    getOne(params) {
        return http.get("/apps", params);
    },
    del(id) {
        return http.delete(`/apps/${id}`);
    },
};
