var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "mask-fade" } }, [
    _vm.options.loadingShow
      ? _c("div", { staticClass: "loading-wrapper-mask" }, [
          _c("div", { staticClass: "loading-content-wrapper" }, [
            _c("div", { staticClass: "loading-content-text" }, [
              _c("span", [_vm._v(_vm._s(_vm.options.loadingText))]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }