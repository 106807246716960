/**
 * 计划显示处理 V1.0.9 lzb 2021-05-18
 *
 */
export default {
    /**
     * 培训状态 - filter数据
     * @param val
     * @returns {string}
     */
    formatterTrainingState(val) {
        let stateStr = "";
        let state = val;
        if (val instanceof Object) {
            state = val.trainingState;
        }

        if (typeof state == "string") {
            state = parseInt(state);
        }

        switch (state) {
            case 0:
                stateStr = "暂停";
                break;
            case 10:
                stateStr = "加入计划";
                break;
            case 11:
                stateStr = "离开计划";
                break;
            case 13:
                stateStr = "未开始";
                break;
            case 14:
                stateStr = "未培训";
                break;
            case 16:
                stateStr = "培训中";
                break;
            case 17:
                stateStr = "学习结束";
                break;
            case 18:
                stateStr = "学时审核不通过";
                break;
            case 19:
                stateStr = "待预约";
                break;
            case 20:
                stateStr = "考试预约失败";
                break;
            case 21:
                stateStr = "考试待审核";
                break;
            case 22:
                stateStr = "考试审核不通过";
                break;
            case 23:
                stateStr = "待考试";
                break;
            case 24:
                stateStr = "考试未通过";
                break;
            case 25:
                stateStr = "考试通过成绩待审核";
                break;
            case 26:
                stateStr = "成绩审核不通过";
                break;
            case 30:
                stateStr = "培训结束";
                break;
            case 31:
                stateStr = "证书打印待审核";
                break;
            case 32:
                stateStr = "证书打印审核不通过";
                break;
            case 33:
                stateStr = "证书未打印";
                break;
            case 34:
                stateStr = "证书已打印";
                break;
            case 35:
                stateStr = "申报";
                break;
            case 36:
                stateStr = "归档";
                break;
            default:
                stateStr = "未知";
        }

        return stateStr;
    },
};
