import Vue from "vue";
import Loading from "@components/loading/Loading";

Loading.install = function (options) {
    const LoadingExtendVue = Vue.extend(Loading);

    const loadingVm = new LoadingExtendVue({
        data: options,
    }).$mount();

    document.body.appendChild(loadingVm.$el);
    return loadingVm;
};

export default Loading;
