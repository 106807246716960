import StoreMainManger from "../db/StoreMainManger";
import { getParser } from "bowser";

const packageJson = require("../../../package.json");

export default {
    /**
     * 获取UI给定屏幕尺寸-宽度
     */
    getDefaultSizeWidth() {
        return 1920.0;
    },
    /**
     * 获取UI给定屏幕尺寸-高度
     */
    getDefaultSizeHeight() {
        return 1080.0;
    },
    /**
     * 获取当前窗口的宽度
     */
    getCurrentWindowSizeWidth() {
        const width = document.documentElement.clientWidth;
        return width;
    },
    /**
     * 获取当前窗口的高度
     */
    getCurrentWindowSizeHeight() {
        const height = document.documentElement.clientHeight;
        return height;
    },
    /**
     * 屏幕宽度比例
     */
    getScreenWidthScale() {
        const radio = this.getCurrentWindowSizeWidth() / this.getDefaultSizeWidth();
        return radio;
    },
    /**
     * 屏幕高度比例
     */
    getScreenHeightScale() {
        const radio = this.getCurrentWindowSizeHeight() / this.getDefaultSizeHeight();
        return radio;
    },
    /**
     * 获取浏览器类型
     */
    getBrowserType() {
        const userAgent = window.navigator.userAgent; // 取得浏览器的userAgent字符串
        // console.log('浏览器信息----', userAgent);
        // 判断是否Opera浏览器
        const isOpera = userAgent.indexOf("Opera") > -1;
        if (isOpera) {
            return "Opera";
        }
        // 判断是否Firefox浏览器
        if (userAgent.indexOf("Firefox") > -1) {
            return "FF";
        }
        // 判断是否Chrome浏览器
        if (userAgent.indexOf("Chrome") > -1) {
            return "Chrome";
        }
        // 判断是否Safari浏览器
        if (userAgent.indexOf("Safari") > -1) {
            return "Safari";
        }
        // 判断是否IE浏览器
        if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) {
            return "IE";
        }
    },
    /**
     * 获取设备的IP地址
     */
    getDeviceIPAddress() {
        try {
            return StoreMainManger.getLocalIPInfoData();
        } catch (error) {
            return "";
        }
    },
    /**
     * 获取浏览器信息
     */
    getBrowserInfo(param) {
        const browser = getParser(window.navigator.userAgent);
        const result = browser.getResult();
        const browserInfo = {};
        browserInfo.browserName = result.browser.name;
        browserInfo.browserVersion = result.browser.version;
        browserInfo.browserEngine = result.engine.name;
        browserInfo.platform = "pc"; // result.platform.type;
        browserInfo.osName = result.os.name;
        browserInfo.osVersion = result.os.versionName;
        browserInfo.osDpi = `${window.screen.width}x${window.screen.height}`;

        // lzb 新增版本号 2020-08-20
        browserInfo.systemVersion = `PC管理端(${packageJson.version})`;
        param = Object.assign(param, browserInfo);
        return param;
    },
    /**
     *
     * @returns 浏览器分辨率的宽度
     */
    getBrowserScreenWidth() {
        return window.screen.width;
    },
};

/**
 * 是否是谷歌内核
 */
export const isChromeUserAgent = () => {
    const userAgent = window.navigator.userAgent;
    if (userAgent.indexOf("Chrome") !== -1) {
        return true;
    }
    return false;
};
/**
 * 是否是谷歌内核
 */
export const getChromeUserAgentVersion = () => {
    const isChrome = isChromeUserAgent();
    if (isChrome) {
        const userAgent = window.navigator.userAgent;
        const chromeVersion = userAgent.match(/Chrome\/(\d+\.\d+\.\d+\.\d+)/);
        const version = chromeVersion && chromeVersion.length > 0 ? chromeVersion[1] : null;
        const versionNumber = version && version.length > 0 ? version.split(".")[0] : "";
        return versionNumber;
    }
    return null;
};
