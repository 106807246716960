import GlobalHelper from "../helper/GlobalHelper";

/**
 * 常量API接口
 */
export default {
    /**
     *  设备导入模板链接
     */
    getImportCarTemplateLink() {
        return `${GlobalHelper.getTemplateDomainName()}/import/template/car.xls`;
    },
    /**
     *  营业执照图片
     */
    getImportCertBusinessLicense() {
        return `${GlobalHelper.getTemplateDomainName()}/import/template/cert/business_license.png`;
    },
    /**
     *  公函 -png
     */
    getImportCertOfficialOPng() {
        return `${GlobalHelper.getTemplateDomainName()}/import/template/cert/official_document.png`;
    },
    /**
     *  公函 -doc
     */
    getImportCertOfficialDoc() {
        return `${GlobalHelper.getTemplateDomainName()}/import/template/cert/official_document.doc`;
    },

    /**
     *  获取签约模板
     */
    getFinancePayModeSignTemplateLink() {
        return GlobalHelper.getTemplateDomainName() + this.getFinancePayModeSignTemplateLinkPath();
    },
    /**
     *  获取签约模板
     */
    getFinancePayModeSignTemplateLinkPath() {
        return "/file/finance/%E4%BB%98%E8%B4%B9%E7%AD%BE%E7%BA%A6%E5%90%88%E5%90%8C%E6%A8%A1%E6%9D%BF.pdf";
    },

    /**
     * 课件导入模板链接
     */
    getImportCourseTemplateLink() {
        return "";
    },

    /**
     * 介绍人介绍链接
     */
    getIntroducerLink() {
        return process.env.VUE_APP_WEB_URL + "/pc/introducer/extension";
    },

    /**
     * 获取备案地址
     */
    getBeiAnLink() {
        return "http://beian.miit.gov.cn/";
    },
    /**
     * 获取阿里云域名
     */
    getAliOssDomain() {
        return GlobalHelper.getTemplateDomainName();
    },

    /**
     * @description: 获取cdn的域名
     * @return {*}
     */
    getCDNDomain() {
        return "https://obs.tbatb.net";
    },
    /**
     * @description: 获取cdn资源的功能路径
     * @return {*}
     */
    getCommonCDNPath() {
        return this.getCDNDomain() + "/common/image";
    },
};
