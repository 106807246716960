// 打印类属性、方法定义
/* eslint-disable */
import printHttp from "../api/system-manager/print-manager/printHttp";
import DateUtil from "../utils/DateUtil";
import WaterMark from "./WaterMark";

/**
 * 使用方法
 *
 *  // 开始打印
 that.$print(this.$refs.print, {title: "企业报告", data:{}});
 orgId: this.options.data.orgId,
 orgName: this.options.data.orgName,
 operationUserName: this.options.data.operationUserName,
 operationUserId: this.options.data.operationUserId,
 supportWaterMask: false,
 waterTitle: "",
 */
const Print = function (dom, options) {
    if (!(this instanceof Print)) return new Print(dom, options);

    this.options = this.extend(
        {
            noPrint: ".no-print",
            data: options.data ? options.data : {},
            title: options.title ? options.title : "",
        },
        options
    );

    if (typeof dom === "string") {
        this.dom = document.querySelector(dom);
    } else {
        this.isDOM(dom);
        // 将要打印的内容
        this.dom = this.isDOM(dom) ? dom : dom.$el;
    }
    this.init(options);
};
Print.prototype = {
    init: function (options) {
        // console.log("print init this.dom", this.dom);
        // console.log("print init options", options);
        const content = this.getHtml() + this.getStyle();
        // console.log("print init content", content);
        this.writeIframe(content);
    },
    extend: function (obj, obj2) {
        for (var k in obj2) {
            obj[k] = obj2[k];
        }
        return obj;
    },

    getStyle: function () {
        var str = "",
            styles = document.querySelectorAll("style,link");
        for (var i = 0; i < styles.length; i++) {
            str += styles[i].outerHTML;
        }

        str += "<style>" + (this.options.noPrint ? this.options.noPrint : ".no-print") + "{display:none;}</style>";

        str += "<style>" + ".no-print" + "{display:none;}</style>";
        return str;
    },

    getHtml: function () {
        var inputs = document.querySelectorAll("input");
        var textareas = document.querySelectorAll("textarea");
        var selects = document.querySelectorAll("select");
        // 图片控件处理20191011-lzb
        const imgElements = document.querySelectorAll("img");

        for (var k = 0; k < inputs.length; k++) {
            if (inputs[k].type == "checkbox" || inputs[k].type == "radio") {
                if (inputs[k].checked == true) {
                    inputs[k].setAttribute("checked", "checked");
                } else {
                    inputs[k].removeAttribute("checked");
                }
            } else if (inputs[k].type == "text") {
                inputs[k].setAttribute("value", inputs[k].value);
            } else {
                inputs[k].setAttribute("value", inputs[k].value);
            }
        }

        for (var k2 = 0; k2 < textareas.length; k2++) {
            if (textareas[k2].type == "textarea") {
                textareas[k2].innerHTML = textareas[k2].value;
            }
        }

        for (var k3 = 0; k3 < selects.length; k3++) {
            if (selects[k3].type == "select-one") {
                var child = selects[k3].children;
                for (var i in child) {
                    if (child[i].tagName == "OPTION") {
                        if (child[i].selected == true) {
                            child[i].setAttribute("selected", "selected");
                        } else {
                            child[i].removeAttribute("selected");
                        }
                    }
                }
            }
        }
        // 图片控件处理20191011-lzb
        for (let k4 = 0; k4 < imgElements.length; k4++) {
            if (imgElements[k4].tagName == "IMG") {
                imgElements[k4].setAttribute("src", imgElements[k4].src);
            }
        }
        // 表格控件处理20200420-lzb - 处理操作列表忍让在表格中
        const thElements = document.querySelectorAll("th");
        for (let k5 = 0; k5 < thElements.length; k5++) {
            if (thElements[k5].tagName == "TH" && thElements[k5].textContent == "操作") {
                thElements[k5].setAttribute("class", "no-print");
            }
        }
        // 表格控件处理20200706-lzb - 处理操作列表忍让在表格中
        const tdElements = document.querySelectorAll("td");
        for (let k6 = 0; k6 < tdElements.length; k6++) {
            const tbChildNodes = tdElements[k6].childNodes;
            if (tbChildNodes) {
                tbChildNodes.forEach((node) => {
                    if (node.tagName === "DIV" && node.innerText == "详情") {
                        node.setAttribute("class", "no-print");
                    }
                });
            }
        }

        /*  let arr = document.getElementsByTagName("tr");
          let heightNum = 0;
          let onePage = 600;
          let pagNum = 0;
          for (let i in arr) {
              heightNum += arr[i].offsetHeight;
              if (heightNum > onePage) {
                  pagNum += 1;
                  var a = document.createElement("pageNum");
                  a.innerHTML= `<p>第${pagNum}页</p>`;
                  var footer = document.getElementById("page-footer-space");
                  footer.innerHTML=`<p>第${pagNum}页</p>`;

                  heightNum = arr[i].offsetHeight;
                  onePage = 800;
              }

          }*/

        return this.dom.outerHTML;
    },

    writeIframe: function (content) {
        // 创建一个iframe
        var w,
            doc,
            iframe = document.createElement("iframe"),
            f = document.body.appendChild(iframe);
        iframe.id = "myIframe";
        const width = this.dom.clientWidth;
        const height = this.dom.clientHeight;
        // // console.log('打印的是dom =', this.dom);
        iframe.setAttribute(
            "style",
            `
        position:absolute;
        padding:10px;
        width: ${width}px;
        maxWidth:${width}px;
        height:${height}px;
        top: -${height}px;
        left:-${width}px;`
        );

        // iframe.setAttribute('style', `
        // position:absolute;
        // padding:10px;
        // background-color: blue;
        // width: ${width}px;
        // maxWidth:${width}px;
        // height:${height}px;
        // top: 10px;
        // left:10px;`);

        w = f.contentWindow || f.contentDocument;

        // 把打印内容写入到iframe中
        doc = f.contentDocument || f.contentWindow.document;
        doc.open();
        doc.write(content);
        // console.log('content:', content);

        // lzb 2020-02-20 设置A4纸大小打印
        doc.body.setAttribute("style", `width:210mm !important; margin: 0 auto;`);

        // 设置水印
        if (this.options.data && this.options.data.supportWaterMask) {
            const title = this.options.data.waterTitle ? this.options.data.waterTitle : "";
            if (title && title.length > 0) {
                WaterMark.addTextWaterMarkToDom(doc.body, title);
            }
        }

        const sectionNodes = doc.getElementsByTagName("section");
        if (sectionNodes && sectionNodes.length > 0) {
            const section = sectionNodes[0];
            section.setAttribute("style", `padding: 0px 10px;`);
        }

        doc.close();
        const _this = this;
        // console.log('content -length= ', content.length);
        const iframeStart = new Date().getTime();
        // console.log('content iframe= ', iframe);
        // 加载事件,当资源已加载时被触发。
        iframe.onload = function () {
            const iframeEnd = new Date().getTime();
            // console.log('iframe执行时间-----', iframeEnd - iframeStart, 'ms');
            const start = new Date().getTime();

            _this.toPrint(w);
            const end = new Date().getTime();
            // console.log('打印执行时间-----', end - start, 'ms');
            setTimeout(function () {
                document.body.removeChild(iframe);
            }, 100);
        };
    },

    toPrint: function (frameWindow) {
        // 新增打印日志
        this.addPrintLogs();

        const that = this;
        try {
            setTimeout(function () {
                // frameWindow.focus();

                // that.PageSetup_Null();
                // that.PageSetup_Default();
                try {
                    if (!frameWindow.document.execCommand("print", false, null)) {
                        frameWindow.print();
                    }
                } catch (e) {
                    frameWindow.print();
                }
                frameWindow.close();
            }, 10);
        } catch (err) {
            console.log("err", err);
        }
    },
    /**
     * 新增打印日志
     */
    addPrintLogs() {
        printHttp.addPrintLog({
            orgId: this.options.data ? this.options.data.orgId : "",
            orgName: this.options.data ? this.options.data.orgName : "",
            operationUserName: this.options.data ? this.options.data.operationUserName : "",
            operationUserId: this.options.data ? this.options.data.operationUserId : "",
            operationModule: this.options.data ? this.options.title : "",
            operationType: 1,
            operationTime: DateUtil.getCurrentTimeStr(),
            batchId: this.options.data ? this.options.data.batchId : "",
            certName: this.options.data ? this.options.data.certName : "", // v1.0.25证书
            certCode: this.options.data ? this.options.data.certNumber : "", // v1.0.25证书编码
        });
    },

    isDOM:
        typeof HTMLElement === "object"
            ? function (obj) {
                  return obj instanceof HTMLElement;
              }
            : function (obj) {
                  return obj && typeof obj === "object" && obj.nodeType === 1 && typeof obj.nodeName === "string";
              },
};
const MyPlugin = {};
MyPlugin.install = function (Vue, options) {
    // 4. 添加实例方法
    Vue.prototype.$print = Print;
};
export default MyPlugin;
