import store from "@/store";

export default {
    /**
     * 获取保存的tagsViews
     */
    getTagsVisitedView() {
        if (store.state.tagsView) {
            return store.state.tagsView.visitedViews;
        } else {
            return [];
        }
    },

    /**
     * 清楚tag选择的tag
     */
    removeAllTagView() {
        store.dispatch("tagsView/delAllVisitedViews");
        store.dispatch("tagsView/delAllCachedViews");
    },
};
