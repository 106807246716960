/**
 * 全局处理方法
 */
import DeviceHelper from "./DeviceHelper";
import AnnouncementApi from "@/api/common/announcement/announcementApi";
import { HudGlobal } from "../HudGlobal";
import { RouterConstants } from "@utils/constants/RouterConstants";
import StoreTempDB from "../db/StoreTempDB";
import EnumConstants from "../constants/EnumConstants";
import { getURLLastName } from "./URLHelper.js";

export default {
    /**
     * 打开新的网页
     */
    openWithUrl(url, msg = null) {
        if (url == null || url.length == 0) {
            return;
        }
        if (DeviceHelper.getBrowserType() == "Safari") {
            const message = msg || "您新打开的窗口可能被阻止，请允许安全性验证偏好设置-->安全性-->允许";
            HudGlobal.showAlertConfirmMessage(message).then(() => {
                const newPage = window.open("newPage", "_blank");
                AnnouncementApi.getOne({})
                    .then(() => {
                        newPage.location = url;
                    })
                    .catch(() => {
                        newPage.location = url;
                    });
            });
        } else {
            // const message = msg || '您确定需要打开一个新的窗口吗？';
            // HudGlobal.showAlertConfirmMessage(message).then(() => {
            //     window.open(url, "_blank").location;
            // });
            this.gotoWithURL(url);
        }
    },
    /**
     * 单纯跳转url - 没有弹框确定
     */
    gotoWithURL(url) {
        window.open(url, "_blank");
    },

    /**
     * 跳转统一的协议外链 lzb 2021-08-03 新增  idCode 是新闻id
     */
    gotoProtocolExternalLink(idCode) {
        const url = `${window.location.origin}${RouterConstants.EXTERNAL_LINE.WEB_PROTOCOL}?type=${idCode}`;
        this.gotoWithURL(url);
    },

    /**
     * 下载文件
     */
    downFileWithURL(url, fileName) {
        fetch(url, {
            method: "get",
            responseType: "blob",
        })
            .then((res) => {
                return res.blob();
            })
            .then((blob) => {
                if ("msSaveOrOpenBlob" in navigator) {
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    const downloadUrl = URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = downloadUrl;
                    if (!fileName) {
                        a.download = filerName(url);
                    } else {
                        a.download = fileName;
                    }
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(downloadUrl);
                }
            });
        // document.body.removeChild(eleLink);
        function filerName(urlFile) {
            // 提取下载的文件名
            const urlArray = urlFile.split("/");
            const index = urlArray.length - 1;
            const result = urlArray[index];
            return decodeURI(result);
        }
    },

    /**
     * 判断是否是对象
     */
    isObject(obj) {
        const result = Object.prototype.toString.call(obj) === "[object Object]";
        return result;
    },

    /**
     * 关闭窗口
     */
    closeWindowEvent() {
        const userAgent = navigator.userAgent;
        if (userAgent.indexOf("Firefox") != -1 || userAgent.indexOf("Chrome") != -1) {
            window.opener = null;
            window.open(" ", "_self").location;
            window.location.href = "about:blank";
            window.close();
        } else {
            window.opener = null;
            window.open(" ", "_self").location;
            window.location.href = "about:blank";
            window.close();
        }
    },

    /**
     * 修改网站的标题 lzb 2021-07-29
     */
    modifyWindowTitle(title) {
        if (title && title.length > 0) {
            document.title = title;
        }
    },
    /**
     * 修改网站的图标 lzb 2021-07-29
     */
    modifyWindowIcon(iconUrl) {
        if (iconUrl && iconUrl.length > 0) {
            const link = document.querySelector("link[rel*='icon']");
            link.href = iconUrl; // icon图标
        }
    },

    /**
     * 当前环境是否是预发布或者正式环境
     */
    getCurrentPrePropProductENV() {
        const routeMode = process.env.VUE_APP_ROUTE_MODE;
        const IS_PROD = routeMode == "history";
        return IS_PROD;
    },
    /**
     * @description: 获取首帧path
     * @return {*}
     */

    getVideoFirstFrameImgPath() {
        const aliSnapshot = "?x-oss-process=video/snapshot,t_5000,m_fast";
        const huaweiSnapshot = "";
        const uploadConfig = StoreTempDB.getUploadConfig();
        let platform = window.$TBUpload.uploadPlatform;
        if (window.$TBUpload.uploadPlatform == EnumConstants.UPLOAD_PLATFORM.ALI) {
            // 阿里
            platform = EnumConstants.UPLOAD_PLATFORM.ALI;
        } else if (window.$TBUpload.upload === EnumConstants.UPLOAD_PLATFORM.HUAWEI) {
            // 华为
            platform = EnumConstants.UPLOAD_PLATFORM.HUAWEI;
        } else {
            // 替比
            platform = uploadConfig.ossType || EnumConstants.UPLOAD_PLATFORM.HUAWEI;
        }

        if (platform == EnumConstants.UPLOAD_PLATFORM.ALI) {
            return aliSnapshot;
        } else if (platform == EnumConstants.UPLOAD_PLATFORM.HUAWEI) {
            return huaweiSnapshot;
        } else {
            return aliSnapshot;
        }
    },
    /**
     * @description: 获取模版的域名
     * @return {*}
     */

    getTemplateDomainName() {
        const uploadConfig = StoreTempDB.getUploadConfig();
        let platform = window.$TBUpload.uploadPlatform;
        if (window.$TBUpload.uploadPlatform == EnumConstants.UPLOAD_PLATFORM.ALI) {
            // 阿里
            platform = EnumConstants.UPLOAD_PLATFORM.ALI;
        } else if (window.$TBUpload.upload === EnumConstants.UPLOAD_PLATFORM.HUAWEI) {
            // 华为
            platform = EnumConstants.UPLOAD_PLATFORM.HUAWEI;
        } else {
            // 替比
            platform = uploadConfig.ossType || EnumConstants.UPLOAD_PLATFORM.HUAWEI;
        }

        const aliBucket = process.env.VUE_APP_ALIOSS_BUCKET;
        const aliRegion = process.env.VUE_APP_ALIOSS_REGION;
        const hwBucket = process.env.VUE_APP_HUAWEI_BUCKET;

        const endPoint = uploadConfig ? uploadConfig.endPoint : "";

        if (platform == EnumConstants.UPLOAD_PLATFORM.ALI) {
            // 阿里
            const aliDomainName = endPoint || `https://${aliBucket}.${aliRegion}.aliyuncs.com`;
            return aliDomainName;
        } else if (platform == EnumConstants.UPLOAD_PLATFORM.HUAWEI) {
            // 华为
            const huaweiDomainName = `https://${hwBucket}.${endPoint}`;
            return huaweiDomainName;
        } else {
            const aliDomainName = endPoint || `https://${aliBucket}.${aliRegion}.aliyuncs.com`;
            return aliDomainName;
        }
    },

    /**
     * 文件下载
     * @param {*} url 路径的url
     * @param {*} filename  文件名称
     */
    downloadFile(url, filename) {
        filename = filename || this.getFileNameFromUrl(url) || "";
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    },
    /**
     * 获取文件的url
     */
    getFileNameFromUrl(url) {
        try {
            const urlObj = new URL(url);
            return urlObj.pathname.split("/").pop() || null;
        } catch (error) {
            console.error("Invalid URL");
            return null;
        }
    },
    /**
     * 下载文件
     */
    downFileByHref(url) {
        if (!url || url.length === 0) {
            console.log("downFileByHref的url不能是null", url);
            return false;
        }
        // 创建一个虚拟的a元素
        let link = document.createElement("a");

        // 设置a元素的href属性为图片URL
        link.href = url;

        // 设置a元素的download属性为要保存的文件名
        link.download = getURLLastName(url);
        // 将a元素添加到DOM中
        document.body.appendChild(link);

        // 模拟用户点击链接，触发下载
        link.click();

        // 从DOM中移除a元素
        document.body.removeChild(link);
    },
};

/**
 * 打开新窗口
 */
export const previewByNewWindow = (url) => {
    if (window.navigator.userAgent.indexOf("Chrome") != -1 && !window.chrome) {
        const opts = {
            message: "您浏览器阻止您的预览或下载,是否继续",
            showClose: true,
        };
        HudGlobal.showOneButtonAlertMessage(opts).then((res) => {
            window.open(url, "_blank");
        });
    } else {
        window.open(url, "_blank");
    }
};
/**
 * 打开新窗口
 */
export const previewPdfNewWindow = (url) => {
    const opts = {
        message: "您的文档已经生成完成，需要马上预览吗？",
        showClose: true,
    };
    HudGlobal.showOneButtonAlertMessage(opts).then((res) => {
        window.open(url, "_blank");
    });
};
