import http from "@tibi/http";
import { HudGlobal } from "../HudGlobal";
import router from "../../router.js";
import { decryptDataByAES, encryptDataByAES } from "../helper/CryptoHelper";

const ssoUrl = process.env.VUE_APP_API_SSO_URL;

export default {
    /**
     *  登录请求
     */
    login(param) {
        return this.sendPostRequest(`${ssoUrl}/login`, param, { isEncrypt: true });
    },
    /**
     * 通过cookie的id获取token lzb 20230912
     */
    getTokenById(param) {
        return this.sendGetRequest(`${ssoUrl}/api/token/id`, param);
    },
    /**
     *  刷新token lzb 20231103
     */
    refreshToken(param) {
        return this.sendPostRequest(`${ssoUrl}/token/refresh`, param);
    },
    /**
     *  其他项目跳转后自动登录
     */
    transferAvoid(param) {
        return this.sendPostRequest(`${ssoUrl}/login/transfer/avoid`, param, { isEncrypt: true });
        // return http.post(`${ssoUrl}/login/transfer/avoid`, param);
    },
    /**
     *  跳转到安途帮
     */
    transferAvoidNopass(param) {
        return this.sendPostRequest(`${ssoUrl}/login/transfer/avoid/nopass`, param, { isEncrypt: true });
        // return http.post(`${ssoUrl}/login/transfer/avoid/nopass`, param);
    },
    /**
     *  预登录请求
     */
    preLogin(param) {
        return this.sendGetRequest(`${ssoUrl}/api/offline/pre/login`, param, { isEncrypt: true });
        // return http.get(`${ssoUrl}/api/offline/pre/login`, param);
    },
    /**
     *  模拟登录请求
     */
    runAs(param) {
        return this.sendPostRequest(`${ssoUrl}/runAs`, param, { isEncrypt: true });
        // return http.post(`${ssoUrl}/runAs`, param);
    },
    /**
     *  退出登录
     */
    logout(param) {
        return this.sendPostRequest(`${ssoUrl}/logout`, param, { isEncrypt: true });
        // return http.post(`${ssoUrl}/logout`, param);
    },
    /**
     *  模拟登录退出
     */
    runRelease(param) {
        return this.sendPostRequest(`${ssoUrl}/runRelease`, param, { isEncrypt: true });
        // return http.post(`${ssoUrl}/runRelease`, param);
    },
    /**
     * 切换登录
     */
    changeLogin(param) {
        return this.sendPostRequest(`${ssoUrl}/login/nopwd`, param, { isEncrypt: true });
        // return http.post(`${ssoUrl}/login/nopwd`, param);
    },

    /**
     * @description: 发送post请求
     * @param {*} url
     * @param {*} param
     * @return {*}
     */
    sendPostRequest(url, param, options) {
        const isEncrypt = this.isAPIEncrypt(options);
        const newParam = this.decorateRequestParam(param, options);
        const newOptions = this.decorateRequestOptions(options);

        return new Promise((resolve, reject) => {
            http.post(url, newParam, newOptions)
                .then((res) => {
                    this.handleRequestSuccessResponse(resolve, res, isEncrypt);
                })
                .catch((error) => {
                    this.handleRequestFailResponse(reject, error);
                });
        });
    },
    /**
     * @description: 发送get请求
     * @param {*} url
     * @param {*} param
     * @return {*}
     */
    sendGetRequest(url, param, options) {
        const isEncrypt = this.isAPIEncrypt(options);
        const newParam = this.decorateRequestParam(param, options);
        const newOptions = this.decorateRequestOptions(options);

        return new Promise((resolve, reject) => {
            http.get(url, newParam, newOptions)
                .then((res) => {
                    this.handleRequestSuccessResponse(resolve, res, isEncrypt);
                })
                .catch((error) => {
                    this.handleRequestFailResponse(reject, error);
                });
        });
    },
    /**
     * 装饰请求参数
     */
    decorateRequestParam(param, options) {
        const isEncrypt = this.isAPIEncrypt(options);
        const sourceParam = {};
        Object.assign(sourceParam, param);
        // 定义新增请求参数
        let newParam = sourceParam;
        if (isEncrypt) {
            const str = encryptDataByAES(sourceParam);
            newParam = {
                data: str,
            };
            return newParam;
        } else {
            return param;
        }
    },
    /**
     * 装饰请求的options
     */
    decorateRequestOptions(options) {
        const isEncrypt = this.isAPIEncrypt(options);
        const newOptions = {
            headers: {
                platform: "pc-admin",
                "x-project": "tbatb-admin-web", // 项目
            },
        };
        // 加密 默认加密 使用A加密方式 传1  使用A加密方式 传-1 不加密
        if (!isEncrypt) {
            newOptions.headers["Encrypt-Mode"] = -1;
        }
        Object.assign(newOptions, options);
        return newOptions;
    },
    /**
     * 是否API加密
     */
    isAPIEncrypt(options) {
        if (process.env.NODE_ENV == "production") {
            return options && options.isEncrypt && process.env.VUE_APP_OPEN_API_ENCRYPT == "true";
        } else {
            return localStorage.getItem("TBEncryptAPI") == 1 || (options && options.isEncrypt && process.env.VUE_APP_OPEN_API_ENCRYPT == "true");
        }
    },

    // 处置成功
    handleRequestSuccessResponse(resolve, response, isEncrypt) {
        // 服务器重启- lzb 20230525
        if (response.code == 6) {
            HudGlobal.showWarningWithMessage("当前系统正在升级，请稍后重试！恢复正常后可继续当前操作！", 5000);
            return;
        }

        const encryptMode = response.resHeaders["encrypt-mode"];
        // A. 无encryptMode 字段   默认使用A解密  B. 1  使用A解密 C -1  不解密
        if (encryptMode == 1) {
            response.data = decryptDataByAES(response.data);
        }
        resolve && resolve(response);
    },

    // 处理失败
    handleRequestFailResponse(reject, error) {
        // 404 处理
        if (error.hasOwnProperty("code") && error.code == 404) {
            router.push("/404");
            return;
        }
        // 500处理
        if (error.hasOwnProperty("code") && error.code == 500) {
            router.push("/500");
            return;
        }
        // 请求取消
        if (error.hasOwnProperty("code") && error.code == 3) {
            console.warn("重复请求被取消");
            return;
        }
        // 网络离线 - lzb 20230525
        if (error.statusCode === 700 && error.code == 8100) {
            HudGlobal.showWarningWithMessage(error.description, 5000);
            return;
        }
        // 服务器断开- lzb 20230525
        if (error.statusCode >= 400 && error.statusCode < 600) {
            HudGlobal.showWarningWithMessage("当前系统正在升级，请稍后重试！恢复正常后可继续当前操作！", 5000);
            return;
        }

        reject && reject(error);
    },
};
