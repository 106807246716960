var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "load-fail-wrapper" }, [
    _c(
      "div",
      { staticClass: "load-fail-content" },
      [
        _c("el-image", { attrs: { src: _vm.getLoadFailImage } }),
        _c("div", { staticClass: "title-wrap" }, [_vm._v(_vm._s(_vm.title))]),
        _vm.isHasOperation
          ? _c(
              "div",
              { staticClass: "button-group" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onClickButtonEvent.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.operationTitle))]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }