import { Constants } from "@utils/constants/Constants";
import TBUserManger from "@utils/user/TBUserManger";

export default {
    /**
     * 是否有权限
     * */
    isHaveJurisdiction(path) {
        return true;
        // return this.checkHasPermissionRoute(path);
    },

    /**
     * 20210621  判断用户是否有这个权限的跳转路径
     * router 传入权限字符串
     * */
    checkHasPermissionRoute(routerString) {
        // 1. 检测
        if (routerString == null || routerString.length == 0) {
            return false;
        }

        // 类型1
        if (routerString.indexOf("?") != -1) {
            // 存在
            const list = routerString.split("?");
            routerString = list[0];
        }
        // 类型2 /&
        if (routerString.indexOf("/$") != -1) {
            // 存在
            const list = routerString.split("/$");
            routerString = list[0];
        }

        // 类型3 外链带#
        if (routerString.indexOf("#") != -1) {
            // 存在
            const string = routerString.replace("#", ""); // 删除#号， 跳外链可能会出现#号
            routerString = string;
        }

        // 2. 获取登录路由数据
        const menuDtoList = TBUserManger.getMenuList();
        let isHalfPath = false; // 是否是半路径
        let halfPath = routerString; // 是否是半路径
        if (routerString.indexOf("./") != -1) {
            // 存在
            isHalfPath = true;
            const tempList = routerString.split("/");
            halfPath = tempList[tempList.length - 1];
        }

        if (menuDtoList && menuDtoList.length > 0) {
            for (let i = 0; i < menuDtoList.length; i++) {
                const item = menuDtoList[i];
                if (item) {
                    // 全路径相同   半路径在全路径里存在
                    if (item.path == routerString || (isHalfPath && item.path.indexOf(halfPath) != -1)) {
                        //  半路径
                        return true;
                    }
                    const children = item.children;
                    const isHave = this.recursionFindList(children, routerString, isHalfPath, halfPath);
                    if (isHave) {
                        return isHave;
                    }
                }
            }
        }

        // 4. 获取静态路由数据
        const routerWhiteList = Constants.routerWhiteList;
        // 5. 遍历比较
        for (let i = 0; i < routerWhiteList.length; i++) {
            const routerStr = routerWhiteList[i];
            if (routerStr == routerString) {
                return true;
            }
        }
        // 6. 默认返回false
        return false;
    },
    /**
     * 通过code 递归寻找匹配的item
     */
    recursionFindList(dataList, routerString, isHalfPath, halfPath) {
        if (dataList && dataList.length > 0) {
            for (let m = 0; m < dataList.length; m++) {
                const item1 = dataList[m];
                if (item1) {
                    if (item1.path == routerString || (isHalfPath && item1.path.indexOf(halfPath) != -1)) {
                        //  半路径
                        return true;
                    } else {
                        const children2 = item1.children;
                        this.recursionFindList(children2, routerString, isHalfPath, halfPath);
                    }
                }
            }
        } else {
            return false;
        }
    },
    /**
     * 根据路径获取title
     * */
    getRouterTitle(routerString) {
        // 类型1
        if (routerString.indexOf("?") != -1) {
            // 存在
            const list = routerString.split("?");
            routerString = list[0];
        }
        // 类型2 /&
        if (routerString.indexOf("/$") != -1) {
            // 存在
            const list = routerString.split("/$");
            routerString = list[0];
        }

        // 2. 获取登录路由数据
        const menuDtoList = TBUserManger.getMenuList();
        let isHalfPath = false; // 是否是半路径
        let halfPath = routerString; // 是否是半路径
        if (routerString.indexOf("./") != -1) {
            // 存在
            isHalfPath = true;
            const tempList = routerString.split("/");
            halfPath = tempList[tempList.length - 1];
        }
        if (menuDtoList && menuDtoList.length > 0) {
            for (let i = 0; i < menuDtoList.length; i++) {
                const item = menuDtoList[i];
                if (item) {
                    // 全路径相同   半路径在全路径里存在
                    if (item.path == routerString || (isHalfPath && item.path.indexOf(halfPath) != -1)) {
                        //  半路径
                        return item.meta.title;
                    }
                    const children = item.children;
                    const title = this.recursionListFindTitle(children, routerString, isHalfPath, halfPath);
                    if (title) {
                        return title;
                    }
                }
            }
        }
        return "无权限";
    },
    /**
     * 通过code 递归寻找匹配的item 的title值
     */
    recursionListFindTitle(dataList, routerString, isHalfPath, halfPath) {
        if (dataList && dataList.length > 0) {
            for (let m = 0; m < dataList.length; m++) {
                const item1 = dataList[m];
                if (item1) {
                    if (item1.path == routerString || (isHalfPath && item1.path.indexOf(halfPath) != -1)) {
                        //  半路径
                        return item1.meta.title;
                    } else {
                        const children2 = item1.children;
                        this.recursionFindList(children2, routerString, isHalfPath, halfPath);
                    }
                }
            }
        } else {
            return "无权限";
        }
    },
};
