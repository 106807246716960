import { provincesNameList } from "../constants/EnumConstants";

/**
 * 字符串处理
 */
export default {
    /**
     * 计算字符串长度
     */
    computeStrLength(str) {
        if (!str || str.length == 0) {
            return 0;
        } else {
            return str.length;
        }
    },
    /**
     * 处理...
     */
    getGetByteLen(str, len) {
        if (this.computedByteLen(str) <= len) {
            return str;
        }
        for (let i = Math.floor(len / 2); i < str.length; i++) {
            // eslint-disable-next-line no-control-regex
            if (str.substr(0, i).replace(/[^\x00-\xff]/g, "01").length >= len) {
                return `${str.substr(0, Math.floor(i / 2) * 2)}...`;
            }
        }
    },
    /**
     * 计算字节个数
     */
    computedByteLen(str) {
        if (!str) {
            return 0;
        }
        // eslint-disable-next-line no-control-regex
        return str.replace(/[^\x00-\xff]/g, "01").length;
    },
    /**
     * 是否是字符串
     */
    isString(str) {
        return Object.prototype.toString.call(str) == "[object String]";
    },
    /**
     * 字符串转数组
     */
    toArray(str, join = ",") {
        if (!str || str.length == 0) return [];
        const list = str.split(",");
        return list || [];
    },
    extractAddressComponents(address) {
        let provinceName, cityName, countyName;
        if (!address) {
            return {
                provinceName,
                cityName,
                countyName,
            };
        }
        // 识别省市区的正则
        let reg =
            "(?<province>[^省]+省|.*?省|.+自治区|[^澳门]+澳门|北京|重庆|上海|天津|台湾|[^香港]+香港|[^市]+市)?(?<city>[^自治州]+自治州|[^特别行政区]+特别行政区|[^市]+市|.*?地区|.*?行政单位|.+盟|市辖区|[^县]+县)(?<county>[^县]+县|[^市]+市|[^镇]+镇|[^区]+区|[^乡]+乡|.+场|.+旗|.+海域|.+岛)?(?<address>.*)";
        

        const match = address.match(reg);
        console.log("extractAddressComponents", address.match(reg));

        if (match != null) {
            const groups = match.groups;
            if (groups) {
                provinceName = groups.province || "";
                cityName = groups.city || "";
                countyName = groups.county || "";
            }
        } else {
            // 匹配省
            provinceName = provincesNameList.find((p) => address.includes(p));
            const cityExp = ".+[市]",
                disExp = ".+[区]";
            // 匹配市
            const city = address.match(new RegExp(cityExp));
            if (city && city.length > 0) {
                cityName = city[0].replace(provinceName, "");
            }

            // 匹配区
            const district = address.match(new RegExp(disExp));
            if (district && district.length > 0) {
                countyName = district[0].replace(provinceName, "").replace(cityName, "");
            }
        }
        // 4大直辖市特殊处理
        if (!!cityName && !provinceName) {
            const municipality = ["北京市", "上海市", "天津市"];
            provinceName = cityName;
            if (municipality.includes(cityName)) {
                cityName = "直辖区";
            } else if (cityName == "重庆市") {
                const municipalityCounty = [
                    "城口县",
                    "丰都县",
                    "垫江县",
                    "忠县",
                    "云阳县",
                    "奉节县",
                    "巫山县",
                    "巫溪县",
                    "石柱土家族自治县",
                    "秀山土家族苗族自治县",
                    "酉阳土家族苗族自治县",
                    "彭水苗族土家族自治县",
                ];
                if (municipalityCounty.includes(countyName)) {
                    cityName = "直辖县";
                } else {
                    cityName = "直辖区";
                }
            }
        }
        return {
            provinceName,
            cityName,
            countyName,
        };
    },
};
