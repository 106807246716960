import Vue from "vue";
import examUtil from "@views/exam-manager/ExamUtil/examUtil.js";

/**
 * 行业
 */
Vue.filter("formatterExamIndustryNameListStr", function (val) {
    return examUtil.formatterExamIndustryNameListStr(val);
});

/**
 * 类别
 */
Vue.filter("formatterExamSubjectNameListStr", function (val) {
    return examUtil.formatterExamSubjectNameListStr(val);
});

/**
 * 岗位
 */
Vue.filter("formatterExamDutyNameListStr", function (val) {
    return examUtil.formatterExamDutyNameListStr(val);
});

/**
 * 课件
 */
Vue.filter("formatterExamCoursesListStr", function (val) {
    return examUtil.formatterExamCoursesListStr(val);
});
/**
 * 标签
 */
Vue.filter("formatterExamSysTagListStr", function (val) {
    return examUtil.formatterExamSysTagListStr(val);
});
