/**
 * 路由加载
 */
export default {
    /**
     * 格式化路由的资源组件
     */
    formatterRouteComponent(data) {
        const newData = [];
        for (let i = 0; i < data.length; i++) {
            const routerArrElement = data[i];

            if (routerArrElement.component) {
                const path = routerArrElement.component;
                routerArrElement.component = this.__lazyLoad(path);
            }
            if (routerArrElement.children) {
                routerArrElement.children = this.formatterRouteComponent(routerArrElement.children);
            }
            newData[newData.length] = routerArrElement;
        }
        return newData;
    },
    /**
     * 组件加载
     */
    __addComponent(data) {
        data.component = this.__lazyLoad(data.component);
        return data;
    },
    /**
     * 组件加载
     */
    __lazyLoad(path) {
        const componentPath = `@/views/${path}.vue`;
        // console.log("组件路径---", componentPath);
        return () => import(`@/views/${path}.vue`);
    },
};
