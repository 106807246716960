var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("BrowserDown"),
      _vm.isRouterAlive ? _c("router-view") : _vm._e(),
      _c("ScrollTop"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }