import CryptoJS from "crypto-js";
import { isObject } from "./TypeChecker";

const generateKey = () => {
    let key = "";

    // // 通过字符的ASCII码值生成字符串
    // for (let i = 97; i <= 112; i++) {
    //     // 97对应'a'的ASCII码，112对应'p'的ASCII码
    //     key += String.fromCharCode(i);
    // }

    function getStartStr() {
        return "GLQT";
    }
    function getMiddleStr() {
        return "hC5CaJnv";
    }
    function getFooterStr() {
        return "Dgdx";
    }

    return getStartStr() + getMiddleStr() + getFooterStr();
};

const baseCryptoCode = generateKey();

const defaultConf = {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    iv: CryptoJS.enc.Utf8.parse("8TW7YhREZ4UddA3S"),
};

/**
 * base64-加密  就是先将字符串转换为utf8字符数组，再转换为base64数据
 */
export const base64EncStr = (str) => {
    const wordArray = CryptoJS.enc.Utf8.parse(str);
    const base64 = CryptoJS.enc.Base64.stringify(wordArray);
    return base64;
};
/**
 * base64-解密
 */
export const base64DecStr = (str) => {
    const parseWordArray = CryptoJS.enc.Base64.parse(str);
    const parseBase64 = CryptoJS.enc.Utf8.stringify(parseWordArray);
    return parseBase64;
};

/**
 *  AES -加密
 *  data  String | Object
 *  key  秘钥
 *  config 配置
 *  {
 *      mode: CryptoJS.mode.CBC,
        padding:CryptoJS.pad.ZeroPadding,
        iv: null,
 *  }
        openBase64:是否支持base64
 */

export const encryptDataByAES = (data, key = baseCryptoCode, config = defaultConf, openBase64 = false) => {
    if (!data || data.length == 0) {
        return data;
    }
    let aecStr = data;
    if (isObject(data)) {
        aecStr = JSON.stringify(data);
    }
    key = CryptoJS.enc.Utf8.parse(key);
    const cipherParams = CryptoJS.AES.encrypt(aecStr, key, config);
    const aesStr = cipherParams.toString();
    return openBase64 ? this.base64EncStr(aesStr) : aesStr;
};
export const testEncryptDataByAES = (data, key = baseCryptoCode, config = defaultConf, openBase64 = false) => {
    if (!data || data.length == 0) {
        return data;
    }
    let aecStr = data;
    if (isObject(data)) {
        aecStr = JSON.stringify(data);
    }
    key = CryptoJS.enc.Utf8.parse(key);
    const cipherParams = CryptoJS.AES.encrypt(aecStr, key, config);
    const aesStr = cipherParams.toString();
    return openBase64 ? this.base64EncStr(aesStr) : aesStr;
};

/**
 * AES -解密
 * data  解密的值
 * key 密钥
 *
 */
export const decryptDataByAES = (data, key = baseCryptoCode, config = defaultConf, openBase64 = false) => {
    if (!data || data.length == 0) {
        return data;
    }
    // 先通过base64解码
    openBase64 && (data = this.base64DecStr(data));

    key = CryptoJS.enc.Utf8.parse(key);

    const decryptedData = CryptoJS.AES.decrypt(data, key, config);
    const originalText = decryptedData.toString(CryptoJS.enc.Utf8);

    try {
        if (originalText && originalText.length > 0) {
            return JSON.parse(originalText);
        }
    } catch (e) {
        return originalText;
    }
};
