var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShow
    ? _c(
        "div",
        {
          staticClass: "scroll-top",
          attrs: { title: "回到顶部" },
          on: { click: _vm.scrollToTop },
        },
        [_c("div", { staticClass: "arrow" })]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }