import store from "@/store";
import { StaticRoutes } from "@utils/router/RouteSource";

export default {
    /**
     * 保存动态路由
     */
    saveDynamicRoutes(dynamicRoutes) {
        store.dispatch("permission/saveDynamicRoutes", dynamicRoutes);
    },
    /**
     * 保存所有的本地路由
     */
    saveAllRoutes(routes) {
        store.dispatch("permission/saveAllRoutes", routes);
    },

    /**
     * 移除所有的路径
     */
    removeAllRoutes() {
        store.dispatch("permission/removeAllRoute");
    },

    /**
     *  获取所有的路由
     */
    getAllRoutes() {
        if (store.state.permission) {
            return store.state.permission.routes;
        } else {
            return [];
        }
    },
    /**
     * 获取所有的静态路由
     */
    getStaticRoutes() {
        return StaticRoutes || [];
    },
    /**
     * 获取动态路由
     */
    getDynamicRoutes() {
        if (store.state.permission) {
            return store.state.permission.dynamicRoutes;
        } else {
            return [];
        }
    },
};
