<template>
    <div class="scroll-top" v-if="isShow" @click="scrollToTop" title="回到顶部">
        <div class="arrow"></div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                isShow: false,
            };
        },
        mounted() {
            window.onscroll = () => {
                const scrollTop = this.getScrollTop();
                if (scrollTop > 500) {
                    this.isShow = true;
                } else {
                    this.isShow = false;
                }
            };
        },
        methods: {
            scrollToTop() {
                if (document.body) {
                    document.body.scrollTop = 0;
                }
                if (document.documentElement) {
                    document.documentElement.scrollTop = 0;
                }
            },

            // 滚动条在Y轴上的滚动距离
            getScrollTop() {
                let scrollTop = 0;
                let bodyScrollTop = 0;
                let documentScrollTop = 0;
                if (document.body) {
                    bodyScrollTop = document.body.scrollTop;
                }
                if (document.documentElement) {
                    documentScrollTop = document.documentElement.scrollTop;
                }
                scrollTop = bodyScrollTop - documentScrollTop > 0 ? bodyScrollTop : documentScrollTop;
                return scrollTop;
            },

            // 文档的总高度
            getScrollHeight() {
                let scrollHeight = 0;
                let bodyScrollHeight = 0;
                let documentScrollHeight = 0;
                if (document.body) {
                    bodyScrollHeight = document.body.scrollHeight;
                }
                if (document.documentElement) {
                    documentScrollHeight = document.documentElement.scrollHeight;
                }
                scrollHeight = bodyScrollHeight - documentScrollHeight > 0 ? bodyScrollHeight : documentScrollHeight;
                return scrollHeight;
            },

            // 浏览器视口的高度
            getWindowHeight() {
                let windowHeight = 0;
                if (document.compatMode == "CSS1Compat") {
                    windowHeight = document.documentElement.clientHeight;
                } else {
                    windowHeight = document.body.clientHeight;
                }
                return windowHeight;
            },
        },
    };
</script>
<style lang="scss">
    .scroll-top {
        width: 40px;
        height: 40px;
        display: inline-block;
        position: fixed;
        right: 20px;
        bottom: 140px;
        background: #dddddd;
        line-height: 40px;
        text-align: center;
        opacity: 0.7;
        border-radius: 5px;
        z-index: 10;

        &:hover {
            cursor: pointer;
            color: #3d0b14;
            opacity: 1;
            .arrow {
                border-color: transparent transparent #999999 transparent;
            }
        }
        .arrow {
            width: 0;
            height: 0;
            border: 10px solid;
            border-color: transparent transparent white transparent;
            position: relative;
            margin: 0 auto;
            top: 5px;
            &:after {
                content: "";
                position: absolute;
                top: -7px;
                left: -10px;
                border: 10px solid;
                border-color: transparent transparent #dddddd transparent;
            }
        }
    }
</style>
