import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import persistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import settings from "../settings";
import { Constants } from "@utils/constants/Constants";

Vue.use(Vuex);

const SAVE_VUEX_KEY = Constants.vuexKey;

const secureLS = new SecureLS({ isCompression: false });

const modulesFiles = require.context("./modules", true, /\.js$/);
// eslint-disable-next-line no-shadow
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
    const value = modulesFiles(modulePath);
    modules[moduleName] = value.default;
    return modules;
}, {});

const persistedStateSecureLS = persistedState({
    key: SAVE_VUEX_KEY,
    storage: {
        getItem: (key) => secureLS.get(key),
        setItem: (key, value) => secureLS.set(key, value),
        removeItem: (key) => secureLS.remove(key),
    },
});

const persistedStateNormal = persistedState({
    key: SAVE_VUEX_KEY,
    storage: window.localStorage,
    // paths: modules,
});

const IS_PROD = ["production", "prod"].includes(process.env.NODE_ENV);
const persistedStateUse = settings.localStoreEncrypt && IS_PROD ? persistedStateSecureLS : persistedStateNormal;

const store = new Vuex.Store({
    modules,
    getters,
    plugins: [persistedStateUse],
});

export default store;
