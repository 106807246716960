import { Constants, StaticConstants } from "../constants/Constants";
import StoreMainManger from "@utils/db/StoreMainManger";
import orgAuthHttp from "@api/my-organize/enterprise-auth/EnterpriseAuthHttp";
import StoreUserDB from "@utils/db/StoreUserDB";
/**
 * 当前登录信息
 */
export default {
    /**
     * 当前登录者的身份
     */
    getCurrentUserIdentity() {
        const userInfo = this.getCurrentUserInfo();
        const userIdentity = userInfo.userIdentityList;
        return userIdentity;
    },

    /**
     * 获取用户id
     */
    getCurrentUserId() {
        const userInfo = this.getCurrentUserInfo();
        let userId = userInfo.userId || "";
        if (userId && userId.length > 0) {
            return userId;
        }

        userId = StoreMainManger.getUserIdFromDB();
        return userId;
    },
    /**
     * 获取用户userName
     */
    getCurrentUserName() {
        const userInfo = this.getCurrentUserInfo();
        const userName = userInfo.userName;
        return userName;
    },

    /**
     * 获取用户信息组织I
     */
    getCurrentUserOrgId() {
        const orgDto = this.getCurrentLoginOrgObj();
        const orgId = orgDto.orgId;
        return orgId;
    },
    /**
     * 获取用户信息组织I
     */
    getCurrentUserOrgState() {
        const orgDto = this.getCurrentLoginOrgObj();
        const state = orgDto.state;
        return state;
    },
    /**
     * 获取站点登录组织id lzb 2021-08-05
     */
    getSiteLoginOrgId() {
        let orgId = "";
        const templateStr = window.localStorage.getItem("templateData");
        if (templateStr && templateStr.length > 0) {
            const templateData = JSON.parse(templateStr);
            orgId = templateData.orgId;
        } else {
            orgId = this.getCurrentUserOrgId();
        }
        return orgId;
    },

    /**
     * 获取用户信息组织name
     */
    getCurrentUserOrgName() {
        const orgDto = this.getCurrentLoginOrgObj();
        const orgId = orgDto.orgName;
        return orgId;
    },
    /**
     * 获取项目名称
     */
    getCurrentProductName() {
        const orgDto = this.getCurrentLoginOrgObj();
        const orgId = orgDto.productName;
        return orgId;
    },

    /**
     * 当前登录者的组织类型
     */
    getCurrentTypeCode() {
        const orgDto = this.getCurrentLoginOrgObj();
        const typeCode = orgDto.typeCode;
        return typeCode;
    },
    /**
     * 当前登录者的组织的认证状态
     */
    getCurrentOrgCertState() {
        // 证书认证状态（0：未认证，1：申请中，2：申请失败，10：认证成功，11：重新申请中，12：重新申请失败，20：重新申请成功
        const orgDto = this.getCurrentLoginOrgObj();
        const certState = orgDto.certState;
        return certState;
    },
    /**
     *  用户行业是否超过规定数量
     */
    getExceedLimitIndustryCount() {
        const userInfo = this.getCurrentUserInfo();
        const extJsonObj = userInfo.extJsonObj;
        if (extJsonObj && extJsonObj.industryList && extJsonObj.industryList.length > 20) {
            return true;
        }
        return false;
    },
    /**
     *  用户类别是否超过规定数量
     */
    getExceedLimitSubjectCount() {
        const userInfo = this.getCurrentUserInfo();
        const extJsonObj = userInfo.extJsonObj;
        if (extJsonObj && extJsonObj.subjectList && extJsonObj.subjectList.length > 20) {
            return true;
        }
        return false;
    },
    /**
     * 获取类别的code
     */
    getSubjectCodes() {
        const userInfo = this.getCurrentUserInfo();
        const extJsonObj = userInfo.orgUser.extJsonObj;
        if (!extJsonObj || !extJsonObj.subjectList || extJsonObj.subjectList.length == 0) {
            return [];
        }
        const codes = extJsonObj.subjectList.map((item) => {
            return item.subjectCode;
        });
        return codes;
    },
    /**
     * 获取类别的code
     */
    getIndustryCodes() {
        const userInfo = this.getCurrentUserInfo();
        const extJsonObj = userInfo.orgUser.extJsonObj;
        if (!extJsonObj || !extJsonObj.industryList || extJsonObj.industryList.length == 0) {
            return [];
        }
        const codes = extJsonObj.industryList.map((item) => {
            return item.industryCode;
        });
        return codes;
    },
    /**
     * 获取当前登录者在职的多家组织 V1.0.9 lzb 2021-06-03
     */
    getCurrentJobOrgList() {
        const userInfo = this.getCurrentUserInfo();
        const orgManageDtoList = userInfo.orgManageDtoList;
        return orgManageDtoList || [];
    },

    /**
     *  用户岗位是否超过规定数量
     */
    getExceedLimitDutyCount() {
        const userInfo = this.getCurrentUserInfo();
        const extJsonObj = userInfo.extJsonObj;
        if (extJsonObj && extJsonObj.dutyList && extJsonObj.dutyList.length > 20) {
            return true;
        }
        return false;
    },

    /**
     * 获取用户信息
     */
    getCurrentUserInfo() {
        const userInfo = StoreUserDB.getUserInfo();
        return userInfo;
    },

    /**
     * 获取菜单列表 框架替换
     */
    getMenuList() {
        const userInfo = StoreUserDB.getUserInfo();
        if (userInfo) {
            return userInfo.menuDtoList || [];
        }
        return [];
    },

    /**
     * 获取用户当前组织V1.0.9 lzb 2021-06-03
     */
    getCurrentLoginOrgObj() {
        const userInfo = this.getCurrentUserInfo();
        const orgDto = userInfo.orgDto;
        return orgDto || {};
    },
    /**
     * 是否修改密码
     */
    isRequireModifyPwd() {
        const user = this.getCurrentUserInfo();
        const require = user.passWordLength == 1 && user.defaultPasswordMod == 1;
        return require;
    },

    /**
     * 加载数据更新用户状态
     */
    loadOrgDtoStateUpdateAuthState(callback) {
        const that = this;
        // 请求入参
        const param = {
            orgId: this.getCurrentUserOrgId(),
        };
        orgAuthHttp
            .authState(param)
            .then((response) => {
                if (response && response.success) {
                    const data = response.data;
                    if (data) {
                        const certState = data.certState;
                        const userInfo = that.getCurrentUserInfo();
                        userInfo.orgDto.certState = certState;
                        StoreUserDB.saveUserInfo(userInfo);
                    }
                }
                if (callback) {
                    callback();
                }
            })
            .catch(function () {
                if (callback) {
                    callback();
                }
            });
    },
    // ========================================权限判断 是否=============================//

    /**
     * 当前登录类型是否是总部
     */
    isLoginAdminTypeCode() {
        const typeCode = this.getCurrentTypeCode();
        if (typeCode === Constants.organizationType.ORGTYPE_HEAD_OFFICE) {
            return true;
        } else {
            return false;
        }
    },
    /**
     * 当前登录类型是否是企业
     */
    isLoginCompanyTypeCode() {
        const typeCode = this.getCurrentTypeCode();
        if (typeCode === Constants.organizationType.ORGTYPE_COMPANY || typeCode === Constants.organizationType.ORGTYPE_ORGAN) {
            return true;
        } else {
            return false;
        }
    },
    /**
     * 当前登录类型是否是企业
     */
    isLoginOnlyCompanyTypeCode() {
        const typeCode = this.getCurrentTypeCode();
        if (typeCode === Constants.organizationType.ORGTYPE_COMPANY) {
            return true;
        } else {
            return false;
        }
    },
    /**
     * 当前登录类型是否是代理商
     */
    isLoginAgentTypeCode() {
        const typeCode = this.getCurrentTypeCode();
        if (typeCode === Constants.organizationType.ORGTYPE_AGENT) {
            return true;
        } else {
            return false;
        }
    },
    /**
     * 当前登录类型是否是行业管理
     */
    isLoginSuperviseTypeCode() {
        const typeCode = this.getCurrentTypeCode();
        if (typeCode === Constants.organizationType.ORGTYPE_SUPPERVISE) {
            return true;
        } else {
            return false;
        }
    },

    /**
     * 是否是迁移学员 2021-08-12
     */
    isMigrate() {
        const userInfo = this.getCurrentUserInfo();
        // sourceType 100：系统录入，101：导入，102：自主入驻,103：迁移
        if (userInfo && userInfo.orgDto && userInfo.orgDto.sourceType) {
            return userInfo.orgDto.sourceType * 1 === 103;
        }
        return false;
    },

    /**
     * 当前登录者是否包含管理员身份
     */
    isLoginContainerAdminIdentity() {
        const identityList = this.getCurrentUserIdentity();
        if (!identityList || identityList.length === 0) {
            return false;
        }
        for (let i = 0; i < identityList.length; i++) {
            const identity = identityList[i];
            if (identity.identityCode === Constants.organizeIndentityType.INDENTITY_ADMIN) {
                return true;
            }
        }
        return false;
    },
    /**
     * 获取登录组织是否是默认管理员
     * */
    getCurrentUserIsDefault() {
        const userInfo = this.getCurrentUserInfo();
        let isDefault = 0;
        if (userInfo) {
            const userIdentity = userInfo.userIdentityList;
            if (userIdentity && userIdentity.length > 0) {
                for (let i = 0; i < userIdentity.length; i++) {
                    const item = userIdentity[i];
                    // 获取到登录信息数据 isDefault
                    if (item.identityCode == Constants.organizeIndentityType.INDENTITY_ADMIN) {
                        isDefault = item.isDefault;
                        break;
                    }
                }
            }
        }
        // isDefault 1:默认管理员 0 不是
        if (isDefault == 1) {
            return true;
        } else {
            return false;
        }
    },

    /**
     * 20210223  判断用户是否有这个权限
     * permission 传入权限字符串
     * */
    checkHasPermissionFormPermissionList(permission) {
        // 1. 检测
        if (permission == null || permission.length == 0) {
            return false;
        }
        // 2. 获取权限数据
        const userInfo = this.getCurrentUserInfo();
        const permissionList = userInfo.permissionList;

        if (!permissionList || permissionList.length == 0) {
            return false;
        }
        // 3. 遍历比较
        for (let i = 0; i < permissionList.length; i++) {
            const permissionStr = permissionList[i];
            if (permissionStr == permission) {
                return true;
            }
        }
        // 4. 默认返回false
        return false;
    },
    /**
     * 20210621  判断用户是否有这个权限的跳转路径
     * router 传入权限字符串
     * */
    checkHasPermissionRoute(routerString) {
        // 1. 检测
        if (routerString == null || routerString.length == 0) {
            return false;
        }
        // 2. 获取登录路由数据
        const userInfo = this.getCurrentUserInfo();
        const menuDtoList = userInfo.menuDtoList;

        // 3. 遍历比较
        for (let i = 0; i < menuDtoList.length; i++) {
            const item = menuDtoList[i];
            if (item.path == routerString) {
                return true;
            }
        }

        // 4. 获取静态路由数据
        const routerWhiteList = Constants.routerWhiteList;
        // 5. 遍历比较
        for (let i = 0; i < routerWhiteList.length; i++) {
            const routerStr = routerWhiteList[i];
            if (routerStr == routerString) {
                return true;
            }
        }

        // 6. 默认返回false
        return false;
    },
    /**
     * 获取组织id  yxl 20210804  从蜀交安培移植过来的，临时写的地方，到时候合并蜀交安培需要删掉。
     */
    getOrgId() {
        let orgId = "";
        const templateStr = window.localStorage.getItem("templateData");
        if (templateStr && templateStr.length > 0) {
            const templateData = JSON.parse(templateStr);
            orgId = templateData.orgId;
        } else {
            orgId = this.getCurrentUserOrgId();
        }
        return orgId;
    },
    /**
     * 是否登录
     */
    isLogin() {
        const info = this.getCurrentUserInfo();
        if (info && info.token) {
            return true;
        }
        return false;
    },
    /**
     * 是否包含安全培训类别
     */
    isContainerSafeTrainFromLogin() {
        const userInfo = this.getCurrentUserInfo();
        const subjectList = (userInfo.orgUser && userInfo.orgUser.extJsonObj && userInfo.orgUser.extJsonObj.subjectList) || [];
        const subjectItem = subjectList.find((item) => {
            return item.subjectCode == StaticConstants.subjectCodeTrain; // "T01T01";
        });
        return !!subjectItem;
    },

    /**
     * 通过类别code获取类别名称  lzb 2024-01-15
     */
    findSubjectNameBySubjectCode(code) {
        if (!code || code.length == 0) return "";
        const userInfo = this.getCurrentUserInfo();
        const menuDtoList = (userInfo.menuDtoList && userInfo.menuDtoList) || [];

        const findItem = menuDtoList.find((item) => {
            return item.parameterContent == code;
        });
        return (findItem && findItem.meta && findItem.meta.title) || "";
    },
};
