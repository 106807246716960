/**
 * 默认非系统级别的保存不能保存到app, lzb 刘子彬
 *
 */
const app = {
    namespaced: true,
    state: {
        sidebar: {
            // 默认false为打开
            opened: localStorage.getItem("sidebarStatus") != 1,
            withoutAnimation: false,
        },
        device: "desktop",
        footerNews: [],
    },
    mutations: {
        TOGGLE_SIDEBAR: (state) => {
            state.sidebar.opened = !state.sidebar.opened;
            state.sidebar.withoutAnimation = false;
            if (state.sidebar.opened) {
                localStorage.setItem("sidebarStatus", 0);
            } else {
                localStorage.setItem("sidebarStatus", 1);
            }
        },
        TOGGLE_DEVICE: (state, device) => {
            state.device = device;
        },
        CLOSE_SIDEBAR: (state, withoutAnimation) => {
            localStorage.setItem("sidebarStatus", 0);
            state.sidebar.opened = false;
            state.sidebar.withoutAnimation = withoutAnimation;
        },
        SET_FOOTER_NEWS: (state, footerNews) => {
            state.footerNews = footerNews;
        },
    },
    actions: {
        toggleSideBar: ({ commit }) => {
            commit("TOGGLE_SIDEBAR");
        },
        toggleDevice: ({ commit }, device) => {
            commit("TOGGLE_DEVICE", device);
        },
        closeSideBar: ({ commit }, { withoutAnimation }) => {
            commit("CLOSE_SIDEBAR", withoutAnimation);
        },
        setFooterNews: ({ commit }, footerNews) => {
            commit("SET_FOOTER_NEWS", footerNews);
        },
    },
};

export default app;
