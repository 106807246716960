import Vue from "vue";
import { RequestCodeConstants } from "../constants/RequestCodeConstants.js";
import { Constants } from "../constants/Constants.js";
import userUtil from "../user/userUtil.js";
import Loading from "./loading.js";
import { ConstantErrors } from "../constants/ConstantErrors.js";
import windownGuideDialog from "../../components/window-guide-dialog/index.js";
import WindowDialog from "../../components/window-Dialog/index.js";
import EnumConstants from "@utils/constants/EnumConstants";
import TBUserManger from "@utils/user/TBUserManger";
import TagViewManager from "@views/layout/utils/TagViewManager";
import LoadFail from "@views/error/load-fail/LoadFail.vue";
import NoDataEmpty from "@views/error/no-data-empty/no-data-empty.vue";
import FullLoading from "@components/loading/index.js";
import StoreTempDB from "../db/StoreTempDB.js";
import { getResponsiveImageURL, getCDNImageName } from "../helper/URLHelper.js";

/**
 * 注册全局组件
 */
Vue.component("LoadFail", LoadFail);
Vue.component("NoDataEmpty", NoDataEmpty);

/**
 * 全局公共类
 */
// 获取URL中的参数, 返回JSON格式
Vue.prototype.getUrlParams = function (url) {
    try {
        url = url.match(/\?([^#]+)/)[1];
        const obj = {};
        const arr = url.split("&");
        for (let i = 0; i < arr.length; i++) {
            const subArr = arr[i].split("=");
            obj[subArr[0]] = subArr[1];
        }
        return obj;
    } catch (err) {
        return null;
    }
};

// 关闭tab - lzb 新增 2020-09-02
Vue.prototype.removeTab = function (url, jumpLastRouter = true) {
    TagViewManager.removeTab(url, jumpLastRouter);
};

Vue.prototype.removeSomeTab = function (url, excludeArr) {
    TagViewManager.removeSomeTab(url, excludeArr);
};

// 全局加载
Vue.prototype.loadingStart = function (message) {
    Loading.showLoading(message);
};

Vue.prototype.loadingClose = function () {
    Loading.hideLoading();
};

// 上一次路由, 如果指定某个路由关闭时, 指定关闭某个路由时, 返回指定页面, 可以使用这个参数强制重置
Vue.prototype.prevRoute = "";

// 判断资源类型
Vue.prototype.formatResourcesType = function (row, column, cellValue) {
    switch (cellValue) {
        case null:
            return "所有";
        case 11:
            return "菜单";
        case 12:
            return "按钮";
        case 13:
            return "表单";
        case 14:
            return "资源块";
        case 15:
            return "标签";
        case 16:
            return "数据选项";
        default: {
            return "";
        }
    }
};

// 判空显示
Vue.prototype.formatNull = function (row, column, cellValue) {
    return cellValue == null || cellValue == "" ? "-" : cellValue;
};

// 判空
Vue.prototype.isEmpty = function (val) {
    // null or undefined
    if (val == null) return true;

    if (typeof val === "boolean") return false;

    if (typeof val === "number") return !val;

    if (val instanceof Error) return val.message === "";

    switch (Object.prototype.toString.call(val)) {
        // String or Array
        case "[object String]":
        case "[object Array]":
            return !val.length;

        // Map or Set or File
        case "[object File]":
        case "[object Map]":
        case "[object Set]": {
            return !val.size;
        }
        // Plain Object
        case "[object Object]": {
            return !Object.keys(val).length;
        }
    }
    return false;
};

/*
 * lzb  判断是否具有权限某一个权限  - 传入权限字符串
 * */
Vue.prototype.checkHasPermissionFormPermissionList = function (permission) {
    return TBUserManger.checkHasPermissionFormPermissionList(permission);
};

/**
 * 响应式图片 -全局方法 lzb 20230601
 */
Vue.prototype.formatterResponseUrl = (val, width, height) => {
    return getResponsiveImageURL(val, width, height);
};
/**
 * cdn图片 -全局方法 lzb 20230601
 */
Vue.prototype.formatterCDNImageName = (name, width, height) => {
    return getCDNImageName(name, width, height);
};

/**
 *用户相关操作
 * @type {{keys, getUserName, login, logout}}
 */
Vue.prototype.userUtil = userUtil;

/**
 *
 * @type {{TB_SYSTEM_OPERATION_LOG_MODULE, TB_SYSTEM_AUDIT_PROCESS_TYPE, TB_TRAIN_PLAN_MODE, TB_SYSTEM_OPERATION_LOG_TYPE, TB_SYSTEM_INDUSTRY_TYPE, TB_SYSTEM_CLASS_TRAIN_TYPE, TB_SYSTEM_RESOURCE_TYPE, TB_SYSTEM_BANNER_POSITION, TB_TRAIN_PLAN_TYPE, TB_SYSTEM_ORGANIZE_NATURE, TB_SYSTEM_ORGANIZE_IDENTITY_TYPE, TB_AUTH_PURPOSE_TYPE, TB_SYSTEM_PERMISSION_TYPE, TB_SYSTEM_PRODUCT_TYPE, TB_SYSTEM_ORGANIZE_TYPE}}
 */
// 请求常量命名
Vue.prototype.RequestCodeConstants = RequestCodeConstants;

// 返回常量命名
Vue.prototype.Constants = Constants;

/**
 * 常量错误类
 */
Vue.prototype.ConstantErrors = ConstantErrors;
/**
 * 常量枚举
 */
Vue.prototype.$EnumConstants = EnumConstants;

/**
 * 操作引导
 */
Vue.prototype.$windownGuideDialog = windownGuideDialog.install();

// 弹框
Vue.prototype.$windowDialog = WindowDialog.install();

/**
 * 全局加载
 */
Vue.prototype.$fullLoading = FullLoading.install();

window.$TBUpload = {};
/**
 * @description: 上传平台
 * @return {*}
 */
window.$TBUpload.uploadPlatform = StoreTempDB.getUploadPlatform() || EnumConstants.UPLOAD_PLATFORM.HUAWEI;
/**
 * @description:  替比云上传平台
 * @return {*}
 */
window.$TBUpload.uploadSubPlatform = EnumConstants.UPLOAD_PLATFORM.HUAWEI;
