import { Loading } from "element-ui";

let loadingCount = 0;
let loading;

const startLoading = (message) => {
    loading = Loading.service({
        lock: true,
        text: message ? message : "加载中……",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
    });
};

const endLoading = () => {
    loading.close();
};

const showLoading = (message) => {
    if (loadingCount === 0) {
        startLoading(message);
    }
    loadingCount += 1;
};

const hideLoading = () => {
    if (loadingCount <= 0) {
        return;
    }
    loadingCount -= 1;
    if (loadingCount === 0) {
        endLoading();
    }
};

export default {
    showLoading,
    hideLoading,
};
