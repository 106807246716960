import Vue from "vue";

Vue.mixin({
    beforeRouteLeave(to, from, next) {
        // console.log("beforeRouteLeave");

        // 如果需要关闭的路由不在数组中, 则完全关闭所有缓存
        const fromPath = from.path;
        const tagsArr = this.$store.state.tagsView.visitedViews.map((tagsObj) => {
            return tagsObj.path;
        });

        if (tagsArr.indexOf(fromPath) === -1) {
            // 此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
            if (this.$vnode && this.$vnode.data.keepAlive) {
                if (this.$vnode.parent && this.$vnode.parent.componentInstance && this.$vnode.parent.componentInstance.cache) {
                    if (this.$vnode.componentOptions) {
                        const key =
                            this.$vnode.key == null
                                ? this.$vnode.componentOptions.Ctor.cid + (this.$vnode.componentOptions.tag ? `::${this.$vnode.componentOptions.tag}` : "")
                                : this.$vnode.key;
                        const cache = this.$vnode.parent.componentInstance.cache;
                        const keys = this.$vnode.parent.componentInstance.keys;
                        if (cache[key]) {
                            if (keys.length) {
                                const index = keys.indexOf(key);
                                if (index > -1) {
                                    keys.splice(index, 1);
                                }
                            }
                            delete cache[key];

                            this.$destroy();
                        }
                    }
                }
            }
        }
        next();
    },
});
