<template>
    <div class="app-browser-version-lower" v-if="tips.down">
        <span>
            <span>检查到您的浏览器体验不好,</span>
            <span v-if="browser.name">当前版本:{{ browser.name }} - {{ browser.version }}</span>
            <span v-if="browser.versionNumber < 10">, 可能导致有些功能不能正常使用</span>
            <span>, 推荐您安装360浏览器, chrome谷歌浏览器, firefox火狐浏览器!</span>
            <a class="app-browser-version-down" href="javascript:void(0);" @click="downBrowser">点击即可下载</a>
            <span class="app-browser-close" @click="closeTips">关闭</span>
        </span>

        <!--show-close="true"-->
        <el-dialog :visible.sync="tips.downBox" :modal="false" width="300px" title="下载浏览器">
            <div class="app-browser-box">
                <el-table :data="gridData">
                    <el-table-column property="name" width="200" label="浏览器名称"></el-table-column>
                    <el-table-column label="操作" width="60">
                        <template slot-scope="scope">
                            <el-button type="text" @click="down(scope.row)" size="small">下载</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import Bowser from "bowser";

    export default {
        props: {},
        data() {
            return {
                tips: {
                    down: false,
                    downBox: false,
                    // 浏览器禁止使用
                    browserDisabled: false,
                },
                browser: {
                    name: "",
                    version: "",
                    versionNumber: 0,
                },
                gridData: [
                    {
                        name: "360浏览器[推荐]",
                        url: "http://down.360safe.com/se/360se10.0.2004.0.exe",
                    },
                    {
                        name: "chrome谷歌浏览器[推荐]",
                        url: "https://dl.google.com/tag/s/appguid%3D%7B8A69D345-D564-463C-AFF1-A69D9E530F96%7D%26iid%3D%7B566DF992-1195-6E28-C689-0B5E3C2F3719%7D%26lang%3Dzh-CN%26browser%3D4%26usagestats%3D1%26appname%3DGoogle%2520Chrome%26needsadmin%3Dprefers%26ap%3Dx64-stable-statsdef_1%26installdataindex%3Dempty/update2/installers/ChromeSetup.exe",
                    },
                    {
                        name: "firefox火狐浏览器",
                        url: "https://download-ssl.firefox.com.cn/releases-sha2/stub/official/zh-CN/Firefox-latest.exe",
                    },
                ],
            };
        },
        created() {},
        mounted() {
            const browser = Bowser.getParser(window.navigator.userAgent);
            const result = browser.getResult();
            // console.log("result", result);
            if (result) {
                const browserObj = result.browser;
                this.browser.name = browserObj.name;
                this.browser.version = browserObj.version;
                this.browser.versionNumber = parseInt(browserObj.version);

                // console.log("browserObj", browserObj);
                // console.log("versionNumber",  this.browser.versionNumber);
                if (browserObj.name.toLocaleLowerCase().indexOf("ie") !== -1 || browserObj.name.toLocaleLowerCase().indexOf("internet explorer") !== -1) {
                    this.tips.down = true;
                }
            }

            // console.log("this.isIE()", this.isIE());
            // if (this.isIE()) {
            //     this.tips.down = true;
            // }

            // IE浏览器版本小于9
            if (!document.addEventListener) {
                this.downBrowser();
            }
        },
        methods: {
            isIE() {
                const myNav = navigator.userAgent.toLowerCase();
                // console.log("myNav", myNav);
                // mozilla/5.0 (windows nt 10.0; wow64; trident/7.0; .net4.0c; .net4.0e; .net clr 2.0.50727; .net clr 3.0.30729; .net clr 3.5.30729; rv:11.0) like gecko
                if (myNav.indexOf("msie") !== -1 || myNav.indexOf("trident") !== -1) {
                    return true;
                }
                return false;
            },

            closeTips() {
                this.tips.down = false;
            },
            downBrowser() {
                this.tips.downBox = true;
            },
            down(row) {
                const url = row.url;
                // window.open(url, "_blank").location;
            },
        },
    };
</script>
<style lang="scss">
    @import "../../assets/scss/constants-element.scss";
    .app-browser-version-lower {
        z-index: $--constants-z-index-9999;
        display: block;
        position: fixed;
        top: $px-0;
        width: $percent-100;
        height: $px-40;
        line-height: $px-40;
        background-color: $color-ff6600;
        text-align: center;
        font-weight: bolder;
        color: $color-4e4e4e;
    }

    .app-browser-close {
        display: inline-block;
        position: absolute;
        right: $percent-5;
        color: $color-4e4e4e;
        cursor: pointer;
    }

    .app-browser-close:hover {
        color: $color-red;
        font-weight: bolder;
    }
</style>
