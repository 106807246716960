export default {
    /**
     * 是否包含特殊字符
     */
    isContainerSpecialChar(str) {
        // 1. 检查
        if (str == null || str == undefined || str.length == 0) {
            return false;
        }
        // 2. 验证字符串
        const pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
        const result = pattern.test(str);

        return result;
    },
    /**
     * 是否包含数字
     */
    isContianerNumber(str) {
        // 1. 检查
        if (str == null || str == undefined || str.length == 0) {
            return false;
        }
        // 2. 验证字符串
        const pattern = new RegExp("[0-9]");
        const result = pattern.test(str);

        return result;
    },
    /**
     * 是否包含中文
     */
    isContainerChineseStr(str) {
        // 1. 检查
        if (str == null || str == undefined || str.length == 0) {
            return false;
        }
        // 2. 验证字符串
        const pattern = new RegExp("[\\u4E00-\\u9FFF]");
        const result = pattern.test(str);

        return result;
    },

    /**
     * 是否只有支持中文和英文
     * @param value
     * @returns {boolean}
     */
    isOnlyChineseEnglish(str) {
        // 1. 检查
        if (str == null || str == undefined || str.length == 0) {
            return false;
        }
        // 2. 验证字符串
        const pattern = new RegExp("^[A-Za-z\u4e00-\u9fa5]+$");
        const result = pattern.test(str);
        return result;
    },
    /**
     * 是否只有支持中文和英文数字
     * @param value
     * @returns {boolean}
     */
    isOnlyChineseEnglishNumber(str) {
        // 1. 检查
        if (str == null || str == undefined || str.length == 0) {
            return false;
        }
        // 2. 验证字符串
        const pattern = new RegExp("^[A-Za-z\u4e00-\u9fa50-9]+$");
        const result = pattern.test(str);
        return result;
    },
    /**
     * 是否只有支持中文和英文正常符号
     * @param value
     * @returns {boolean}
     */
    isOnlyChineseEnglishNormalTextSpecifyKey(str) {
        // 1. 检查
        if (str == null || str == undefined || str.length == 0) {
            return false;
        }
        // 2. 验证字符串
        const pattern = new RegExp("^[A-Za-z-、_/.\u4e00-\u9fa5]+$");
        const result = pattern.test(str);
        return result;
    },
    /**
     * 是否只有支持数字中文和英文正常符号
     * @param value
     * @returns {boolean}
     */
    isOnlyNumberChineseEnglishNormalTextSpecifyKey(str) {
        // 1. 检查
        if (str == null || str == undefined || str.length == 0) {
            return false;
        }
        // 2. 验证字符串
        const pattern = new RegExp("^[0-9A-Za-z-、——_/.\u4e00-\u9fa5]+$");
        const result = pattern.test(str);
        return result;
    },
    /**
     * 扩展名验证
     */
    isExternal(path) {
        return /^(https?:|mailto:|tel:)/.test(path);
    },
};
