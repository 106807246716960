import router from "@/router";

/**
 * 全局数据 e.g 标识符、layout统计数据等
 */
const global = {
    namespaced: true,
    state: {
        // 未读消息总数
        unreadMessageTotal: 0,
        // 未读新闻
        unreadNewsTotal: 0,
        // 未审核企业入驻组织数量
        unAuditOrgCount: 0,
        // 我的组织是否被引导
        isGuidedMineOrg: false,
        // 更新free的object
        updateFreeAPIObj: {},
        // 记住创建计划模板提醒
        rememberFlag: false,
        // 项目配置 lzb 2021-07-30
        projectConfig: {},
    },
    mutations: {
        SET_UNREAD_MESSAGE_TOTAL: (state, total) => {
            state.unreadMessageTotal = total || 0;
        },
        SET_UNREAD_NEWS_TOTAL: (state, total) => {
            state.unreadNewsTotal = total || 0;
        },
        SET_REMEMBER_CREATE_PLAN: (state, data) => {
            state.rememberFlag = data;
        },
        SET_IS_GUIDED_MINE_ORG: (state, data) => {
            state.isGuidedMineOrg = data;
        },
        SET_UPDATE_FREE_API_OBJ: (state, data) => {
            state.updateFreeAPIObj = data;
        },
        SET_UN_AUDIT_ORG_COUNT: (state, data) => {
            state.unAuditOrgCount = data;
        },
        // lzb 2021-07-30 配置
        SET_PROJECT_CONFIG: (state, data) => {
            state.projectConfig = data;
        },
    },
    actions: {
        /**
         *  刷新未读消息
         */
        refreshUnreadMessageTotal: ({ commit }, total) => {
            commit("SET_UNREAD_MESSAGE_TOTAL", total);
        },
        /**
         *  刷新未读新闻
         */
        refreshUnreadNewsTotal: ({ commit }, total) => {
            commit("SET_UNREAD_NEWS_TOTAL", total);
        },

        /**
         *  刷新创建计划是否记住状态
         */
        refreshRememberCreatePlanState({ commit }, data) {
            commit("SET_REMEMBER_CREATE_PLAN", data);
        },
        /**
         *  刷新我的组织的引导状态
         */
        refreshGuidedMineOrgState({ commit }, data) {
            commit("SET_IS_GUIDED_MINE_ORG", data);
        },
        /**
         *  刷新更新free的object
         */
        refreshFreeApiObject({ commit }, data) {
            commit("SET_UPDATE_FREE_API_OBJ", data);
        },
        /**
         * 刷新未审核组织数量
         */
        refreshUnAuditOrgCount({ commit }, data) {
            commit("SET_UN_AUDIT_ORG_COUNT", data);
        },
        /**
         * 刷新项目配置
         */
        refreshProjectConfig({ commit }, data) {
            commit("SET_PROJECT_CONFIG", data);
        },

        /**
         * 移除全局信息
         */
        removeGlobalStateData({ commit }) {
            commit("SET_UNREAD_MESSAGE_TOTAL", 0);
            commit("SET_UNREAD_NEWS_TOTAL", 0);
            commit("SET_UN_AUDIT_ORG_COUNT", 0);
            commit("SET_REMEMBER_CREATE_PLAN", false);
            commit("SET_IS_GUIDED_MINE_ORG", false);
            commit("SET_UPDATE_FREE_API_OBJ", null);
        },
    },
};
export default global;
