<template>
    <transition name="mask-fade">
        <div class="loading-wrapper-mask" v-if="options.loadingShow">
            <div class="loading-content-wrapper">
                <!-- <el-image class="loading-content-img" :src="options.loadingImage"></el-image> -->
                <div class="loading-content-text">
                    <span>{{ options.loadingText }}</span>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    const options = {
        /**
         * 是否显示
         */
        loadingShow: false,
        /**
         * 加载的图片
         */
        loadingImage: "",
        /**
         * 加载的文字
         */
        loadingText: "努力加载中....",
    };

    export default {
        name: "Loading",
        data() {
            return {
                options,
            };
        },
        methods: {
            /**
             * 显示动画
             */
            showLoading(opts) {
                Object.assign(this.options, opts);
                this.options.loadingShow = true;

                // 动态为body新增class
                if (!document.body.classList.contains("show-loading-mask-fullscreen")) {
                    document.body.classList.add("show-loading-mask-fullscreen");
                }
            },
            /**
             * 隐藏动画
             */
            dismissLoading() {
                this.options.loadingShow = false;
                if (document.body.classList.contains("show-loading-mask-fullscreen")) {
                    document.body.classList.remove("show-loading-mask-fullscreen");
                }
            },
        },
    };
</script>

<style lang="scss">
    .show-loading-mask-fullscreen {
        overflow: hidden !important;
    }
    // 渐变过渡
    .mask-fade-enter,
    .mask-fade-active {
        opacity: 0;
    }
    .mask-fade-enter-active,
    .mask-fade-leave-active {
        transition: opacity 0.35s;
    }
    .loading-wrapper-mask {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 2003;
        background-color: rgba(0, 0, 0, 0.7);
        margin: 0 auto;
        .loading-content-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .loading-content-img {
            }

            .loading-content-text {
                color: white;
                font-size: 14px;
            }
        }
    }
</style>
