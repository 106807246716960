import Vue from "vue";
import OrgFormatterUtils from "@views/organize-manager/organize-manager/utils/OrgFormatterUtils";

/**
 * 组织模块 - 过滤
 */
Vue.filter("formatterOrgStateStr", function (val) {
    return OrgFormatterUtils.formatterOrgStateStr(val);
});

/**
 * 使用模式
 */
Vue.filter("formatterBillingModeStr", function (val) {
    return OrgFormatterUtils.formatterBillingMode(val);
});

/**
 * 付费通道2021-11-18 lzb 新增
 */
Vue.filter("formatterPaymentChannelsStr", function (val) {
    return OrgFormatterUtils.formatterPaymentChannels(val);
});
