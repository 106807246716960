import dashboardHttp from "@/api/dashboard/dashboardHttp";
import { HudGlobal } from "../../utils/HudGlobal";
import DeviceHelper from "../../utils/helper/DeviceHelper";

export default {
    name: "windowGuideDialog",
    data() {
        return {
            options: {
                isVisible: false,
                /**
                 *  标记
                 * */
                tag: 0,
                /**
                 * 关闭
                 * */
                closeButtonText: "关闭",
                /**
                 * 上一步
                 * */
                preButtonText: "上一步",
                /**
                 * 下一步
                 * */
                nextButtonText: "下一步",

                /**
                 * 是否背景使能事件
                 * */
                isEnabledMaskEvent: true,
            },
            /**
             *  回调
             * */
            callBack: null,
            // 图片列表
            imgList: [],
            currentItem: null,
            currentIndex: 0,
        };
    },
    created() {},
    methods: {
        /**
         *  显示弹框
         * */
        showWindownGuideAlert(options, callBack) {
            Object.assign(this.options, options);

            this.callBack = callBack;

            this.loadProcessGuideDataList();
        },
        /**
         * 上一步
         */
        onClickPreEvent() {
            this.currentIndex--;
            if (this.currentIndex <= 0) {
                this.currentIndex = 0;
            }

            if (this.currentIndex < this.imgList.length) {
                this.currentItem = this.imgList[this.currentIndex];
            }
        },
        /**
         * 点击下一步
         */
        onClickNextEvent() {
            this.currentIndex++;
            if (this.currentIndex >= this.imgList.length) {
                this.onClickCloseEvent();
                return;
            }

            this.currentItem = this.imgList[this.currentIndex];
        },

        /**
         * 点击关闭
         */
        onClickCloseEvent() {
            this.close();
        },

        /**
         * 关闭
         */
        close() {
            this.options.isVisible = false;
            if (this.callBack) {
                this.callBack(true);
            }
        },

        /**
         * 加载引导图片
         */
        loadProcessGuideDataList() {
            const that = this;
            dashboardHttp.getOperationProcessGuideData().then((response) => {
                if (response.success) {
                    that.imgList = response.data;
                    that.currentIndex = 0;
                    that.options.isVisible = true;
                    if (that.imgList.length > 0) {
                        that.currentItem = that.imgList[that.currentIndex];
                    }
                } else {
                    // HudGlobal.showWarningWithMessage(response.description);
                }
            });
        },
    },
};
