import ConstantsAPI from "../constants/ConstantsAPI";

/**
 * @description: 获取url的last 名称
 * @return {*}
 */
const getURLLastName = (url) => {
    if (!url || url.length === 0) return "";
    const name = url.substring(url.lastIndexOf("/") + 1);
    return name;
};

/**
 * @description: 获取响应式图片
 * @param {string} originUrl
 * @param {number} width  -1 表示宽度自适应
 * @param {number} height
 * @return {*}
 */
const getResponsiveImageURL = (originUrl, width, height) => {
    if (!originUrl || originUrl.length === 0) return originUrl;
    if (!originUrl.startsWith("http")) return originUrl;
    try {
        //图片可能有空格
        // originUrl = originUrl.replace(/\s/g, "");
        const url = new URL(originUrl);
        const hostname = url.hostname;
        let resultUrl = originUrl;
        let processStr = "";
        if (hostname.includes("myhuaweicloud.com") || hostname.includes("obs.tbatb.net")) {
            //华为 - 比例缩放
            processStr = `?x-image-process=image/resize`;
            if (resultUrl.includes("x-image-process=image")) return resultUrl;
            resultUrl = resultUrl + processStr;
            if (!width && !height) {
                // 原图
                resultUrl = `${resultUrl},p_100`;
            } else {
                resultUrl = `${resultUrl},m_lfit`;
                width > 0 && (resultUrl = `${resultUrl},w_${width}`);
                height > 0 && (resultUrl = `${resultUrl},h_${height}`);
            }

            window.isSupportWebp && (resultUrl = `${resultUrl}/format,webp`);
            return resultUrl;
        } else if (hostname.includes("aliyuncs.com")) {
            // 阿里 - 比例缩放

            if (resultUrl.includes("image_process=")) return resultUrl;

            if (!width && !height) {
                window.isSupportWebp && (resultUrl = `${resultUrl}?image_process=format,webp`);
                return resultUrl;
            }

            processStr = `?image_process=resize`;
            resultUrl = resultUrl + processStr;

            if (width > 0 && height > 0) {
                resultUrl = `${resultUrl},fw_${width},fh_${height}`;
                window.isSupportWebp && (resultUrl = `${resultUrl}/format,webp`);
                return resultUrl;
            } else {
                width > 0 && (resultUrl = `${resultUrl},w_${width}`);
                height > 0 && (resultUrl = `${resultUrl},h_${height}`);
                window.isSupportWebp && (resultUrl = `${resultUrl}/format,webp`);
                return resultUrl;
            }
        } else {
            // 其他
            return resultUrl;
        }
    } catch (e) {
        return originUrl;
    }
};
/**
 * @description: 获取cdn图片
 * @param {*} name
 * @param {*} width
 * @param {*} height
 * @return {*}
 */
const getCDNImageName = (name, width, height) => {
    if (!name || name.length === 0) {
        console.warn("cdn name not null");
        return name;
    }
    const url = ConstantsAPI.getCommonCDNPath() + "/" + name;
    return getResponsiveImageURL(url, width, height);
};

/**
 * 解析url
 */
export const getUrlQueryAsObject = (url, excludeKeys = []) => {
    try {
        // 解析URL并获取查询字符串
        const urlObj = new URL(url);
        const searchParams = new URLSearchParams(urlObj.search);

        // 将查询字符串转换为对象
        const queryParamsObject = {};
        for (const [key, value] of searchParams.entries()) {
            if (!excludeKeys.includes(key)) {
                queryParamsObject[key] = value;
            }
        }

        return queryParamsObject;
    } catch (error) {
        // 处理URL解析错误
        console.error("Error parsing the URL:", error);
        return null;
    }
};

export { getCDNImageName, getURLLastName, getResponsiveImageURL };
