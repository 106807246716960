const ConstantErrors = {
    // 错误描述
    description: {
        /**
         * 网络连接失败
         */
        NETWORK_CONNECT_FAIL: "网络连接失败",
        /**
         * 登录已过期
         */
        LOGIN_EXPIRED_MESSAGE: "登录已过期, 请重新登录!",
    },

    // code
    Codes: {
        EMPLOYEE_FACE_CHECKED: 12612, // 员工-人像检测失败
        EMPLOYEE_FACE_FAIL: 12610, // 员工-人像比对失败V1.0.9 lzb
        DEPARTMENT_MANAGER_NOT_FIND: 12405, // 检测部门负责人
        EXPORT_MORE_DATA: 99999, // lzb 数据过多2023-07-27
    },
};

export { ConstantErrors };
