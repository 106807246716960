import Vue from "vue";
import FormatterUtil from "@utils/formatter-global/FormatterUtil";
import UniversalFormatter from "@utils/formatter-global/UniversalFormatter";
import { getResponsiveImageURL } from "@/utils/helper/URLHelper";

/**
 * 通用数据为 null
 */
Vue.filter("formatterDetailValueStr", (val) => {
    return UniversalFormatter.formatterDetailValueStr(val);
});
/**
 * 通用状态
 */
Vue.filter("formatterNumberStr", (val) => {
    return UniversalFormatter.formatterNumberStr(val);
});

/**
 * 分转元
 */
Vue.filter("formatterMoneyStr", (val, acc) => {
    return FormatterUtil.formatterMoneyPointStr(val, acc);
});

/**
 * 设备源
 */
Vue.filter("sourceTypeFilter", function (val) {
    return FormatterUtil.formatterSourceType(val);
});

/**
 * 计算分-秒
 */
Vue.filter("missToMinuteStr", function (val) {
    return FormatterUtil.missToMinuteStr(val);
});

/**
 * 资源类型
 */
Vue.filter("formatResourcesType", function (val) {
    return FormatterUtil.formatterResourcesType(val);
});

/**
 * 补充全url
 */
Vue.filter("filterSourceFullURL", function (val) {
    return FormatterUtil.filterSourceFullURL(val);
});

/**
 * 统一字符串过滤
 */
Vue.filter("formatterStr", function (val) {
    return FormatterUtil.formatterStr(val);
});

/**
 * 完整地址
 */
Vue.filter("formatterFullAddressStr", function (val) {
    return FormatterUtil.formatterAddressFullAppend(val);
});
/**
 * @description: 学员分类  fbz
 * @param {*} filterUserClazz
 * @param {*} function
 * @return {*}
 */
Vue.filter("filterUserClazz", (val) => {
    return FormatterUtil.filterUserClazz(val);
});

/**
 *  地区
 */
Vue.filter("areaNameFormatter", (item, limitCount = 0, noData = "无") => {
    return UniversalFormatter.formatterAreaNameListStr(item, limitCount, noData);
});
/**
 * 当个地址单元
 */
Vue.filter("formatterAddressUnitStr", (item) => {
    return FormatterUtil.formatterAddressUnitStr(item);
});

/**
 * 过滤状态
 */
Vue.filter("fliterState", (val, obj) => {
    return FormatterUtil.filterState(val, obj);
});
/**
 * 转换空字符
 */
Vue.filter("filterNullString", (value, formatterValue = "-") => {
    return FormatterUtil.filterNullString(value, formatterValue);
});

/**
 * 响应式图片-
 */
Vue.filter("formatterResponseUrl", (val, width, height) => {
    return getResponsiveImageURL(val, width, height);
});
