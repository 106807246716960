import http from "@/utils/http";
import commonHttp from "@api/common/commonHttp";

export default {
    add(params) {
        return http.post("/system/business/add", params);
    },
    del(id) {
        return http.delete(`/system/business/del/${id}`);
    },
    update(params) {
        return http.put("/system/business/mod", params);
    },
    // 查询经营范围, 行业-项目-类别-岗位
    selectPage(params) {
        return http.get("/system/business/page", params);
    },
    // 查询经营范围, 行业-项目-类别
    selectSubjectPage(params) {
        return http.get("/system/business/subject/page", params);
    },
    // 查询经营范围, 可查总部范围, 带组织查, 可查到类别, 也可查到岗位级别
    selectConditionPage(params) {
        // return http.get('/system/business/condition/page', params); lzb 20200805 后台需要替换增加部门id
        //  delete  lzb  2020-11-19 替换为共有接口 delete
        // return http.get('/org/department/subject/page', params);
        return commonHttp.getCommonSubjectList(params);
    },
    // 查询岗位
    selectCommonDutyList(params) {
        //  delete  lzb  2020-11-19 替换为共有接口 delete
        return commonHttp.getCommonDutyList(params);
    },
};
