const state = {
    routes: [],
    dynamicRoutes: [],
};

const mutations = {
    // eslint-disable-next-line no-shadow
    SET_ROUTES: (state, routes) => {
        state.routes = routes;
    },
    // eslint-disable-next-line no-shadow
    SET_DYNAMIC_ROUTES: (state, routes) => {
        state.dynamicRoutes = routes;
    },
};

const actions = {
    saveAllRoutes({ commit }, routes) {
        commit("SET_ROUTES", routes);
    },
    saveDynamicRoutes({ commit }, dynamicRoutes) {
        commit("SET_DYNAMIC_ROUTES", dynamicRoutes);
    },

    // 移除所有的路由
    removeAllRoute({ commit }) {
        commit("SET_DYNAMIC_ROUTES", []);
        commit("SET_ROUTES", []);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
