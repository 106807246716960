import Vue from "vue";
import VueRouter from "vue-router";
import RoutePermissionUtil from "@utils/router/RoutePermissionUtil";
import { RouterConstants } from "@utils/constants/RouterConstants";
import { StaticRoutes } from "@utils/router/RouteSource";
import { parseQuery, stringifyQuery } from "./utils/router/routerQuery";

Vue.use(VueRouter);

/**
 * 重写路由的push方法
 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    let pathString = location;
    let isPath = true;
    if (location.hasOwnProperty("path")) {
        pathString = location.path;
        isPath = false;
    }
    // yxl 2021.06.22 V1.0.10 判断是否有相关权限路由，如无直接跳转到无权限路由
    if (!RoutePermissionUtil.isHaveJurisdiction(pathString)) {
        const noPermissionRoute = RouterConstants.SYSTEM_NO_PERMISSION_ROUTE;
        const title = RoutePermissionUtil.getRouterTitle(pathString);
        if (isPath) {
            const tempLocation = {};
            tempLocation.path = noPermissionRoute;
            tempLocation.query = { tagTitle: title };
            location = tempLocation;
        } else {
            location.path = noPermissionRoute;
            location.query = { tagTitle: title };
        }
    }
    return routerPush.call(this, location).catch((error) => error);
};

const routeMode = process.env.VUE_APP_ROUTE_MODE;

let createRouter = () => {};
// 只有正式环境判断配置参数，其他情况判断别的
const isRouterEncrypt = process.env.VUE_APP_OPEN_ROUTE_ENCRYPT == "true";
if (isRouterEncrypt) {
    createRouter = () =>
        new VueRouter({
            // hash 默认,访问路径带#号 history,不带#号,但需要服务器的支持
            mode: routeMode || "hash",
            scrollBehavior: () => ({ y: 0 }),
            stringifyQuery, // 序列化query参数
            parseQuery, // 反序列化query参数
            routes: StaticRoutes,
        });
} else {
    createRouter = () =>
        new VueRouter({
            // hash 默认,访问路径带#号 history,不带#号,但需要服务器的支持
            mode: routeMode || "hash",
            scrollBehavior: () => ({ y: 0 }),
            routes: StaticRoutes,
        });
}
const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    // 重置路由
    router.matcher = newRouter.matcher;
}
export default router;
